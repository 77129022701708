import { LanguageMap, ResponseError } from '@/types/common';
import request from '@/utils/ajax';

export interface RecommendMenuListReq {
  storeId?: string;
  cpType?: string;
  orderFoodType?: string;
}

export interface RecommendMenu {
  productId?: string;
  productNameMap?: LanguageMap;
  productDescMap?: LanguageMap;
  originalPrice?: string;
  price?: string;
  imageUrl?: string;
  thumbnailUrl?: string;
}

export interface RecommendMenuListRes {
  success?: boolean;
  error?: ResponseError;
  data?: RecommendMenu[];
}

/**
 * 查询门店推荐菜品列表
 *
 */
export const queryRecommondMenuList = async (data: RecommendMenuListReq): Promise<RecommendMenuListRes> => {
  return await request<RecommendMenuListReq, RecommendMenuListRes>('/menu/recommend_menu_list', data);
};
