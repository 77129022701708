import { AxiosRequestConfig } from 'axios';
import { MD5 } from 'crypto-js';
import { signConfig } from './config/config';

function checkSerializedObject(str: string) {
  try {
    const obj = JSON.parse(str);
    return typeof obj === 'object' && obj !== null;
  } catch (e) {
    return false;
  }
}

function safeJsonParse(str: string) {
  const decodeStr = decodeURIComponent(str);
  const isSerializedObject = checkSerializedObject(decodeStr);
  if (isSerializedObject) {
    return JSON.parse(decodeStr);
  } else {
    return {}; // 不可转换的修改为空对象
  }
}

// 从headers中获取存储值为encode后的值，如果是对象序列化后的会返回对象，否则返回字符串
function getOriginHeader(config: AxiosRequestConfig, key: string) {
  const decodeStr = decodeURIComponent(config.headers?.[key] || '');
  const isSerializedObject = checkSerializedObject(decodeStr);
  if (isSerializedObject) {
    return JSON.parse(decodeStr);
  } else {
    return decodeStr;
  }
}

// 判断当前网络
const getNetworkStatus = () => {
  if (!navigator.onLine) {
    return 0;
  } else if ((navigator as any).connection) {
    // 使用类型断言
    const connection = (navigator as any).connection;
    switch (connection.type) {
      case 'none':
        return 0;
      case 'wifi':
        return 1;
      default:
        return 2;
    }
  } else {
    return 0;
  }
};

const sign = function (requestData: object & { timestamp: string }): string {
  const appKey = signConfig.appKey;
  const appSecret = signConfig.secret;
  const timestamp = requestData.timestamp;
  return MD5(`appKey=${appKey}&body=${JSON.stringify(requestData)}&timeStamp=${timestamp}&appSecret=${appSecret}`).toString();
};

export { getOriginHeader, getNetworkStatus, sign, safeJsonParse };
