import styles from './index.module.scss';
import { t } from '@/utils/lang';
import expired from '@/assets/image/expired.svg';

const Expired: React.FC = () => {
  return (
    <div className={styles.expired}>
      <img src={expired} alt="" />
      <p>{t('merchant.expiredView')}</p>
    </div>
  );
};
export default Expired;
