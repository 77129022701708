import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from 'antd-mobile';
import styles from './index.module.scss';
import award from '@/assets/purchaseSuccessPage/award.svg';
import { getQueryParams } from '@/utils/tools/others';
import useReport from '@/hooks/useReport';
import { t } from '@/utils/lang';

// 支付成功页
export default function PurchaseSuccessPage() {
  const { report } = useReport();
  const navigate = useNavigate();
  //获取 url 里面的订单号
  const { orderId } = getQueryParams(window.location.href);
  // 点击立即使用按钮
  const onClickUseButton = () => {
    if (orderId) {
      const reportData = {
        type: 'click',
        btn_text: '立即使用'
      };
      //obus上报
      report('btn', reportData, 'purchase_success_page');
      navigate(`/platform/groupCouponOrderDetail?orderId=${orderId}`, { replace: true });
    }
  };
  // // 点击返回按钮
  const onClickBackButton = () => {
    const reportData = {
      type: 'click',
      btn_text: '回到首页'
    };
    //obus上报
    report('btn', reportData, 'purchase_success_page');
    navigate('/platform/home', { replace: true });
  };
  useEffect(() => {
    const reportData = {
      type: 'view'
    };
    //obus上报
    report('page', reportData, 'purchase_success_page');
  }, []);
  return (
    <div className={styles.purchaseSuccessPage}>
      <div className={styles.containerBox}>
        <Image className={styles.imgBox} width={200} height={104} src={award}></Image>
        <div className={styles.titleContainer}>{t('pay.paySuccess')}</div>
        <div className={styles.content}>{t('pay.payInStore')}</div>
        <div className={styles.useNowButton} onClick={onClickUseButton}>
          {t('pay.useNowButton')}
        </div>
        <div className={styles.backButton} onClick={onClickBackButton}>
          {t('pay.backToHome')}
        </div>
      </div>
    </div>
  );
}
