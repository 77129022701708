import { t } from '@/utils/lang';

interface RuleProps {
  receiveTimesLimit: number; // 每个用户最多领取张数，<=0表示不限制
  receiveTimesDailyLimit: number; // 每个用户单日最多领取张数，<=0表示不限制
  className?: string;
  isFreeCoupon?: boolean;
}
function PurchaseRule(props: RuleProps) {
  const { receiveTimesLimit, receiveTimesDailyLimit, className, isFreeCoupon = false } = props;

  return (
    <div>
      {receiveTimesLimit > 0 && receiveTimesDailyLimit < 0 && (
        <div className={className}>
          {isFreeCoupon
            ? t('merchant.couponReceiveTimesLimit', { receiveTimesLimit: receiveTimesLimit })
            : t('merchant.receiveTimesLimit', { receiveTimesLimit: receiveTimesLimit })}
        </div>
      )}
      {receiveTimesLimit < 0 && receiveTimesDailyLimit > 0 && (
        <div className={className}>
          {isFreeCoupon
            ? t('merchant.couponReceiveTimesDailyLimit', { receiveTimesDailyLimit: receiveTimesDailyLimit })
            : t('merchant.receiveTimesDailyLimit', { receiveTimesDailyLimit: receiveTimesDailyLimit })}
        </div>
      )}
      {receiveTimesLimit > 0 && receiveTimesDailyLimit > 0 && (
        <div className={className}>
          {isFreeCoupon
            ? t('merchant.allCouponLimit', { receiveTimesDailyLimit: receiveTimesDailyLimit, receiveTimesLimit: receiveTimesLimit })
            : t('merchant.allGroupLimit', { receiveTimesDailyLimit: receiveTimesDailyLimit, receiveTimesLimit: receiveTimesLimit })}
        </div>
      )}
    </div>
  );
}

export default PurchaseRule;
