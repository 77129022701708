import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Toast } from 'antd-mobile';
import { RootState } from '@/store';
import queryPageCardList, {
  FlashSalePageData,
  BannerCardData,
  BrandPavilionData,
  MallNearbyData,
  HomeCardParams,
  ResponseData,
  CardIdEnum,
  CardListData
} from '@/api/home/topHalfPart';

export interface pageCardListState {
  flashSalePageData: FlashSalePageData; // 爆款专区
  bannerCardData: BannerCardData[]; // 顶部BANNER
  brandPavilionData: BrandPavilionData; // 品牌馆
  mallNearbyData: MallNearbyData[]; // 附近商场
  isInit: boolean; // 表示是否有存过首页数据到redux
}
const initialState: pageCardListState = {
  flashSalePageData: {
    countdownTime: 0,
    title: {
      'zh-CN': '',
      'en-US': '',
      'id-ID': ''
    },
    items: [],
    pageId: '',
    productListId: '',
    hasMore: false,
    pageType: ''
  },
  bannerCardData: [],
  brandPavilionData: {
    name: {
      'zh-CN': '',
      'en-US': '',
      'id-ID': ''
    },
    brandLogo: [],
    hasMore: false
  },
  mallNearbyData: [],
  // 表示是否有存过首页数据到redux
  isInit: false
};

export const fetchPageCardData = createAsyncThunk('pageCardList/fetchPageCardData', async (params: HomeCardParams) => {
  const response: ResponseData = await queryPageCardList(params);
  console.log('首页请求聚合接口：', response);
  if (!response?.data?.cardList?.length) {
    const errorData: CardListData = {
      cardList: [
        {
          cardId: CardIdEnum.BRAND_PAVILION_CARD,
          data: initialState.brandPavilionData
        },
        {
          cardId: CardIdEnum.FLASH_SALE_CARD,
          data: initialState.flashSalePageData
        },
        {
          cardId: CardIdEnum.MALL_NEARBY_CARD,
          data: initialState.mallNearbyData
        },
        {
          cardId: CardIdEnum.PAGE_HOME_BANNER_CARD,
          data: initialState.bannerCardData
        }
      ]
    };
    const { cardIds } = params;
    const cardList = cardIds.length > 0 ? errorData.cardList.filter((item) => cardIds.includes(item.cardId)) : errorData.cardList;
    response.data = { cardList: cardList };
  }
  if (!response?.success) {
    if (response?.error?.message) {
      Toast.show({ content: response?.error?.message });
    }
    return response;
  } else {
    return response;
  }
});

// 更新操作
export const pageCardListState = createSlice({
  name: 'search',
  initialState,
  reducers: {
    // 更新首页上半部分卡片信息
    updatePageCardInfo: (state, action) => {
      const { data = {} } = action.payload; // 添加默认值 {}
      const { cardList = [] }: CardListData = data; // 添加默认值 []
      cardList &&
        cardList.map((item) => {
          switch (item.cardId) {
            case CardIdEnum.BRAND_PAVILION_CARD:
              state.brandPavilionData = item.data;
              break;
            case CardIdEnum.FLASH_SALE_CARD:
              state.flashSalePageData = item.data;
              break;
            case CardIdEnum.MALL_NEARBY_CARD:
              state.mallNearbyData = item.data;
              break;
            case CardIdEnum.PAGE_HOME_BANNER_CARD:
              state.bannerCardData = item.data;
              break;
            default:
              break;
          }
        });
    },
    resetCountDownTime: (state) => {
      state.flashSalePageData.countdownTime = -1;
    }
  },
  // 异步更新操作
  extraReducers: (builder) => {
    builder
      // 加载操作
      .addCase(fetchPageCardData.pending, () => {})
      // 加载成功
      .addCase(fetchPageCardData.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { cardList } = data;
        cardList &&
          cardList.map((item) => {
            switch (item.cardId) {
              case CardIdEnum.BRAND_PAVILION_CARD:
                state.brandPavilionData = item.data;
                break;
              case CardIdEnum.FLASH_SALE_CARD:
                state.flashSalePageData = item.data;
                break;
              case CardIdEnum.MALL_NEARBY_CARD:
                state.mallNearbyData = item.data;
                break;
              case CardIdEnum.PAGE_HOME_BANNER_CARD:
                state.bannerCardData = item.data;
                break;
              default:
                break;
            }
          });
        state.isInit = true;
      })
      // 加载失败
      .addCase(fetchPageCardData.rejected, () => {});
  }
});
export const { updatePageCardInfo, resetCountDownTime } = pageCardListState.actions;

export const getFlashSalePageData = (state: RootState) => state.pageCardList.flashSalePageData; // 爆款专区
export const getBannerCardData = (state: RootState) => state.pageCardList.bannerCardData; // 顶部BANNER
export const getBrandPavilionData = (state: RootState) => state.pageCardList.brandPavilionData; // 品牌馆
export const getMallNearbyData = (state: RootState) => state.pageCardList.mallNearbyData; // 附近商场
export const getIsInit = (state: RootState) => state.pageCardList.isInit; // 表示是否有存过首页数据到redux

export default pageCardListState.reducer;
