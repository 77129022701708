import { Image } from 'antd-mobile';
import { useEffect, useState } from 'react';
import defaultrSrc from '@/assets/merchant/noimage.svg';
import { defaultFoodImageUrlRegExp } from '@/utils/tools/constant';
import { compressImg } from '@/utils/tools/compressImg';

const RecommondFoodImage = (props: { thumbnailUrl?: string; imageUrl?: string }) => {
  const { thumbnailUrl, imageUrl } = props;
  const [imageSrc, setImageSrc] = useState<string>(defaultrSrc);
  const isDefaulImage = (url?: string) => {
    if (url && !defaultFoodImageUrlRegExp.test(url)) {
      return url;
    }
  };
  useEffect(() => {
    const resultSrc = isDefaulImage(thumbnailUrl) ?? isDefaulImage(imageUrl) ?? defaultrSrc;
    setImageSrc(resultSrc);
  }, [thumbnailUrl, imageUrl]);
  const loadError = () => {
    setImageSrc(defaultrSrc);
  };
  return <Image src={compressImg(imageSrc, { width: 192 })} alt="" style={{ height: '28vw' }} onError={loadError} fit="cover" />;
};

export default RecommondFoodImage;
