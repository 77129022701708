import { monitorReport } from './monitor/monitorReport';

const loadPolyfills = function () {
  // 检测浏览器是否支持AbortController API 解决线上 AbortController 不存在的报错问题
  if (!('AbortController' in window)) {
    // 如果不支持，动态引入polyfill模块
    import('abortcontroller-polyfill/dist/polyfill-patch-fetch')
      .then(() => {
        monitorReport.info({ category: 'AbortController 动态引入成功' });
      })
      .catch((error) => {
        monitorReport.info({ category: 'AbortController 动态引入失败', msg: { message: error?.message || '' } });
      });
  }
};
export default loadPolyfills;
