import dayjs from 'dayjs';
import { LanguageMap, Position } from '@/types/common';
import { getLanguage, t } from '../lang';
//对应的业务多语言词条文件
import enGB from '../../i18n/en-GB/index.json';
import zhCN from '../../i18n/zh-CN/index.json';
import idID from '../../i18n/id-ID/index.json';
import { LOCAL_KEY } from './storage';
import { toOpenDeepLink } from './jumpLink';

//多语言初始化
export const initLanguageMap = {
  enGB: enGB,
  zhCN: zhCN,
  idID: idID
};

// 跳转google地图
const openGoogleMaps = (position: Position, googleMapLink?: string) => {
  // 优先使用googleMapLink跳转
  const { latitude, longitude } = position;
  // 兜底的url，使用经纬度跳转
  const url = googleMapLink || `https://www.google.com/maps?q=${latitude},${longitude}`;
  toOpenDeepLink(url);
  // toOpenWebView(url, { showToolbar: false, showURL: false });
};

export enum FormatTimeType {
  'YMD' = 'YMD',
  'YMDHM' = 'YMDHM',
  'YMDHMS' = 'YMDHMS'
}

const formatZhYMD = 'YYYY年MM月DD日'; // 中文
const formatZhYMDHM = 'YYYY年MM月DD日 HH:mm'; // 中文
const formatZhYMDHMS = 'YYYY年MM月DD日 HH:mm:ss'; // 中文

const formatYMD = 'DD MMM YYYY'; // 非中文
const formatYMDHM = 'DD MMM YYYY HH:mm'; // 非中文
const formatYMDHMS = 'DD MMM YYYY HH:mm:ss'; // 非中文

const formatLangMap = {
  'zh-CN': {
    YMD: formatZhYMD,
    YMDHM: formatZhYMDHM,
    YMDHMS: formatZhYMDHMS
  },
  other: {
    YMD: formatYMD,
    YMDHM: formatYMDHM,
    YMDHMS: formatYMDHMS
  }
};

// 时间转换(根据语言展示) -- 目前仅提供自动转换为 2024年10月01日 22:13:10 / 28 Jul 2024 22:13:10 时间可配置是否展示，其他转换请自行根据dayjs文档转换

function formatTimeTool(time: string, formatType?: FormatTimeType) {
  if (!time) return '';
  const lang = getLanguage() === 'zh-CN' ? 'zh-CN' : 'other';
  const type = formatLangMap[lang][formatType || 'YMD'];
  if (!time) return dayjs().format(type);
  return dayjs(time).format(type);
}

// 距离显示 大于1km保留以为小数KM，小于一公里，如果小于100m展示100m
function formatDistance(distance?: number | null) {
  if (distance === null || distance === undefined) return '';
  const newDistance = Number(distance);
  if (newDistance === 0) return '< 100m';
  if (newDistance > 1) return `${newDistance.toFixed(1)}km`;
  // 小于1km
  const distanceInMeters = Math.floor(newDistance * 1000);
  if (distanceInMeters > 100) return `${distanceInMeters}m`;
  return '< 100m';
}

// 印尼盾格式画
const formatToIndonesianRupiah = function formatToIndonesianRupiah(amount: number | undefined): string {
  if (!amount) return '0';
  // 将数字转换为字符串
  const strAmount = amount.toString();
  // 将小数点和整数部分分开
  const parts = strAmount.split('.');
  const integerPart = parts[0] || [];

  // 加上千位分隔符
  let formattedAmount = '';
  for (let i = 0; i < integerPart.length; i++) {
    formattedAmount = integerPart[integerPart.length - 1 - i] + formattedAmount;
    if (i > 0 && i % 3 === 2 && i !== integerPart.length - 1) {
      formattedAmount = '.' + formattedAmount;
    }
  }

  // 返回格式化后的金额
  return formattedAmount;
};

const idleFn = (Fn: () => void, duration = 0) => {
  typeof window?.requestIdleCallback !== 'undefined' ? window.requestIdleCallback(Fn) : setTimeout(Fn, duration);
};

const defaultFoodImageUrlRegExp = /\/product\/default\.(jpg|png)/;
const isDefaulImage = (url?: string) => {
  if (url && !defaultFoodImageUrlRegExp.test(url)) {
    return false;
  }
  return true;
};

function getQueryParams(url = window.location.href) {
  const params: Record<string, string> = {};
  const searchParams = new URLSearchParams(url.split('?')[1] || '');

  for (const [key, value] of searchParams) {
    params[key] = value;
  }

  return params;
}

type TimeRange = {
  businessStartTime: string;
  businessEndTime: string;
};

type ScheduleEntry = {
  week: string; // 例如 "1,2,3,5"
  time: TimeRange[];
};
const weekMap: { [key: string]: LanguageMap } = {
  '1': {
    'zh-CN': '周一',
    'en-US': 'Monday',
    'id-ID': 'Senin'
  },
  '2': {
    'zh-CN': '周二',
    'en-US': 'Tuesday',
    'id-ID': 'Selasa'
  },
  '3': {
    'zh-CN': '周三',
    'en-US': 'Wednesday',
    'id-ID': 'Rabu'
  },
  '4': {
    'zh-CN': '周四',
    'en-US': 'Thursday',
    'id-ID': 'Kamis'
  },
  '5': {
    'zh-CN': '周五',
    'en-US': 'Friday',
    'id-ID': 'Jumat'
  },
  '6': {
    'zh-CN': '周六',
    'en-US': 'Saturday',
    'id-ID': 'Sabtu'
  },
  '7': {
    'zh-CN': '周日',
    'en-US': 'Sunday',
    'id-ID': 'Minggu'
  }
};
// 判断券来源
function getCouponSource(source: string) {
  switch (source) {
    case 'ultra':
      return 'Ultra券码';
    case 'CODE':
    case 'QPON':
      return '三合一券码';
    case 'CODE_LINK':
      return '其他券链接';
    case 'PAPER_VOUCHER':
      return '纸质券';
  }
}
// 判断是否为连续的星期
function areWeeksContinuous(weeks: number[]): boolean {
  if (weeks.length === 0) return false;

  const sortedWeeks = weeks.sort((a, b) => a - b);
  for (let i = 1; i < sortedWeeks.length; i++) {
    if (sortedWeeks[i] !== sortedWeeks[i - 1]! + 1) {
      return false; // 如果不是连续的
    }
  }
  return true; // 是连续的
}

// 获取星期的字符串表示
function getWeekRange(week: string) {
  const days = week.split(',').map(Number);
  // 判斷是否為連續的日期
  return {
    weekArr: days.map((day) => weekMap[day.toString()]),
    isContinuous: areWeeksContinuous(days)
  };
}

// 生成展示数组
function generateDisplayArray(arr: ScheduleEntry[]) {
  const result: string[] = [];
  const lang = getLanguage() as 'zh-CN' | 'en-US' | 'id-ID';

  arr.forEach((entry) => {
    const weekRangeObj = getWeekRange(entry.week);
    if (weekRangeObj.isContinuous) {
      // 连续的星期
      entry.time.forEach((item) => {
        const startDay = weekRangeObj?.weekArr[0]![lang] || weekRangeObj?.weekArr[0]!['id-ID'];
        const endDay = weekRangeObj?.weekArr[weekRangeObj?.weekArr.length - 1]![lang] || weekRangeObj?.weekArr[0]!['id-ID'];
        result.push(t('orderProcess.weekTime', { day1: startDay, day2: endDay, time: item.businessStartTime, time2: item.businessEndTime }));
      });
    } else {
      // 不联系的星期
      let str = `${weekRangeObj?.weekArr?.map((e) => e![lang] || e!['id-ID'])?.join(' 、')}`;
      entry.time.forEach((item) => {
        str = `${str} ${t('orderProcess.useTime', { time: item.businessStartTime + '-' + item.businessEndTime })}`;
        result.push(str);
      });
    }
  });

  return result;
}

//判断是否为Safari浏览器
export function isSafari() {
  const userAgent = navigator.userAgent;
  // 检查是否为 Safari 浏览器，不包括 Chrome、Edge 和 Firefox
  const isSafari =
    /Safari/.test(userAgent) && !/Chrome/.test(userAgent) && !/Edg/.test(userAgent) && !/FxiOS/.test(userAgent) && !/CriOS/.test(userAgent); // 排除 Firefox
  return isSafari;
}

//判断是否为FireFox浏览器
export function isFireFox() {
  const userAgent = navigator.userAgent;
  // 检查是否为 Safari 浏览器，不包括 Chrome、Edge 和 Firefox
  const isFireFoxFlag = /FxiOS/.test(userAgent) || /Firefox/.test(userAgent);
  return isFireFoxFlag;
}

// 判断富文本内容是否为空
export const isRichTextEmpty = (htmlString: string) => {
  // 去除 HTML 标签、空格和换行符后的内容
  const text = htmlString?.replace(/<(?!img).*?>/g, '')?.replace(/\s/g, '');
  if (!text) {
    return '';
  } else {
    return htmlString;
  }
};
// 判断当前是否为  Facebook ，Instagram ，TikTok ,YouTube ,微信内核。是的话默认当前打开的就是webView
export const isSpecificWebView = () => {
  const userAgent = navigator.userAgent || navigator?.vendor || window?.opera;
  // 检测 Facebook WebView
  const isFacebookWebView = /FBAN|FBAV/.test(userAgent);

  // 检测 Instagram WebView
  const isInstagramWebView = /Instagram/.test(userAgent);

  // 检测 TikTok WebView
  const isTikTokWebView = /TikTok/.test(userAgent);

  // 检测 YouTube WebView
  const isYouTubeWebView = /youtube/i.test(userAgent);

  // 检测 微信 WebView
  const isWeChatWebView = /MicroMessenger/.test(userAgent);
  // 返回结果
  return isFacebookWebView || isInstagramWebView || isTikTokWebView || isYouTubeWebView || isWeChatWebView;
};

export function getBrowserEngine() {
  const userAgent = navigator?.userAgent;

  if (userAgent.includes('Chrome')) {
    return 'Blink'; // Chrome、Edge、Opera 使用 Blink 内核
  } else if (userAgent.includes('Firefox')) {
    return 'Gecko'; // Firefox 使用 Gecko 内核
  } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
    return 'WebKit'; // Safari 使用 WebKit 内核
  } else if (userAgent.includes('Trident')) {
    return 'Trident'; // Internet Explorer 使用 Trident 内核
  } else if (userAgent.includes('Edge')) {
    return 'EdgeHTML'; // Edge（旧版）使用 EdgeHTML 内核
  }

  return 'Unknown'; // 未知内核
}

// 防止转译的获取url参数方法
export const getQueryParam = (param: string) => {
  const url = window.location.href;
  const regex = new RegExp(`[?&]${param}=([^&#]*)`, 'i'); // 创建正则表达式
  const match = url.match(regex); // 使用正则匹配参数
  return match ? decodeURIComponent(match[1] || '') : ''; // 返回解码后的参数值，或空字符串
};

// 手动生成uuid
const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const getUserAgent = () => {
  if (typeof window === 'object') {
    return window?.navigator?.userAgent;
  }
};

// 是否PC登录
const isPC = () => {
  const ua = getUserAgent(); // 浏览器ua
  if (!ua) return false;
  const agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  const isPCAgent = agents.every((agent) => ua.indexOf(agent) === -1);
  return isPCAgent;
};

// 手动生成sessionId
const createSessionId = () => {
  const sessionId = Date.now().toString(36) + Math.random().toString(36).substr(2, 5);
  return sessionId;
};

//获取obus的deviceId
const getObusDeviceId = () => {
  const obusDeviceId = window.otrack?.getDeviceId?.();
  const generateDeviceId = generateUUID();
  const qpon_device_id = obusDeviceId || generateDeviceId;
  localStorage.setItem(LOCAL_KEY.QPON_DEVICE_ID, qpon_device_id);
  return qpon_device_id;
};

//获取obus的sessionId
const getObusSessionId = () => {
  const qpon_session_id = window.otrack?.getHeader?.()?.$session_id || createSessionId();
  localStorage.setItem(LOCAL_KEY.QPON_SESSION_ID, qpon_session_id);
  return qpon_session_id;
};

// 正则匹配ios和安卓的deeplink路径
const extractPath = (url: string) => {
  // ios存活和不存活返回的是两种链接。需要用https判断
  const regex = url?.includes('https') ? /go\.link\/([^?]*)/ : /qpon:\/\/([^?]*)/;
  const match = url.match(regex);
  if (match) {
    return match[1];
  } else {
    return null;
  }
};

export {
  generateDisplayArray,
  openGoogleMaps,
  formatTimeTool,
  formatDistance,
  formatToIndonesianRupiah,
  idleFn,
  isDefaulImage,
  getQueryParams,
  generateUUID,
  getCouponSource,
  isPC,
  getObusDeviceId,
  createSessionId,
  getObusSessionId,
  extractPath
};
