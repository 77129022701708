import { useState } from 'react';
import { Input } from 'antd-mobile';
import { keyboardListener } from '@/utils/nativeSdk';

let keyboardFn: ((status?: boolean) => void) | null = null;
export default () => {
  const [keyStatus, setKeyStatus] = useState(false);
  const [changeListener, setChangeListener] = useState(false);
  return (
    <>
      <button
        onClick={() => {
          setKeyStatus(true);
          keyboardFn = (status) => {
            setKeyStatus(status as boolean);
          };
          keyboardListener.add(keyboardFn);
          setChangeListener(true);
        }}
      >
        开启键盘监听
      </button>
      {`${Boolean(changeListener)}`}
      <br />
      <button
        onClick={() => {
          if (keyboardFn) {
            keyboardListener.remove(keyboardFn);
            keyboardFn = null;
            setChangeListener(false);
          }
        }}
      >
        移除键盘监听
      </button>
      {`${!changeListener}`}
      <br />
      <span>键盘状态：{JSON.stringify(keyStatus)}</span>
      <Input type="text" placeholder="请点击输入框" />
    </>
  );
};
