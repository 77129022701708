import request from '@/utils/ajax';

export interface RequestData {
  app_id: string; // 应用id
}

// 定义响应数据的接口
interface ResponseData {
  code: number;
  msg: string;
  data: {
    deviceIds: string[];
    userIds: string[];
  };
}

/**
 *  查询开发者选项的白名单
 */
export const getDevOptWhiteList = async (data: RequestData) => {
  const baseUrl = import.meta.env.VITE_NODE_HOST || 'https://qpon-food-gl.qponmobile.com';
  const app_id = data?.app_id || 'com.qpon.platform';
  const res = await request<RequestData, ResponseData>(`${baseUrl}/nodePublic/public/getDevOptWhiteList`, { app_id }, { method: 'POST' });
  return res;
};
/**
 *  查询开发者选项的商场数据
 */
export interface MallDropdownData {
  name: string; // 商场名称
  address: string; // 商场地址
  latitude: string; // 商场纬度
  longitude: string; // 商场经度
}

export const getDevOptStoreList = async () => {
  const baseUrl = import.meta.env.VITE_NODE_HOST || 'https://qpon-food-gl.qponmobile.com';
  const res = await request<
    { key: string },
    {
      code: number;
      data?: MallDropdownData[];
      success: boolean;
    }
  >(`${baseUrl}/nodePublic/config/getConfig?key=app_dev_opt_store_list`, undefined, {
    method: 'GET'
  });
  return (res?.success && res?.data) || [];
};
