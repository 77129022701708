// sessionId规则，存储与sessionStorage
// 更新规则  1、初次进入页面生成  2、首页刷新更新  3、非首页刷新不更新

import { SESSION_KEY, storage } from '@/utils/tools';

function createSessionId() {
  const sessionId = Date.now().toString(36) + Math.random().toString(36).substr(2, 5);
  return sessionId;
}

const sessionIdData: {
  sessionId: string;
  updateSessionIdData: (sessionId: string) => void;
} = { sessionId: '', updateSessionIdData: () => {} };
/**
 *
 * @returns {sessionId: string}
 */
function useSessionId(): string {
  const sessionId = sessionIdData.sessionId;
  const pathname = location.pathname;

  // 更新state和sessionStorage
  function updateSessionId(sessionId: string) {
    sessionIdData.updateSessionIdData(sessionId);
    sessionStorage.setItem(SESSION_KEY.SESSION_ID, sessionId);
  }

  // 存在sessionId不需要做任何操作
  if (sessionId) {
    // 防止自动清除，重写一次
    sessionStorage.setItem(SESSION_KEY.SESSION_ID, sessionId);
    return sessionId;
  }
  // 不存在，只需要对非首页恢复存储在sessionStorage中的数据(没有就重新生成) // todo 这里是否还要包含 / 和 /platfrom
  if (['/', '/platform/home', '/platform/home/order', '/platform/home/account', '/platform/merchant'].indexOf(pathname) === -1) {
    const preSessionId = storage.getSessionStorage(SESSION_KEY.SESSION_ID);
    const newSessionId = preSessionId || createSessionId();
    updateSessionId(newSessionId);
    return newSessionId;
  } else {
    const newSessionId = createSessionId();
    updateSessionId(newSessionId);
    return newSessionId;
  }
}

export default useSessionId;
