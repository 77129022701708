import { Toast } from 'antd-mobile';
import { monitorReport } from '../monitor/monitorReport';
import { getCurrentPositionPermissions, getPlatform } from '../nativeSdk';
import { ErrorCode } from '../nativeSdk/trycatch';
import { Position } from '@/types/common';
import { t } from '@/utils/lang';

export enum PositionErrorCode {
  INITIAL = 0, //  初始
  /**授权拒绝*/
  PERMISSION_DENIED = 1,
  /**能力不可用*/
  POSITION_UNAVAILABLE = 2,
  /**超时*/
  TIMEOUT = 3,
  /**获取权限失败*/
  PERMISSION_GRANTED_FAIL = 4,
  /**授权通过但是定位失败*/
  PERMISSION_GRANTED_BUT_DISABLED = 5,
  /**不支持*/
  NO_SUPPORT = 6,
  /**未知的错误*/
  UNKNOWN_ERROR = 7
}
export const PositionErrorCodeMsg: { [key: number]: string } = {
  1: '授权拒绝',
  2: '能力不可用',
  3: '超时',
  4: '获取权限失败',
  5: '授权通过但是定位失败',
  6: '不支持',
  7: '未知的错误'
};
export interface GetPositionParams {
  enableHighAccuracy?: boolean; // 开启高精度模式
  timeout?: number; // 超时时间 单位毫秒 默认修改为20s
  maximumAge?: number; // 0为 不使用缓存位置信息 默认给了30分钟
}
export type PositionResult = Position & {
  success: boolean;
  code?: PositionErrorCode;
  errorMessage?: string;
};

function getPosition(params: GetPositionParams): Promise<PositionResult> {
  const { enableHighAccuracy = true, timeout = 20000 } = params;
  return new Promise((resolve) => {
    const platformInfo = getPlatform();
    // 非客户端走H5 api
    if (platformInfo?.code === 0 && platformInfo?.data === 'web') {
      if (navigator?.geolocation) {
        // 使用 Geolocation API 获取当前位置信息
        console.log('locationAction', 'H5拉定位');
        navigator.geolocation?.getCurrentPosition(
          (position) => {
            const { coords } = position;
            const { latitude, longitude } = coords;
            console.log('locationAction H5拉定位成功', coords);
            resolve({ success: true, latitude, longitude });
          },
          (error) => {
            const { code = PositionErrorCode.NO_SUPPORT, message } = error;
            console.log('locationAction H5拉定位失败', message);
            resolve({ success: false, errorMessage: message || 'location fail', code });
            monitorReport.info({
              category: 'H5拉定位失败',
              msg: { reason: PositionErrorCodeMsg[code], message }
            });
          },
          {
            enableHighAccuracy,
            timeout
          }
        );
      } else {
        // 如果浏览器不支持 Geolocation API，则返回错误信息
        resolve({ success: false, code: PositionErrorCode.NO_SUPPORT, errorMessage: 'no supported geolocation api' });
        Toast.show(t('home.locationErrMessage'));
      }
    } else {
      console.log('locationAction', 'app拉定位');
      getCurrentPositionPermissions()
        .then((positionRes) => {
          console.log('locationAction app定位原始结果', positionRes);
          // Toast.show({ duration: 3000, content: `app定位获取到的结果,/n ${JSON.stringify(positionRes)}` });
          if (positionRes?.code === 0 && positionRes?.data && positionRes?.data.coords) {
            const { latitude, longitude } = positionRes.data.coords;
            resolve({ success: true, latitude, longitude });
          } else {
            let code;
            const message = positionRes.message || 'location fail';
            const { code: originCode } = positionRes;

            // 自定义错误码
            // /** 未获取到权限 */
            // NO_PERMISSION: '-4',
            // /** 定位权限被拒绝 */
            // PERMISSION_DENIED: '-5',
            // /** 方法未实现 */
            // NOT_IMPLEMENTED: '-2',
            // /** web未实现 */
            // WEB_IMPLEMENTED: '-3',
            // /** 当前环境不在qpon App中 */
            // NOT_QPON_APP: '-6',
            // /** 已获取权限无法获取定位 */
            // NOT_USE_WITH_GRANTED: '-7'

            if (originCode === +ErrorCode.PERMISSION_DENIED) {
              // 授权拒绝
              code = PositionErrorCode.PERMISSION_DENIED;
            } else if (originCode === +ErrorCode.NO_PERMISSION) {
              // 获取权限失败
              code = PositionErrorCode.PERMISSION_GRANTED_FAIL;
            } else if (originCode === +ErrorCode.NOT_USE_WITH_GRANTED) {
              // 授权但是获取不到定位
              code = PositionErrorCode.PERMISSION_GRANTED_BUT_DISABLED;
            } else if (originCode === +ErrorCode.WEB_IMPLEMENTED) {
              code = PositionErrorCode.NO_SUPPORT;
            } else if (originCode === +ErrorCode.NOT_IMPLEMENTED) {
              code = PositionErrorCode.NO_SUPPORT;
            } else if (originCode === -1) {
              code = PositionErrorCode.POSITION_UNAVAILABLE;
            } else {
              code = PositionErrorCode.POSITION_UNAVAILABLE;
            }
            resolve({ success: false, code, errorMessage: message });
            monitorReport.info({
              category: 'APP拉定位失败',
              msg: { reason: PositionErrorCodeMsg[code], message }
            });
          }
        })
        .catch((e) => {
          resolve({ success: false, code: PositionErrorCode.UNKNOWN_ERROR, errorMessage: e.message || 'location fail' });
          monitorReport.info({
            category: 'APP拉定位失败',
            msg: { reason: PositionErrorCodeMsg[7], message: e.message }
          });
          Toast.show(t('home.locationErrMessage'));
        });
    }
  });
}

export default getPosition;
