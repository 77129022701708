import request from '@/utils/ajax';
import { useFetch } from '@/hooks';
import { OrderFoodType, LanguageMap } from '@/types/common';
import { UseRequestOptionsConfig } from '@/utils/ajax/interface';
import { DistanceQueryParams, RegionQueryParams, RecommondQueryParams } from '@/store/home/list/type';
import { PromotionInfo } from '@/api/order/settlementApi';

export interface RegionListData {
  regionCode: string;
  regionName: string;
  level: number;
  id: number;
  parentId: number;
  children: RegionListData[] | null;
  [key: string]: unknown;
}

interface ResponseError {
  code: string;
  message: string;
}

export interface RegionListResponseData {
  success: boolean;
  error: ResponseError;
  data: RegionListData[];
}

interface RegionListRequestData {}

/**
 * 查询行政区域列表
 */
export const queryRegionListData = async (data?: RegionListRequestData): Promise<RegionListResponseData> => {
  return request<RegionListRequestData, RegionListResponseData>('/region/region_list', data);
};

export interface StoreObj {
  storeLatitude: string; // 门店纬度
  storeAddress: string; // 门店地址
  storeLongitude: string; // 门店经度
  storeName: string; // 门店名
  storeDistance: number; // 门店距离
  storeId: string; // 门店id
  storeProvince: string; // 门店省份
  storeCity: string; // 门店城市
  storeArea: string; // 门店区域
  storeCpType: string;
}

export interface ListItem {
  storeInfo: StoreObj; // 门店信息
  token: string;
  detailId: string; //未领取用这个字段查详情 // 团购商品类型时存储（团购商品详情查询ID），1204版本需求：支持同一商品设置不同的活动价
  assetSalePrice: number; // 销售价格
  assetPreferentialPrice: number; // 券后价格
  useScopeOrderFoodTypes: OrderFoodType[]; // 可使用
  priceBeforeDiscount: number; // 券前价格
  priceAfterDiscount: number; // 券后价格
  imageUrl: string; // 图片链接
  couponType: string; // 优惠券类型: 满减券、折扣券、菜品券
  assetType: string; // 资产类型: 用于区分平台券和第三方券
  category: string; // 资产类型: COUPON 优惠券类型，PRODUCT 商品类型
  assetNameCn: string; // 产品中文名称
  assetNameId: string; // 产品印尼文名称
  assetNameEn: string; // 产品英文名称
  assetName: LanguageMap;
  status: boolean; // 可领取状态
  assetId: number; // 资产id
  percent: number; // 领取/购买进度
  discountAmount: number; // 优惠幅度
  couponTemplateId?: number; //券ID
  couponBatchId?: number; //团购券ID
  discountPercent?: number; // 折扣比例
  skuId: number;
  showRecommendTitle?: boolean;
  isFlashSale?: boolean; // 是否是闪购商品
  remainingStock?: number; // 可售卖库存
  promotionInfo?: PromotionInfo; // 促销信息
  userGroupCheckResult: boolean; //用户是否有资格参与活动 true有，false没有
  isSoldOut: boolean; //是否售罄
  receiveTimesLimit: number; //单用户限购的总次数
  receiveTimesDailyLimit: number; //单用户限购的单日次数
  userLimit: boolean; //用户限购
}
export interface FindByDistanceData {
  assetList: ListItem[];
  hasMore?: boolean; // 是否有更多
  queryId?: string; // 用于查询下一页时候携带
  isRecommend?: boolean;
  activityId?: number; // 活动id
  statistical: {
    statisticalByDistance: {
      [distance: number]: string; // key 表示距离 value 表示数量
    };
    statisticalByCategory: {
      [categoryId: number]: string; //key 表示类目ID value 表示数量
    };
  }; //埋点统计
}

export interface FindByDistanceResponseData {
  success: boolean;
  error?: ResponseError;
  data?: FindByDistanceData;
}

/**
 * 按附近范围查询优惠券列表
 */

export const queryFindByDistanceData = async (data?: DistanceQueryParams): Promise<FindByDistanceResponseData | null> => {
  return request<DistanceQueryParams, FindByDistanceResponseData>('/market/asset_aggregate_page/find_by_distance', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.warn('queryFindByDistanceData', err);
      return null;
    });
};

export interface FindByRegion {
  assetList: ListItem[];
  hasMore: boolean; // 是否有更多
  queryId?: string; // 用于查询下一页时候携带
  isRecommend?: boolean;
  regionId: string;
  activityId?: number;
}

export interface FindByRegionResponseData {
  success: boolean;
  error?: ResponseError;
  data?: FindByRegion;
}

export interface QueryRecommend {
  queryRecommend?: boolean | string;
}

/**
 * 【优惠券聚合页】按行政区域查询优惠券列表
 */
export const queryFindByRegion = async (data: RegionQueryParams): Promise<FindByRegionResponseData | null> => {
  return request<RegionQueryParams, FindByRegionResponseData>('/market/asset_aggregate_page/find_by_region', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.warn('queryFindByDistanceData', err);
      return null;
    });
};

export interface MyCouponCountRequestData {
  storeId?: string; // 门店id
  orderFoodType?: string; // 点餐类型（1=外卖，2=自取，3=堂食，4=grab外卖）
  status?: string; // 状态（valid=可用，used=已核销，locked=已锁定，expired=已过期，not_effective=未生效）
}

export interface MyCouponCountData {
  usableCount: number; // 待使用
  waitPayCount: number; // 待支付
}
interface MyCouponCountResponseData {
  success: boolean;
  error?: ResponseError;
  data: MyCouponCountData;
}

/**
 * 【优惠券聚合页】查询我的优惠券总数
 */

export const useApiQuerymyCouponCount = (data: MyCouponCountRequestData) => {
  return useFetch<MyCouponCountRequestData, MyCouponCountResponseData>(`/market/coupon/my_coupon_count`, data, { method: 'POST' });
};

export interface ReceiveByTokenRes {
  id: string; // 用户领取优惠券记录id
  category: string; // 优惠券类别:COUPON,CODE
  couponCode: string; // 非必须
  couponTemplateId: number; // 优惠券模板id
  couponName: string; // 优惠券名称
  couponType: string; // 优惠券类型
  couponTypeName: string; // 优惠券类型名
  couponValue: string; // 优惠金额
  couponDescription: object; // 优惠券描述
  status: string; // 优惠券状态
  effectiveStartTime: string; // 使用开始期限
  effectiveEndTime: string; // 使用结束期限
  useScopeMinOrderPrice: string; // 门槛金额
  useScopeOrderFoodTypes: OrderFoodType[]; // 门店id
  scopeStoreIds: string[];
}

interface ReceiveByTokenResData {
  success: boolean;
  error: ResponseError;
  data: ReceiveByTokenRes;
}

export interface ReceiveByTokenReq {
  token: string;
}

/** 【优惠券聚合页】领取优惠券-根据token领券 */

export const receiveByToken = async (data?: ReceiveByTokenReq): Promise<ReceiveByTokenResData> => {
  return request<ReceiveByTokenReq, ReceiveByTokenResData>('/market/coupon/receive_by_token', data);
};

// 获取资源位数据暂时不需要入参
export interface QueryPopupRep {
  activityId?: string; //活动id，根据活动id返回相应的弹窗信息
}

export interface QueryPopupResData {
  success: boolean;
  error: ResponseError;
  data: PopupResData;
}

interface activityObj {
  activityId: string; // 活动id
}
export interface PopupResData {
  imageUrl: LanguageMap; // 展示图片(json 格式,返回多语言图片)
  activity: activityObj; // 活动信息
}
/**
 * 获取资源位数据
 */
export const useApiQueryPopup = (data: QueryPopupRep, useRequestOptions?: UseRequestOptionsConfig) => {
  return useFetch<QueryPopupRep, QueryPopupResData>(`/resource_pos/platform_homepage/popup`, data, { method: 'POST' }, { ...useRequestOptions });
};

export interface QueryClaimAwardRep {
  activityId: string; // 活动id
}

export interface QueryClaimAwardResData {
  success?: boolean;
  error?: ResponseError;
  data?: ClaimAwardData;
}

// 优惠券类型枚举
export enum BUSINESS_TYPE {
  LOCAL_LIFE = 'LOCAL_LIFE',
  SAAS = 'SAAS'
}

export interface ClaimAwardCoupons {
  quantity: number;
  templateId: string;
  name: LanguageMap;
  couponType: string;
  logoUrl: string;
  description: string;
  business: BUSINESS_TYPE;
  effectiveStartTime: string; // 活动开始时间
  effectiveEndTime: string; // 活动结束时间
}

export interface ClaimAwardData {
  activityId?: string; // 活动id
  imgUrl: string; // 图片地址
  coupons: ClaimAwardCoupons[];
  discountTips: string;
}

/**
 * 红包活动领取
 */
export const useApiQueryClaimAward = (data: QueryClaimAwardRep, useRequestOptions?: UseRequestOptionsConfig) => {
  return useFetch<QueryClaimAwardRep, QueryClaimAwardResData>(
    `/local_life/market/activity/claim_award`,
    data,
    { method: 'POST' },
    { ...useRequestOptions }
  );
};

/**
 * 【优惠券聚合页】查询分类列表
 */
export interface ICategoryListItem {
  categoryId: number;
  categoryName: {
    'en-US': string;
    'id-ID': string;
    'zh-CN': string;
  };
  sort: number | string;
}

export interface ICategoryListReq {}
export interface ICategoryListRes {
  success: boolean;
  error: ResponseError;
  data: ICategoryListItem[];
}

/**
 * 【优惠券聚合页】查询分类列表
 * 接口地址：https://yapi-ads.wanyol.com/project/34770/interface/api/491600
 */
export const queryCategoryList = async (data?: ICategoryListReq): Promise<ICategoryListRes> => {
  return request<ICategoryListReq, ICategoryListRes>('/market/asset_aggregate_page/query_all_category', data);
};

/**
 * 【优惠券聚合页】查询推荐列表
 * 接口地址：https://yapi-ads.wanyol.com/project/34770/interface/api/491711
 */
export const queryFindByRecommendList = async (data?: RecommondQueryParams): Promise<FindByDistanceResponseData | null> => {
  return request<RecommondQueryParams, FindByDistanceResponseData>('/market/asset_aggregate_page/find_by_recommend', data);
};
