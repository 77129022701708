import React, { useEffect, useState } from 'react';
import { Button, Toast } from 'antd-mobile';
import { Network } from '@capacitor/network';
import styles from './index.module.scss';
import NoNetwork from '@/assets/common/noNetwork.webp';
import { t } from '@/utils/lang';
import { getnetworkStatus, isIos, isQponApp, requestCheckUpdate } from '@/utils/nativeSdk';

export default function NetworkAwareComponent({ children }: { children: React.ReactNode }) {
  // 保存原始的 pushState 和 replaceState 方法
  const originalPushState = history.pushState;
  const originalReplaceState = history.replaceState;
  const flag = 'isFirstNetworkChanged';

  const [isOnline, setIsOnline] = useState<boolean | null>(null);

  useEffect(() => {
    // 在组件挂载时检查网络状态
    checkNetworkStatus();
  }, []);

  // 检查网络状态
  const checkNetworkStatus = async () => {
    console.log('checkNetworkStatus called');
    try {
      const res = await getnetworkStatus();
      if (res?.code === 0) {
        const status = res?.data;
        console.log('Network status:', status, isOnline);
        setIsOnline(status?.connected as boolean);
      }
    } catch (e) {
      console.log('checkNetworkStatus error:', e);
    }
  };

  useEffect(() => {
    console.log('isOnline changed:', isOnline);
  }, [isOnline]);

  // 网络错误toast提示
  const showToast = () => {
    Toast.show({
      content: (
        <div style={{ textAlign: 'center' }}>
          {t('network.toastMsgOne')}
          <br />
          {t('network.toastMsgTwo')}
        </div>
      )
    });
  };

  console.log('curPlatform:', isQponApp(), isIos());
  // ios客户端的时候，监听首次网络变更
  if (isQponApp()) {
    // 首次有网络状态变更的时候，更新hasRefreshed状态，标志首次网络状态变更
    Network.addListener('networkStatusChange', async (status) => {
      console.log('networkStatusChange status:', status);
      /**
       * isFirstNetworkChanged
       * true表示已经有首次从无网变有网
       * false或undefined表示没有首次网络变化
       * 首次网络变化的时候，标记为true，同时主动更新页面状态
       * 如果后面还有网络变化，不会主动更新页面状态，只会在路由跳转的时候才更新页面状态
       */
      // 首次从无网变有网的时候，更新标志，检查网络状态和更新网络页面
      if (localStorage.getItem(flag) !== 'true') {
        console.log('networkStatusChange debug 1', localStorage.getItem(flag));
        const res = await getnetworkStatus();
        const status = res?.data;
        if (status?.connected === true) {
          console.log('networkStatusChange debug 2', status?.connected);
          // 更新标记
          localStorage.setItem(flag, 'true');
          // 首次网络状态变更，更新页面数据
          checkNetworkStatus();
          // 首次网络状态变更，启动h5自升级检查
          requestCheckUpdate();
          // location.reload();
        }
      }
    });
  }

  useEffect(() => {
    // 重写 pushState 方法
    history.pushState = function (...args) {
      console.log('路由监听 pushState');
      checkNetworkStatus();

      return originalPushState.apply(this, args);
    };

    // 重写 replaceState 方法
    history.replaceState = function (...args) {
      console.log('路由监听 replaceState');
      checkNetworkStatus();

      return originalReplaceState.apply(this, args);
    };

    // 监听 popstate 事件
    window.addEventListener('popstate', () => {
      console.log('路由监听 popstate');
      checkNetworkStatus();
    });
  }, [originalPushState, originalReplaceState]);

  const handleButtonClick = async () => {
    const status = await Network.getStatus();
    if (status?.connected === true) {
      setIsOnline(status?.connected as boolean);
    } else {
      // 网络异常，toast提示
      showToast();
    }
  };

  if (isOnline === false) {
    console.log('network children 1');
    // 无网络，返回网络错误提示页
    return (
      <div className={styles.container}>
        <div className={styles.box}>
          <img src={NoNetwork as string} className={styles.img} />
          <div className={styles.textTitle}>{t('network.noNetworkTitle')}</div>
          <div className={styles.textDesc}>{t('network.noNetworkDesc')}</div>
          <Button className={styles.retryButton} fill="none" onClick={handleButtonClick}>
            {t('network.refreshButton')}
          </Button>
        </div>
      </div>
    );
  } else {
    console.log('network children 2');
    // 网络正常
    return <>{children}</>;
  }
}
