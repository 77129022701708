import { isQponApp } from '../nativeSdk';
import registerSW from './registerSW.ts';
import { getQueryParams } from '@/utils/tools/others';
import { reportFCMToken } from '@/api/fcm';
// 安装弹窗实例
let deferredPrompt: unknown = null;
// 本次访问用户是否安装PWA
let hasPWAInstalled = false;
// 获取安装弹窗的状态
let asyncGetPromptStatus: Promise<boolean> = Promise.resolve(true);
// 获取安装弹窗超时时间
const getPromptTimeout = 10000;
// 获取通知栏权限的状态 null 初始状态  true 获取到  false 未获取到
let hasGetNotificationPermission: boolean | null = null;
// 初始化PWA
export function initPWA() {
  // 拦截安装事件，主动提示安装
  if (!isSupportPWA()) return;
  // 在APP中不启用PWA
  if (isQponApp()) return;
  // MARK 命中OPPO浏览器离线处理
  // if (window.offline_status) return;
  // 注册sw
  registerSW();
  // 封装asyncGetPromptStatus
  asyncGetPromptStatus = new Promise((resolve) => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      deferredPrompt = e;
      console.warn('PWA安装拦截成功');
      console.log(`'beforeinstallprompt' event was fired.`);
      resolve(true);
    });
    // 拦截prompt超时10秒，作为兜底,
    window.setTimeout(() => {
      resolve(false);
    }, getPromptTimeout);
  });

  // 监听安装事件
  window.addEventListener('appinstalled', () => {
    deferredPrompt = null;
    // Optionally, send analytics event to indicate successful install
    console.log('PWA was installed');
    console.warn('PWA安装成功');
    // 这个状态并没有持久化存储，因为卸载PWA后这个值就不准确了，需要实时拦截Prompt来进行判断
    hasPWAInstalled = true;
  });

  // 通知SW onload事件
  window.onload = () => {
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        type: 'LOAD'
      });
    }
  };

  // 用户的任何行为触发获取消息推送权限
  getNotificationPermission();

  // 处理SW传过来的消息
  handleSWPostData();
}

// 是否已经安装了PWA
export function hasInstalledPWA() {
  if (hasPWAInstalled) {
    console.warn('hasPWAInstalled', hasPWAInstalled);
    return true;
  }

  if (isOpenFromPWA()) {
    console.warn('从PWA启动', '已经安装PWA');
    return true;
  }

  // 支持PWA，但是拦截不到安装事件,无法再次添加图标，认为已经安装
  // 这儿要延时调用该接口，否则可能不准确
  // IOS肯定拦截不到
  if (isSupportPWA() && !deferredPrompt) {
    console.warn('拦截不到deferredPrompt', '已经安装PWA');
    return true;
  }
  return false;
}

// 异步判断是否已经安装了PWA，比同步准确
export async function asyncHasInstalledPWA() {
  if (hasPWAInstalled) {
    console.warn('hasPWAInstalled', hasPWAInstalled);
    return true;
  }

  if (isOpenFromPWA()) {
    console.warn('从PWA启动', '已经安装PWA');
    return true;
  }

  // 没有安装时，需要等到获取安装弹窗后再判断
  const promptStatus = await asyncGetPromptStatus;
  if (promptStatus) {
    // 获取到prompt证明没有安装
    return false;
  } else {
    // 获取不到，默认已经安装，不准确，可以根据业务更改默认值
    return true;
  }
}

// 安装PWA,返回用户的选择
export async function installPWA() {
  // 是否选择了安装
  let hasSelectInstall = false;
  if (deferredPrompt) {
    // 显示安装按钮,等待回调
    const result = await deferredPrompt.prompt().catch((err: unknown) => {
      console.error(err);
      return err;
    });
    // 判断1 dismissed accepted
    if (result.outcome === 'accepted') {
      hasSelectInstall = true;
    }
    console.warn('deferredPrompt result', result);
    // Wait for the user to respond to the prompt
    // userChoice 可能不存在
    if (deferredPrompt.userChoice) {
      const { outcome } = await deferredPrompt.userChoice;
      // Optionally, send analytics event with outcome of user choice
      console.log(`用户选择结果: ${outcome}`);
      // 判断2
      if (outcome === 'accepted') {
        hasSelectInstall = true;
      }
    }
    deferredPrompt = null;
    return hasSelectInstall;
  }
}

// 判断浏览器是否支持PWA
export function isSupportPWA() {
  if (!('serviceWorker' in navigator)) {
    // 浏览器支持 Service Worker
    return false;
  }
  // if (!('BeforeInstallPromptEvent' in window)) {
  // 浏览器支持安装 PWA
  // 安卓才支持
  //   return false;
  // }
  if (!('caches' in window)) {
    // 浏览器支持 Cache API
    return false;
  }
  // if (!('PushManager' in window)) {
  // 浏览器支持推送通知
  // IOS 18才支持
  //   return false;
  // }
  return true;
}

// 是否从PWA桌面图标打开
export function isOpenFromPWA() {
  if (window.matchMedia('(display-mode: standalone)').matches) {
    // 用户从 PWA 桌面图标打开应用
    console.log('isOpenFromPWA window.matchMedia', true);
    return true;
  }
  if (navigator.standalone) {
    console.log('isOpenFromPWA navigator.standalone', true);
    // iOS 设备上从桌面图标打开
    return true;
  }
  // OPPO浏览器通过url上面有标识判断
  const params = getQueryParams();
  if (params.channel_id === 'desktop_icon') {
    console.warn('OPPO 桌面图标打开');
    return true;
  }
  return false;
}
// 是否被sw控制
export function hasSW() {
  return !!window.navigator?.serviceWorker?.controller;
}

// 请求通知权限
export function getNotificationPermission() {
  // Web端无法在没有用户交互的情况下获取通知权限。这是浏览器的安全策略限制。
  // 用户行为触发获取消息推送权限
  // 进入应用只获取一次
  document.addEventListener('touchmove', requestNotificationPermission);
}

function requestNotificationPermission() {
  // ios 18才支持Notification
  // 无论结果每次进入页面只获取一次
  console.warn('Notification permission', '移除监听');
  document.removeEventListener('touchmove', requestNotificationPermission);
  console.warn('Notification 是否存在', !!window.Notification);
  console.warn('Notification hasGetNotificationPermission', hasGetNotificationPermission);
  if (!window.Notification) return;
  window.Notification.requestPermission()
    .then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission 已经授权', permission);
        hasGetNotificationPermission = true;
        //  通知SW获取token
      } else {
        console.log('Notification permission 未授权', permission);
        hasGetNotificationPermission = false;
      }
    })
    .finally(() => {})
    .catch((err) => {
      console.warn('Notification permission error.', err);
    });
}

// 处理SW传过来的数据
function handleSWPostData() {
  navigator.serviceWorker.addEventListener('message', (event) => {
    console.warn('SW传过来的message', event);
    const data = event.data;
    if (!data) return;
    const { type, payload } = data;

    // 处理事件类型
    // 更新localStorage
    if (type === 'UPDATE_LOCAL_STORAGE') {
      const keys = Object.keys(payload);
      keys.map((key) => {
        window.localStorage.setItem(key, payload[key]);
      });
    }

    // MARK 版本更新后自动刷新页面
    if (type === 'REFRESH_PAGE') {
      console.warn('REFRESH_PAGE', '刷新页面');
      window.location.reload();
    }

    // 2.如果存在fcm-token，需要上报服务端
    if (payload) {
      const fcmToken = payload['fcm-token'];
      if (fcmToken) {
        // 不需要处理结果
        reportFCMToken(fcmToken);
      }
    }
  });
}
