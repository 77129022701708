import { createAsyncThunk, PayloadAction, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { Toast } from 'antd-mobile';
import {
  HomeListTypeVO,
  type ListType,
  type ListParamsType,
  RegionQueryParams,
  DistanceQueryParams,
  RecommondQueryParams,
  ListResDto,
  ICategoryListItemVo
} from './type';
import { RootState } from '@/store';
import {
  queryFindByDistanceData,
  queryFindByRecommendList,
  queryFindByRegion,
  FindByRegionResponseData,
  FindByDistanceResponseData
} from '@/api/home';
import { LOCAL_KEY, storage } from '@/utils/tools';

const commmonErrorHandle = <T extends FindByRegionResponseData | FindByDistanceResponseData>(res: T) => {
  if (!res?.success) {
    if (res?.error?.message) {
      Toast.show({ content: res?.error?.message });
    }
  }
};
// 聚合参数
const joinParams = (params: Partial<ListParamsType>, state: HomeListTypeVO): RegionQueryParams | DistanceQueryParams | RecommondQueryParams => {
  let newParams = {
    queryId: params.queryId ?? state.commonQueryparmas.queryId,
    pageSize: params.pageSize ?? state.commonQueryparmas.pageSize,
    lat: params.lat ?? state.lat,
    lng: params.lng ?? state.lng,
    needStatistical: params.needStatistical ?? state.commonQueryparmas.needStatistical,
    isDefaultData: params.isDefaultData ?? state.commonQueryparmas.isDefaultData
  } as ListParamsType;
  switch (params.listType) {
    case 'region':
      newParams = {
        ...newParams,
        categoryIdList: params.categoryIdList ?? state.commonQueryparmas.categoryIdList,
        regionId: params.regionId ?? state.regionQueryParams.regionId
      } as RegionQueryParams;
      break;
    case 'distance':
      newParams = {
        ...newParams,
        categoryIdList: params.categoryIdList ?? state.commonQueryparmas.categoryIdList,
        distance: params.distance ?? state.distanceQueryParams.distance
      } as DistanceQueryParams;
      break;
    case 'recommond':
      if (state.recommondQueryParams.originQueryId) {
        newParams = {
          ...newParams,
          originQueryId: params.originQueryId ?? state.recommondQueryParams.originQueryId,
          queryId: ''
        } as RecommondQueryParams;
      }
      if (params.regionId || state.productsRes.listTypeData === 'region') {
        newParams = {
          ...newParams,
          regionId: params.regionId || state.regionQueryParams.regionId
        } as RecommondQueryParams;
      }
      if (params.distance || state.productsRes.listTypeData === 'distance') {
        newParams = {
          ...newParams,
          distance: params.distance || state.distanceQueryParams.distance
        } as RecommondQueryParams;
      }
      break;
    default:
      if (state.listType === 'region') {
        newParams = {
          ...newParams,
          categoryIdList: state.commonQueryparmas.categoryIdList,
          regionId: state.regionQueryParams.regionId
        } as RegionQueryParams;
      }
      if (state.listType === 'distance') {
        newParams = {
          ...newParams,
          categoryIdList: state.commonQueryparmas.categoryIdList,
          distance: state.distanceQueryParams.distance
        } as DistanceQueryParams;
      }
      if (state.listType === 'recommond') {
        if (state.productsRes.listTypeData === 'region') {
          newParams = {
            ...newParams,
            regionId: state.regionQueryParams.regionId
          } as RecommondQueryParams;
        }
        if (state.productsRes.listTypeData === 'distance') {
          newParams = {
            ...newParams,
            distance: state.distanceQueryParams.distance
          } as RecommondQueryParams;
        }
        if (state.recommondQueryParams.originQueryId) {
          newParams = {
            ...newParams,
            originQueryId: state.recommondQueryParams.originQueryId,
            queryId: ''
          } as RecommondQueryParams;
        }
      }
      break;
  }
  return newParams;
};
// 请求接口
const getlistDataApi = async (type: ListType, paramsObj: Partial<ListParamsType>) => {
  switch (type) {
    case 'region':
      return await queryFindByRegion(paramsObj as RegionQueryParams);
    case 'distance':
      return await queryFindByDistanceData(paramsObj as DistanceQueryParams);
    case 'recommond':
      return await queryFindByRecommendList(paramsObj as RecommondQueryParams);
    default:
      break;
  }
};

/***
 * @param params 调列表接口参数，空对象情况使用缓存，加载更多数据
 */

export const getMoreAsync = createAsyncThunk('homeList/getMoreAsync', async (params: Partial<ListParamsType>, thunkAPI) => {
  const state = (<RootState>thunkAPI.getState()).homeList;

  // 聚合参数 params携带listType 更新参数
  const paramsObj = joinParams(params, state);
  const newParams = { ...paramsObj };
  delete newParams.isDefaultData;
  const response = await getlistDataApi(state.listType, newParams);
  if (response) {
    commmonErrorHandle(response);
  }
  return response;
});
export const extraReducers = (builder: ActionReducerMapBuilder<HomeListTypeVO>) => {
  builder
    .addCase(getMoreAsync.pending, (state) => {
      if (['region', 'distance'].includes(state.listType)) {
        state.productsRes.loading = true;
      } else {
        state.recommondProductsRes.loading = true;
      }
    })
    .addCase(getMoreAsync.fulfilled, (state, action) => {
      const data = action.payload?.data;

      if (action.payload?.success) {
        // 更新参数的state.queryId
        state.commonQueryparmas.queryId = data?.queryId ?? '';

        state.productsRes.isLogin = !!storage.getLocalStorage(LOCAL_KEY.TOKEN_INFO);
        if (['region', 'distance'].includes(state.listType)) {
          //  如果redux有数据，当前的为默认请求的数据 不要更新
          if (action.meta.arg.isDefaultData && state.productsRes.list.length) return;
          // 更新stateRes
          state.productsRes = {
            ...state.productsRes,
            list: state.isFirstPage ? (data?.assetList ?? []) : state.productsRes.list.concat(data?.assetList ?? []),
            activityId: data?.activityId ?? -1,
            hasMore: data?.hasMore ?? false,
            queryId: data?.queryId ?? '',
            loading: false
          };

          if (state.isFirstPage) {
            state.productsRes.listTypeData = state.listType as 'region' | 'distance';
            state.recommondProductsRes = {
              ...state.recommondProductsRes,
              list: [],
              activityId: -1,
              queryId: '',
              loading: false
            };
          }

          // 查询数据为null或者数据加载完，准备加载推荐菜
          if ((!data || !data?.hasMore) && ['region', 'distance'].includes(state.listType)) {
            state.listType = 'recommond';
            state.recommondQueryParams.originQueryId = data?.queryId ?? '';
            state.commonQueryparmas.queryId = '';
            state.recommondProductsRes.hasMore = true;
            return;
          }

          return;
        }
        // 推荐菜接口成功回调
        state.recommondProductsRes = {
          list: state.isFirstPage ? (data?.assetList ?? []) : state.recommondProductsRes.list.concat(data?.assetList ?? []),
          activityId: data?.activityId ?? -1,
          hasMore: data?.hasMore ?? false,
          queryId: data?.queryId ?? '',
          loading: false,
          isLogin: !!storage.getLocalStorage(LOCAL_KEY.TOKEN_INFO)
        };
        state.recommondQueryParams.originQueryId = '';
        return;
      }
      // 请求成功，后端502
      if (['region', 'distance'].includes(state.listType)) {
        state.productsRes.loading = false;
        state.productsRes.hasMore = false;
      } else {
        state.recommondProductsRes.loading = false;
        state.recommondProductsRes.hasMore = false;
      }
    })
    .addCase(getMoreAsync.rejected, (state) => {
      if (['region', 'distance'].includes(state.listType)) {
        state.productsRes.loading = false;
        state.productsRes.hasMore = false;
      } else {
        state.recommondProductsRes.loading = false;
        state.recommondProductsRes.hasMore = false;
      }
    });
};

export const reducers = {
  // 更新参数操作，更新后拉接口数据
  updateRequestParmasReducers: (
    state: HomeListTypeVO,
    action: PayloadAction<Partial<ListParamsType> & Required<Pick<ListParamsType, 'listType'>>>
  ) => {
    state.commonQueryparmas = {
      ...state.commonQueryparmas,
      pageSize: action.payload.pageSize ?? state.commonQueryparmas.pageSize,
      queryId: action.payload.queryId ?? '',
      needStatistical: action.payload?.needStatistical ?? state.commonQueryparmas.needStatistical,
      isDefaultData: action.payload?.isDefaultData ?? false
    };
    // 更新缓存state
    state.listType = action.payload.listType;
    state.lat = action.payload.lat ?? state.lat;
    state.lng = action.payload.lng ?? state.lng;
    if (action.payload.listType === 'distance') {
      state.commonQueryparmas = {
        ...state.commonQueryparmas,
        categoryIdList: action.payload?.categoryIdList ?? state.commonQueryparmas.categoryIdList
      };
      state.distanceQueryParams.distance = action.payload.distance ?? state.distanceQueryParams.distance;
    }
    if (action.payload.listType === 'region') {
      state.commonQueryparmas = {
        ...state.commonQueryparmas,
        categoryIdList: action.payload?.categoryIdList ?? state.commonQueryparmas.categoryIdList
      };
      state.regionQueryParams.regionId = action.payload.regionId ?? state.regionQueryParams.regionId;
    }
    if (action.payload.listType === 'recommond') {
      state.recommondQueryParams = {
        regionId: action.payload.regionId ?? state.regionQueryParams.regionId,
        distance: action.payload.distance ?? state.distanceQueryParams.distance
      };
    }
  },
  updatedProductsReducers: (state: HomeListTypeVO, action: PayloadAction<ListResDto & { listTypeData: 'region' | 'distance' }>) => {
    state.productsRes = action.payload;
  },
  updatedRecommondProductsReducers: (state: HomeListTypeVO, action: PayloadAction<ListResDto>) => {
    state.recommondProductsRes = action.payload;
  },
  // 领取优惠券
  upDateItem: (
    state: HomeListTypeVO,
    action: PayloadAction<{ detailId: string | number; isReceive?: boolean; isNotCollected?: boolean; couponId?: string }>
  ) => {
    const { detailId, isReceive, isNotCollected, couponId } = action.payload;
    // state.recivedCoupons = {
    //   ...state.recivedCoupons,
    //   [detailId]: {
    //     isReceive: isReceive,
    //     isNotCollected: isNotCollected,
    //     couponId: couponId
    //   }
    // };
    if (state?.recivedCoupons) {
      state.recivedCoupons[detailId] = { isReceive, isNotCollected, couponId };
    }
  },
  // 判断团购券是否售尽
  updateSoldOutItem: (state: HomeListTypeVO, action: PayloadAction<{ id: string | number; isSoldOut: boolean }>) => {
    const { id, isSoldOut } = action.payload;
    // state.groupHasBuyCoupons = {
    //   ...state.groupHasBuyCoupons,
    //   [id]: { isSoldOut }
    // };
    if (state?.groupHasBuyCoupons) {
      state.groupHasBuyCoupons[id] = { isSoldOut };
    }
  },
  upDateCascaderAreaVal: (state: HomeListTypeVO, action: PayloadAction<HomeListTypeVO['curCascaderAreaVal']>) => {
    state.curCascaderAreaVal = action.payload;
  },
  // 是否为第一次领取
  updateFirstReceive: (state: HomeListTypeVO, action: PayloadAction<HomeListTypeVO['isFirstReceive']>) => {
    state.isFirstReceive = action.payload;
  },
  // 是否为第一次领取
  updateFirstRetain: (state: HomeListTypeVO, action: PayloadAction<HomeListTypeVO['isFirstRetain']>) => {
    state.isFirstRetain = action.payload;
  },
  // 更新是否点击筛选列表
  updateIsSelect: (state: HomeListTypeVO, action: PayloadAction<HomeListTypeVO['isSelect']>) => {
    state.isSelect = action.payload;
  },
  // 更新类目
  updateCuisineType: (state: HomeListTypeVO, action: PayloadAction<ICategoryListItemVo[]>) => {
    state.curCuisineType = action.payload;
  },
  updatedCurPosition: (state: HomeListTypeVO, action: PayloadAction<HomeListTypeVO['curPosition']>) => {
    state.curPosition = action.payload;
  },
  updatedIsFirstPage: (state: HomeListTypeVO, action: PayloadAction<HomeListTypeVO['isFirstPage']>) => {
    state.isFirstPage = action.payload;
  },
  updatedListType: (state: HomeListTypeVO, action: PayloadAction<HomeListTypeVO['listType']>) => {
    state.listType = action.payload;
  },
  clearList: (state: HomeListTypeVO) => {
    state.productsRes.list = [];
  }
};
