import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Toast } from 'antd-mobile';
import { RootState } from '@/store';
import request from '@/utils/ajax';
import { LanguageMap } from '@/types/common';

interface ResponseError {
  code: string;
  message: string;
}

// 奖品
export interface AwardItem {
  awardId: number;
  awardDescription: LanguageMap;
  awardImgUrl: LanguageMap;
  awardName: string;
  awardNameMap: LanguageMap;
  awardType: string;
  exchangeCode: string;
  winAwardTime: string;
}
// 规则详情
export interface RuleDetail {
  activityRuleDetail: LanguageMap;
  dailyChanceLimitByUser: number;
  totalChanceLimitByUser: number;
  endTime: string;
  startTime: string;
}

// 活动信息
export interface ActivityInfo {
  activityId?: number;
  activityImgUrl?: LanguageMap;
  activitySubType?: string;
  activityType?: string;
  awardList?: AwardItem[];
  residualDrawChances?: number | null;
  ruleDetail?: RuleDetail;
}

export interface HomeBannerContentItem {
  contentId: string /** 类容id，例如活动id */;
  contentType: string /** 内容类型枚举：image */;
  contentSource: string /** 内容来源：image */;
  imgUrl?: Record<string, string> /** 多语言图片（map类型，key为locale，value为对应语种图片url） */;
  targetUrl: string /** 跳转链接 */;
}
export interface HomeBannerListRes {
  posId: string /** 资源位id */;
  contentList: HomeBannerContentItem[];
}

export interface activityAndBannerData {
  whatsappLink?: string;
  activity?: ActivityInfo; // 活动信息
  banner?: HomeBannerListRes; // banner信息
}

export interface HomeActivityInfoListResData {
  success: boolean;
  error: ResponseError;
  data: activityAndBannerData;
}

export interface QueryActivityRequest {
  //聚合页场景：qpon_aggregation_page
  scene: string;
}

/** 查询优惠聚合页活动信息 */

// A mock function to mimic making an async request for data
const fetchHomeActivityInfoList = async (data?: QueryActivityRequest): Promise<HomeActivityInfoListResData> => {
  return request<QueryActivityRequest, HomeActivityInfoListResData>('/market/activity/query_aggregation_activity', data);
};

export interface ActivityState {
  data: activityAndBannerData;
  loading?: boolean;
}

// 初始化数据
const initialData = {
  whatsappLink: undefined, // WhatsApp链接
  activity: undefined, // 活动信息
  banner: undefined // banner信息
};
const initialLoading = undefined;

const initialState: ActivityState = {
  data: initialData,
  loading: initialLoading
};

export const fetchActivityData = createAsyncThunk('activity/fetchActivityData', async (params: QueryActivityRequest) => {
  const response: HomeActivityInfoListResData = await fetchHomeActivityInfoList(params).then((res) => {
    return res;
  });
  if (!response?.success) {
    if (response?.error?.message) {
      Toast.show({ content: response?.error?.message });
    }
    return response;
  } else {
    return response;
  }
});

// 更新操作
export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // upDateItem: (state, action) => {
    // }
    // 更新活动的loading状态
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    // 初始化activity状态
    resetActivityState: (state) => {
      state.data = initialData;
      state.loading = initialLoading;
    }
  },
  // 异步更新操作
  extraReducers: (builder) => {
    builder
      // 加载操作
      .addCase(fetchActivityData.pending, (state) => {
        state.loading = true;
      })
      // 加载成功
      .addCase(fetchActivityData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.data = data;
        state.loading = false;
      })
      // 加载失败
      .addCase(fetchActivityData.rejected, (state) => {
        state.loading = false;
        // state.data;
      });
  }
});

// export const {} = activitySlice.actions;

export const getActivityData = (state: RootState) => state.activity.data;
export const getActivityLoading = (state: RootState) => state.activity.loading;
export const { setLoading, resetActivityState } = activitySlice.actions;
export default activitySlice.reducer;
