import styles from './index.module.scss';
import Logo from '@/assets/logo.svg';
import { t } from '@/utils/lang';

function LoadingView() {
  return (
    <div className={styles.content}>
      <img className={styles.logo} src={Logo} alt="logo" />
      <span className={styles.loadingText}>{t('languageChangeLoading')}...</span>
    </div>
  );
}
export default LoadingView;
