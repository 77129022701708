import { useNavigate } from 'react-router-dom';
import router from '@/router/routes.tsx';
import { extractPath } from '@/utils/tools/others';
import { SESSION_KEY } from '@/utils/tools/storage';

function useListenerDeepLink() {
  const navigate = useNavigate();
  // 监听客户端回调deepLink
  function handelListenerDeepLink() {
    if (sessionStorage.getItem(SESSION_KEY.HAS_LISTENER_DEEPLINK)) return;
    sessionStorage.setItem(SESSION_KEY.HAS_LISTENER_DEEPLINK, 'true');
    window.addEventListener('ResolveLinkRequest', async function (deepLinkUrl) {
      const deepLinkPath = extractPath(deepLinkUrl?.url) || '';
      const routeExists = router?.routes?.[0]?.children?.some((route) => {
        // 精确匹配路径，可以根据需要调整匹配策略
        return route?.path?.includes(deepLinkPath);
      });
      const param = deepLinkUrl?.url?.split('?')[1];
      navigate(routeExists ? `/${deepLinkPath}?${param}` : '/');
    });
  }
  return { handelListenerDeepLink };
}
export default useListenerDeepLink;
