import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { ListItem as RenamedListItem } from '@/api/home';
import { ResponseError } from '@/types/common';

export interface FindBySearch {
  assetList: RenamedListItem[];
  hasMore: boolean; // 是否有更多
  queryId: string; // 用于查询下一页时候携带
  activityId: number;
}

export interface SearchState {
  success?: boolean;
  error?: ResponseError;
  data?: FindBySearch;
}

export interface SearchDishesState {
  SearchData: SearchState;
  assetList: RenamedListItem[];
  loading?: boolean;
  scrollTop: number;
  searchText: string;
}
const initialState: SearchDishesState = {
  SearchData: {},
  assetList: [],
  loading: false,
  scrollTop: 0,
  searchText: ''
};

// 更新操作
export const searchDishesState = createSlice({
  name: 'search',
  initialState,
  reducers: {
    // 更新加载状态
    updateSearchDishesLoading: (state, action) => {
      state.loading = action.payload;
    },
    // 更新搜索词
    updateSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    // 更新搜索结果列表
    updateSearchDishesData: (state, action) => {
      const { data } = action.payload;
      state.SearchData = action.payload;
      state.assetList = data?.assetList || [];
    },
    // 更新加载更多搜索结果列表
    updateSearchDishesMoreData: (state, action) => {
      const { data } = action.payload;

      state.SearchData = action.payload;
      state.assetList = [...state.assetList, ...(data?.assetList || [])];
    },
    // 更新页面滑动距离
    updateScrollTop: (state, action) => {
      state.scrollTop = action.payload;
    }
  }
});
export const { updateSearchDishesLoading, updateSearchDishesMoreData, updateSearchDishesData, updateScrollTop, updateSearchText } =
  searchDishesState.actions;

export const getSearchDishesData = (state: RootState) => state.search.SearchData;
export const getSearchDishesLoading = (state: RootState) => state.search.loading;
export const getScrollTop = (state: RootState) => state.search.scrollTop;
export const getSearchText = (state: RootState) => state.search.searchText;
export const getAssetList = (state: RootState) => state.search.assetList;

export default searchDishesState.reducer;
