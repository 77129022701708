/* eslint-disable @typescript-eslint/no-explicit-any */
export class Listener<T extends (...args: any[]) => any> {
  private _map: Set<T> = new Set<T>();

  constructor(fn: (listener: (...args: Parameters<T>) => void) => void) {
    fn(this.listener.bind(this));
  }

  listener(...args: Parameters<T>) {
    Array.from(this._map).forEach((fn) => fn(...args));
  }

  add(fn: T) {
    this._map.add(fn);
  }

  remove(fn: T) {
    this._map.delete(fn);
  }
}
