import { getCssVariable } from '@/utils/tools/cssVariable';
import { getLanguage } from '@/utils/lang';

// 定制化接口，跳转扫码点餐页面，添加额外参数sbh，uk
export function addExtraQuery(url: string) {
  // uk uniqueKey即token
  // sbh statusBarHeight 安全高度，状态栏高度
  // URL上面拼接参数
  // 定义一个参数，额外的查询函数
  const sbh = getCssVariable('--status-bar-height');
  console.warn('sbh', sbh);
  const uk = localStorage.getItem('tokenInfo');
  console.warn('uk', uk);
  const lang = getLanguage();
  console.warn('language', lang);
  const queryParts: string[] = [];
  if (uk) queryParts.push(`uk=${encodeURIComponent(uk)}`);
  if (sbh) queryParts.push(`sbh=${sbh}`);
  if (lang) queryParts.push(`language=${lang}`);
  if (queryParts.length === 0) return url;
  const extraQuery = queryParts.join('&');
  const finalUrl = `${url}&${extraQuery}`;
  console.warn('addExtraQuery finalUrl', finalUrl);
  return finalUrl;
}
