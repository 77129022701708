/* 
迁移home/list.ts文件
移除state:curpage;loadMoreParams; curPosition; stauts;scrollTop;redPacletVisible
*/

import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './state';
import { reducers, extraReducers, getMoreAsync } from './action';
import { RequestFirstPageListDataType } from './type';
import { RootState, AppThunk } from '@/store';
import { FindByDistanceResponseData } from '@/api/home/index';

// 更新操作
export const homeSlice = createSlice({
  name: 'homeList',
  initialState,
  reducers,
  // 异步更新操作
  extraReducers
});

export const {
  updateRequestParmasReducers,
  updatedProductsReducers,
  updatedRecommondProductsReducers,
  updateCuisineType,
  updateIsSelect,
  updateFirstRetain,
  updateFirstReceive,
  upDateCascaderAreaVal,
  updateSoldOutItem,
  upDateItem,
  updatedCurPosition,
  updatedIsFirstPage,
  clearList
} = homeSlice.actions;

// 获取操作
export const getProductsRes = (state: RootState) => state.homeList.productsRes;

export const getRecommondProductsRes = (state: RootState) => state.homeList.recommondProductsRes;

export const getListType = (state: RootState) => state.homeList.listType;

export const getAllListData = (state: RootState) => state.homeList.productsRes.list.concat(state.homeList.recommondProductsRes.list);

export const getCurCuisineType = (state: RootState) => state.homeList.curCuisineType;

export const getCurCascaderAreaVal = (state: RootState) => state.homeList.curCascaderAreaVal;

export const getRecivedCoupons = (state: RootState) => state.homeList.recivedCoupons;

export const getGroupHasBuyCoupons = (state: RootState) => state.homeList.groupHasBuyCoupons;

export const getIsFirstReceive = (state: RootState) => state.homeList.isFirstReceive;

export const getIsFirstRetain = (state: RootState) => state.homeList.isFirstRetain;

export const getIsSelect = (state: RootState) => state.homeList.isSelect;

export const getCurPosition = (state: RootState) => state.homeList.curPosition;

export const getListTypeData = (state: RootState) => state.homeList.productsRes.listTypeData;

export const getisFirstPage = (state: RootState) => state.homeList.isFirstPage;

export const getPositionParmas = (state: RootState) => {
  return { lat: state.homeList.lat, lng: state.homeList.lng };
};

export const getReqQueryParam = (state: RootState) => {
  return {
    ...state.homeList.commonQueryparmas,
    ...state.homeList.regionQueryParams,
    ...state.homeList.distanceQueryParams,
    ...state.homeList.recommondQueryParams,
    listType: state.homeList.listType
  };
};

export const getPageSize = (state: RootState) => {
  return state.homeList.commonQueryparmas.pageSize;
};

export const getDataItem =
  (token: string): AppThunk =>
  (_, getState) => {
    const currentValue = getAllListData(getState());
    const result = currentValue.find((item) => item.token === token);
    return result;
  };

export const requestFirstPageListData =
  (parmas: RequestFirstPageListDataType): AppThunk<Promise<FindByDistanceResponseData>> =>
  async (dispatch, getState): Promise<FindByDistanceResponseData> => {
    // 更新参数
    dispatch(updateRequestParmasReducers(parmas));
    dispatch(updatedIsFirstPage(true));
    // 获取最新的参数state
    const currentState = getReqQueryParam(getState());
    const res = await dispatch(getMoreAsync({ ...currentState }));
    return res.payload as FindByDistanceResponseData;
  };

export const loadMoreFn =
  (parmas: object): AppThunk =>
  (dispatch) => {
    dispatch(updatedIsFirstPage(false));
    dispatch(getMoreAsync(parmas));
  };

export default homeSlice.reducer;
