import { webSeeNativeSdkLog } from '../websee';
/* eslint-disable @typescript-eslint/no-explicit-any */
export const ErrorCode = Object.freeze({
  /** 未获取到权限 */
  NO_PERMISSION: '-4',
  /** 定位权限被拒绝 */
  PERMISSION_DENIED: '-5',
  /** 方法未实现 */
  NOT_IMPLEMENTED: '-2',
  /** web未实现 */
  WEB_IMPLEMENTED: '-3',
  /** 当前环境不在qpon App中 */
  NOT_QPON_APP: '-6',
  /** 已获取权限无法获取定位 */
  NOT_USE_WITH_GRANTED: '-7'
});
export interface IResult<T = any> {
  code: number;
  data?: T | null;
  message?: string;
}
type ISuccess<T = any> = (data: any) => IResult<T>;
type IFail<T = any> = (error: Error & { code?: string | number }) => IResult<T>;
const success: ISuccess = (data) => {
  return { code: 0, data: data };
};
const fail: IFail = (error) => {
  const result = {
    message: error.message,
    ...getErrorCode(error),
    data: error
  };
  result.code === -1 && webSeeNativeSdkLog(error);
  return result;
};

const getErrorCode = (error: Error & { code?: string | number }) => {
  if (error.message.includes('not implemented') || error.message.includes('Not implemented')) {
    return { code: +ErrorCode.NOT_IMPLEMENTED, message: '方法未实现' };
  } else if (error.message.includes('is not a constructor')) {
    return { code: +ErrorCode.WEB_IMPLEMENTED, message: 'web未实现' };
  }
  switch (error.message) {
    case ErrorCode.NO_PERMISSION: {
      return { code: +ErrorCode.NO_PERMISSION, message: '未获取到权限' };
    }
    case ErrorCode.PERMISSION_DENIED:
    case 'Location services are not enabled':
    case 'Location permission was denied': {
      return { code: +ErrorCode.PERMISSION_DENIED, message: '定位权限被拒绝' };
    }
    case ErrorCode.NOT_QPON_APP: {
      return { code: +ErrorCode.NOT_QPON_APP, message: '当前环境不在qpon App中' };
    }
    case ErrorCode.NOT_USE_WITH_GRANTED: {
      return { code: +ErrorCode.NOT_USE_WITH_GRANTED, message: '已获取权限无法获取定位' };
    }
    default:
      break;
  }
  return { code: -1 };
};

type MiddlewareReturn<T extends (...args: any) => any> = T extends (...args: any[]) => Promise<any>
  ? (...args: Parameters<T>) => Promise<IResult<Awaited<ReturnType<T>>>>
  : (...args: Parameters<T>) => IResult<ReturnType<T>>;

export function middleware<T extends (...args: any[]) => any>(fn: T): MiddlewareReturn<T> {
  return ((...args: Parameters<T>): IResult<ReturnType<T>> | Promise<IResult<ReturnType<T>>> => {
    if (typeof fn !== 'function') {
      return fail(new Error('fn is not a function'));
    }
    try {
      const originalResult = fn(...args);
      if (originalResult instanceof Promise) {
        return originalResult.then(success).catch(fail);
      } else {
        return success(originalResult);
      }
    } catch (error: any) {
      return fail(error as Error);
    }
  }) as MiddlewareReturn<T>;
}
