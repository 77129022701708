// Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
export const firebaseConfig = {
  apiKey: 'AIzaSyCE_QyjS8Gwygq2JFcgn2M5i_YPWglldQs',
  authDomain: 'qpon-1c174.firebaseapp.com',
  databaseURL: 'https://qpon-1c174-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'qpon-1c174',
  storageBucket: 'qpon-1c174.appspot.com',
  messagingSenderId: '786264308667',
  appId: '1:786264308667:web:5dcbb8615bf09a4fefa29e',
  measurementId: 'G-D6Q6G1LKRN'
};

// FCM VAPID
export const VAPID = 'BLt-rEyB61qVeChu9QTIoNevDvnXb_UeknPA5hscIe4n_v24-q3wbPyCUNUtSY5442cJZLhQSQaME7RP7JwkhFY';

// export const notificationDefaultConfig = {
//   title: 'Qpon',
//   body: 'Qpon 活动推送',
//   // MARK 需要更改
//   icon: 'https://dgf-bres-gl.qponmobile.com/common/740241902396342487/66acb34fb1912e1a86c46bea35d71480.jpg'
// };

// 消息通知默认打开页面
export const notificationDefaultPage = 'https://qpon.id?channel=notification';

// html页面缓存时长5分钟
export const htmlCacheMaxAge = 5 * 60;
