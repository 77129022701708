import { isQponApp } from '../nativeSdk';
import { LOCAL_KEY, storage } from './storage';

interface BrowserInfo {
  androidReleaseVersion: string;
  androidSDKVersionId: string;
  brand: string;
  browserVersion: string;
  colorOSVersionCode: string;
  colorOsVersion: string;
  cpuArch: string;
  duid: string;
  gaid: string;
  guid: string;
  imei: string;
  isRs3Feature: boolean;
  mobileName: string;
  mobileRomVersion: string;
  networkType: string;
  ouid: string;
  rsaType: string;
}

interface ClientInfo {
  adId: string;
  [key: string]: string;
}
interface appInfo {
  id: string;
  versionName: string;
  versionCode: string;
  h5_version_name: string;
  h5_version_code: string;
}
// 缓存数据
let browserInfo: BrowserInfo | null = null;
const getBrowserInfo = () => {
  try {
    if (browserInfo) return browserInfo;
    if (window.appJsBridge) {
      const infoRes = window.appJsBridge.callApi('getPrivateBrowserInfo');
      const info: BrowserInfo | null = infoRes?.data ? (infoRes.data as BrowserInfo) : null;
      browserInfo = info;
      return info;
    }
    return null;
  } catch (error) {
    console.warn('getBrowserInfo error', error);
    return null;
  }
};

const getBrowserImei = () => {
  const browserInfo = getBrowserInfo();
  return browserInfo?.imei || '';
};
const getAndroidReleaseVersion = () => {
  const browserInfo = getBrowserInfo();
  return browserInfo?.androidReleaseVersion || '';
};
const getBrowserVersion = () => {
  const browserInfo = getBrowserInfo();
  return browserInfo?.browserVersion || '';
};

const getOpenId = () => {
  const browserInfo = getBrowserInfo();
  const isApp = isQponApp();
  const dfpObj: ClientInfo = storage.getLocalStorage(LOCAL_KEY.CLIENT_DEVICE_INFO, true) as ClientInfo;
  const openId = {
    duid: '',
    ouid: '',
    guid: '',
    gaid: ''
  };
  //app里取客户端透传的相关id
  if (isApp && dfpObj) {
    // obus埋点需要小写的adid
    return { ...dfpObj, adid: dfpObj?.adId };
  }

  if (!browserInfo) {
    return openId;
  }
  const { duid, ouid, guid, gaid } = browserInfo;
  return {
    duid,
    ouid,
    guid,
    gaid
  };
};

// 获取app信息
const getAppInfoObj = () => {
  let appInfo = {};
  try {
    const h5Version = storage.getLocalStorage(LOCAL_KEY.H5_VERSION, true) as appInfo;
    const appDataInfo = storage.getLocalStorage(LOCAL_KEY.CLIENT_INFO, true) as appInfo;
    appInfo = {
      app_package: appDataInfo?.id,
      app_version_name: appDataInfo?.versionName,
      app_version_code: appDataInfo?.versionCode,
      h5_version_name: h5Version?.h5_version_name,
      h5_version_code: h5Version?.h5_version_code
    };
  } catch (error) {
    console.log(error);
  }
  return appInfo;
};

export { getBrowserInfo, getBrowserImei, getOpenId, getBrowserVersion, getAndroidReleaseVersion, getAppInfoObj };
