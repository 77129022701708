/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMount } from 'ahooks';
import { RouterState } from '@remix-run/router';
import router from '@/router/routes';

const jsonParas = (str: string | null) => {
  try {
    return JSON.parse(str || '');
  } catch (error) {
    return null;
  }
};

export const getStack = () => jsonParas(sessionStorage.getItem('historyStack'));
const idx = { current: history.state?.idx, previous: history.state?.idx };
const stacks: any = [];
export const useHistoryStack = () => {
  const isForward = () => idx.current > idx.previous; // 是否前进
  const watchRouter = () => {
    router.subscribe((state) => {
      idx.previous = idx.current;
      idx.current = history.state?.idx;
      console.log('useHistoryStack：', state, isForward());
      recordStack(state);
    });
  };
  const recordStack = (state: RouterState) => {
    const { historyAction, location } = state;
    const data = { type: historyAction, ...location };
    switch (historyAction) {
      case 'PUSH':
        stacks.unshift(data);
        break;
      case 'POP': {
        if (isForward()) {
          // 前进
          stacks.unshift(data);
        } else {
          // 后退
          stacks.shift();
        }
        break;
      }
      case 'REPLACE':
        stacks.splice(0, 1, data);
        break;
      default:
        break;
    }
    sessionStorage.setItem('historyStack', JSON.stringify(stacks));
    console.log('路由堆栈记录', stacks);
  };
  useMount(() => {
    watchRouter();
  });
};
