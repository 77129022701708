/**
 * 查找对象对应的key值
 * @param value
 */
export const findKeyByValue = (obj, value) => {
  for (const [key, val] of Object.entries(obj)) {
    if (val === value) {
      return key;
    }
  }
  // 如果没有找到匹配的值，返回null
  return null;
};
