import { bspwwasSdk } from '@qpon-design/bspwwas';
import { LOCAL_KEY, storage } from '../tools';
import { monitorReport } from '../monitor/monitorReport';
import { generateUUID } from '../tools/others';
import queryDeviceId from '@/api/common/queryDeviceId';

// 同步deviceId到本地
const syncDeviceIdToLocal = (deviceId: string) => {
  if (deviceId) {
    storage.setLocalStorage(LOCAL_KEY.DEVICE_ID, deviceId);
  }
};

// 从本地获取deviceId
const getDeviceIdFromLocal = () => {
  return storage.getLocalStorage(LOCAL_KEY.DEVICE_ID);
};

// 从本地/网络/obus获取deviceId
const getDeviceId = async () => {
  const localDeviceId = getDeviceIdFromLocal();
  // 1.本地存在
  if (localDeviceId) return localDeviceId;

  // 2.风控资源加载及初始化，确保签名成功
  const { hasInit } = await bspwwasSdk.bspLoadAndInit();
  const obusDeviceId = window.otrack?.getDeviceId() || generateUUID();
  if (!window.otrack?.getDeviceId) {
    monitorReport.info({ category: 'window.otrack.getDeviceId 失败', msg: { message: navigator?.onLine } });
  }
  // 初始化失败
  if (!hasInit) {
    return obusDeviceId;
  }

  // 签名测试成功,调用风控接口获取deviceId
  const deviceId = (await queryDeviceId()) || obusDeviceId;
  return deviceId;
};

// 从本地/网络/obus获取deviceId并同步到本地和XApp
const initDeviceId = async () => {
  const deviceId = await getDeviceId();
  if (!deviceId) return null;
  syncDeviceIdToLocal(deviceId);
  return deviceId;
};
export { initDeviceId, getDeviceIdFromLocal };
