import { FC } from 'react';
import { Outlet } from 'react-router-dom';

const RootLayout: FC = () => {
  return (
    <div className="root-layout">
      {/* 子路由内容会渲染在这里 */}
      <Outlet />
    </div>
  );
};

export default RootLayout;
