/**
 * 只支持具备实时压缩图片功能的CDN使用该功能
 * warning:目前这个方法仅对oppo侧的图片做处理，生态过来的数据不做处理
 *
 * width:   压缩后的图片宽度, 0代表自适应
 * quality: 压缩后的图片质量, 最大100
 * */
export const compressImg = (url: string | undefined, opts: { width?: number; height?: number; quality?: number }): string => {
  if (!url) {
    return '';
  }

  const domain = import.meta.env?.VITE_OPPO_DOMAIN_IMG || 'qponmobile.com';
  const isOppoImg = url.includes(domain);
  if (!isOppoImg) return url;
  const { width = 0, height = 0 } = opts;
  let size = '';
  width && (size += `,w_${width}`);
  height && (size += `,h_${height}`);
  // https://oppo-apk.com.cn/prd/menu/pic/98013241930162176/%E5%87%89%E7%9A%AE.webp?v=1703088159&x-amz-process=image/resize,m_fill,h_%s,w_%s/format,webp
  if (url.includes('v=')) {
    return `${url}&x-amz-process=image/resize,m_fill${size}/format,webp`;
  } else {
    return `${url}?x-amz-process=image/resize,m_fill${size}/format,webp`;
  }
};
