// 首页聚合接口
import { PromotionInfo } from '../order/settlementApi';
import request from '@/utils/ajax';
import { ResponseError, LanguageMap } from '@/types/common';

// 来源
export enum SourceEnum {
  APP = 'APP',
  H5 = 'H5'
}

// 卡片类型数组
export enum CardIdEnum {
  PAGE_HOME_BANNER_CARD = 'PAGE_HOME_BANNER_CARD', // 顶部BANNER卡片
  FLASH_SALE_CARD = 'FLASH_SALE_CARD', // 爆款专区卡片
  BRAND_PAVILION_CARD = 'BRAND_PAVILION_CARD', // 品牌馆卡片
  MALL_NEARBY_CARD = 'MALL_NEARBY_CARD' // 附近商场
}

// 首页卡片聚合接口请求参数
export interface HomeCardParams {
  cardIds: CardIdEnum[];
}

// 爆款列表数据接口
interface ProductItem {
  imageUrl: string;
  itemName: LanguageMap;
  salePrice: string;
  originalPrice: string;
  discountAmount: string;
  discountPercent: string;
  itemId: string;
  skuId: string;
  promotionInfo?: PromotionInfo;
}

// 爆款专区卡片数据接口
export interface FlashSalePageData {
  countdownTime: number;
  title: LanguageMap;
  items: ProductItem[];
  pageId: string;
  productListId: string;
  hasMore: boolean;
  pageType: string;
  sessionId?: string;
  sessionTime?: string;
}

// 顶部BANNER卡片数据接口
export interface BannerCardData {
  name: string;
  image: string;
}

// 品牌Logo的接口
interface BrandLogo {
  subTitle: string;
  logo: string;
}
// 品牌馆卡片数据的接口
export interface BrandPavilionData {
  name: LanguageMap;
  brandLogo: BrandLogo[];
  hasMore: boolean;
}
// 附近商场卡片数据的接口
export interface MallNearbyData {
  bigCoverImgUrl: string; // 大图url地址
  smallCoverImgUrl: string; // 小图url地址
  id: string; // 商场id
  mallDetailUrl: string; // 商场详情页url地址
  mallName: string; // 商场名称
  longitude: string; // 商场经度
  latitude: string; // 商场纬度
  distance: string; // 距离
  mallAddress: string; // 商场地址
  pageId: string;
  status: string;
  storeNumbers: string; // 门店数量
}

// 聚合接口返回数据
export interface CardListData {
  cardList: [
    {
      cardId: CardIdEnum.BRAND_PAVILION_CARD;
      data: BrandPavilionData;
    },
    {
      cardId: CardIdEnum.FLASH_SALE_CARD;
      data: FlashSalePageData;
    },
    {
      cardId: CardIdEnum.MALL_NEARBY_CARD;
      data: MallNearbyData[];
    },
    {
      cardId: CardIdEnum.PAGE_HOME_BANNER_CARD;
      data: BannerCardData[];
    }
  ];
}
export interface ResponseData {
  success: boolean;
  error: ResponseError;
  data: CardListData;
}

/**
 * 首页卡片聚合接口
 */
const queryPageCardList = (data?: HomeCardParams): Promise<ResponseData> => {
  return request<HomeCardParams, ResponseData>('/local_life/home/page_card_list', data);
};

export default queryPageCardList;
