/**
 * 将versionName转成versionCode
 * 每一位数字转成3位数字，拼接后将最前面的0抹掉
 * 例如'2.8.3'转成2008003
 */
import h5Manifest from '$/h5_manifest.json';

export const convertStringToNumber = (str) => {
  if (str) {
    const parts = str.split('.');
    const result = parts.map((part) => parseInt(part).toString().padStart(3, '0')).join('');
    return parseInt(result);
  } else {
    return null;
  }
};

/**
 * 获取h5版本
 */
export const getH5Version = () => {
  try {
    const manifestData = h5Manifest;
    console.warn('manifestData', manifestData);
    const h5_version_name = manifestData?.h5_version;
    const h5_version_code = convertStringToNumber(h5_version_name);
    const data = {
      h5_version_name,
      h5_version_code
    };
    return data;
  } catch (error) {
    console.error('Error loading h5 manifest:', error);
    return null;
  }
};

/**
 * 初始和存储h5版本
 */
export const initH5Version = () => {
  const data = getH5Version();
  localStorage.setItem('h5_version', JSON.stringify(data));
};
