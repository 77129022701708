/* eslint-disable @typescript-eslint/no-explicit-any */
import { isPre, isProd } from '../env';
import { SESSION_KEY, storage } from '@/utils/tools';

type ScenarioMapType = {
  [key: string]: string; // 允许任何字符串作为键
};
// scenario为验证场景，order为下单场景

export function genMyCaptcha(style: string, nodeBox: string, callback: (arg0: any) => void, scenario?: string) {
  const isPreProd = isProd || isPre;
  let languageStr = 'en';
  const language = window.navigator.language;
  // 语言国际化
  if (/ID/i.test(language)) languageStr = 'in_ID';
  if (/US/i.test(language) || /GB/i.test(language) || /EN/i.test(language)) {
    languageStr = 'en';
  }
  if (/^zh/i.test(language)) languageStr = 'cn';
  // 组件挂载时，创建script标签
  const script = document.createElement('script');
  const scenarioMap: ScenarioMapType = {
    other: isPreProd ? 'aacafc281e26e806dfd5c1c1f43f2f19' : 'f5e776e4fff68b41b2ea542869e7a0c9',
    order: isPreProd ? 'df1c01dbb50b85a472207796c11bae96' : '8a284000bb35b5d7da1274e58bd96d70'
  };
  const serverUrl = import.meta.env.VITE_CAPTCHA_HOST || 'https://captcha-sgp-sec.heytapmobile.com';
  // 设置标签的src属性
  script.src = `${serverUrl}/dx-captcha/index.js`;
  // 追加到body标签的最下面
  script.onload = script.onratechange = () => {
    const ready = (script as any).ready;
    const readyState = (script as any).readyState;
    if (ready) {
      return false;
    }
    if (
      !readyState || //这是FF的判断语句，因为ff下没有readyState这个值，IE的readyState肯定有值
      readyState == 'loaded' ||
      readyState == 'complete' // 这是IE的判断语句
    ) {
      (script as any).ready = true;
      const apiServer = serverUrl;
      const constIDServer = serverUrl + '/udid/c1';
      const constIDJs = serverUrl + '/dx-captcha/libs/const-id.js';
      const uaJs = serverUrl + '/dx-captcha/libs/greenseer.js';
      let isSuccess = false; //验证码滑块是否成功滑过
      const myCaptcha = window._dx.Captcha(document.getElementById(nodeBox), {
        appId: scenarioMap[scenario || 'other'], // 必填
        apiServer: apiServer, //必填
        orderFrom: storage.getSessionStorage(SESSION_KEY.SOURCE_TAG) || 'qpon',
        constIDServer: constIDServer, //必填
        constID_js: constIDJs, //必填
        //width : 288,//宽度默认288，有效范围200-500
        isSaaS: false, //必须
        ua_js: uaJs, //必须
        style, //页面展示方式，有四种：embed(嵌入式)、inline（内联式）、popup（弹出式）、onclick（触发式）下文有详细说明
        language: languageStr, //语言 en英文 cn中文 in_ID印尼
        constID_options: {
          iframeUrl: false //必须
        },
        success: function (token: any) {
          isSuccess = true;
          //用户操作验证码成功时调用
          myCaptcha.hide();
          // 这里有点奇怪，success有时会执行两次，建议callback函数做防抖节流处理
          return callback({
            success: true,
            token
          });
          //业务自己实现逻辑：将token传递给后端进行token校验。后端返回校验成功后，验证码整个校验流程才算成功
        },
        fail: function (errToken: any) {
          //用户操作验证码失败时调用
          return callback({
            success: false,
            errToken
          });
          //业务逻辑
        }
      });
      myCaptcha.show();

      // 切换页面时关掉
      const hide = () => {
        myCaptcha.hide();
        window.removeEventListener('popstate', hide);
      };
      window.addEventListener('popstate', hide);
      myCaptcha.on('hide', function () {
        //console.log('验证码控件被隐藏了。', isSuccess);
        if (isSuccess) return; //滑块是成功的 不执行手动隐藏的逻辑 隐藏逻辑除了滑块成功会自动隐藏，其他都为手动触发隐藏
        return callback({
          success: false,
          hide: true
        });
      });
    }
  };
  document.body.appendChild(script);
}
