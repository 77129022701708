import { memo } from 'react';
import { useMount } from 'ahooks';
import styles from './index.module.scss';
import useReport from '@/hooks/useReport';

interface ViewMoreProps {
  isExpanded: boolean;
  onToggle: () => void;
  textExpanded: string;
  textCollapsed: string;
  triangleUp: string;
  triangleDown: string;
}
// 查看更多组件
const ViewMore: React.FC<ViewMoreProps> = ({ isExpanded, onToggle, textExpanded, textCollapsed, triangleUp, triangleDown }) => {
  const { report } = useReport();
  useMount(() => {
    report('coupon_view_more', { type: 'view' });
  });
  const onToggleFn = () => {
    report('coupon_view_more_btn', { type: 'click' });
    onToggle();
  };
  return (
    <div className={styles.viewMore} onClick={onToggleFn}>
      <span className={styles.viewMoreText}>{isExpanded ? textExpanded : textCollapsed}</span>
      <img src={isExpanded ? triangleUp : triangleDown} alt="Toggle" />
    </div>
  );
};

export default memo(ViewMore);
