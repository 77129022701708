import { LanguageMap, ResponseError } from '@/types/common';
import request from '@/utils/ajax';

export interface ReceiveReq {
  couponBatchId?: number;
  couponTemplateIds?: number[];
  activityId?: number;
}

export interface StoreList {
  storeId?: string;
  storeName?: string;
  storeAddress?: string;
  storeLongitude?: string;
  storeLatitude?: string;
  storeImageUrl?: string;
}

export interface CouponList {
  id?: string;
  couponTemplateId?: number;
  couponName?: string;
  couponType?: string;
  couponTypeName?: string;
  couponValue?: string;
  couponDescription?: LanguageMap;
  useConditionDescription?: LanguageMap;
  useScopeDescription?: LanguageMap;
  useRuleDescription?: LanguageMap;
  status?: string;
  effectiveTimeLimited?: string;
  effectiveStartTime?: string;
  receivedTime?: string;
  useScopeMinOrderPrice?: string;
  storeList?: StoreList[];
  useScopeOrderFoodTypes?: number[];
}

export interface Data {
  couponList?: CouponList[];
}

export interface ReceiveRes {
  success?: boolean;
  error?: ResponseError;
  data?: Data;
}

/**
 * 领取优惠券
 *
 */

export const receiveCopon = async (data: ReceiveReq): Promise<ReceiveRes> => {
  return await request<ReceiveReq, ReceiveRes>('/market/coupon/receive', data);
};
