// import { AES, enc } from 'crypto-js';
import axiosConfig from '../config/baseConfig';
import { safeJsonParse } from '../tool';
import { XAPP, XDigitalFood, XSys } from '../interface';
// import { signConfig } from './config';
// 清除登录信息
const clearAccessTokenConfig = () => {
  delete axiosConfig.headers['Authorize']; // todo
};

// 更新AppData到公共头 X-Digital-Food 不包含经纬度 经纬度额外处理
const updateXDigitalFoodHeader = (data: Omit<XDigitalFood, 'coors'>) => {
  const originlXDigital = safeJsonParse(axiosConfig.headers['X-Digital-Food']);
  Object.keys(data).forEach((id) => {
    const value = data[id as keyof AppData];
    originlXDigital[id] = value;
  });
  axiosConfig.headers['X-Digital-Food'] = encodeURIComponent(JSON.stringify(originlXDigital));
};

// 定位信息
const updateXDigitalFoodCoorsHeader = (latitude: number, longitude: number) => {
  const originlXDigital = safeJsonParse(axiosConfig.headers['X-Digital-Food']) as XDigitalFood;
  // 加密
  const locationString = `${latitude},${longitude}`;
  const encodedLocation = btoa(locationString);
  originlXDigital.coors = encodedLocation;
  axiosConfig.headers['X-Digital-Food'] = encodeURIComponent(JSON.stringify(originlXDigital));
};

// 更新XSys
const updateXSysHeader = (data: XSys) => {
  const originlXSys = safeJsonParse(axiosConfig.headers['X-Sys']);
  Object.keys(data).forEach((id) => {
    originlXSys[id] = data[id as keyof XSys];
  });
  axiosConfig.headers['X-Sys'] = encodeURIComponent(JSON.stringify(originlXSys));
};
// 更新X-Model
const updateXModelHeader = (model: string) => {
  if (model) {
    axiosConfig.headers['X-Model'] = model;
  }
};

// 更新XApp
const updateXAppHeader = (data: XAPP) => {
  const originlXApp = safeJsonParse(axiosConfig.headers['X-APP']);
  Object.keys(data).forEach((id) => {
    originlXApp[id] = data[id as keyof XAPP];
  });
  axiosConfig.headers['X-APP'] = encodeURIComponent(JSON.stringify(originlXApp));
};
// 更新

export { clearAccessTokenConfig, updateXDigitalFoodHeader, updateXSysHeader, updateXAppHeader, updateXDigitalFoodCoorsHeader, updateXModelHeader };
