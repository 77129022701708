import { useNavigate } from 'react-router-dom';
import { Popover, PopoverRef } from 'antd-mobile';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';
import { t } from '@/utils/lang';
import coupon from '@/assets/merchant/coupon.svg';
import home from '@/assets/merchant/home.svg';
import backPage from '@/assets/merchant/back-page.svg';
import popoverCancel from '@/assets/merchant/popoverCancel.svg';
import { useApiQuerymyCouponCount } from '@/api/home';
import useLogin from '@/hooks/useLogin';
import { storage, LOCAL_KEY } from '@/utils/tools/storage';
import useReport from '@/hooks/useReport';
import { getStack } from '@/hooks/useHistoryStack';

interface NavBarProps {
  storeId: string;
}

const NavBar: React.FC<NavBarProps> = ({ storeId }) => {
  const navigate = useNavigate();
  const { report } = useReport();
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);
  const popoverVisible = storage.getLocalStorage(LOCAL_KEY.POPOVER_VISIBLE);
  const popoverRef = useRef<PopoverRef>(null);
  const imgSrc = useMemo(() => (getStack?.()?.length > 0 ? 'backPage' : 'backHome'), []);
  // 判断当前是否登录
  const { isLogin } = useLogin();
  const { data, run } = useApiQuerymyCouponCount({ status: 'valid,not_effective' });
  // 登录后拉数据
  useEffect(() => {
    if (isLogin) {
      // app1.0版本产品要求做屏蔽，原因是此接口查询的是订单和优惠券的数量，订单跟优惠券已从一个页面改成不同页面，入口跳订单，提示数量不准确
      // setTimeout(run, 2000);
    }
  }, [isLogin, run]);
  const [isRedIconVisible, popoverText, key] = useMemo(() => {
    const { waitPayCount = 0, usableCount = 0 } = data?.data || {};
    const isRedIconVisible = waitPayCount > 0 || usableCount > 0;
    let popoverText = '';
    let key = '';
    if (waitPayCount > 0) {
      popoverText = t('merchant.pendingDisbursement', { num: waitPayCount });
      key = 'waitPay';
    } else if (usableCount > 0) {
      popoverText = t('merchant.unusedCoupon', { num: usableCount });
      key = 'useable';
    }
    if (isRedIconVisible) {
      if (!popoverVisible) {
        storage.setLocalStorage(LOCAL_KEY.POPOVER_VISIBLE, 'true');
      }
      setIsPopoverVisible(true);
    }
    return [isRedIconVisible, popoverText, key];
  }, [data, popoverVisible]);
  const handlePopover = (flag: boolean) => {
    storage.setLocalStorage(LOCAL_KEY.POPOVER_VISIBLE, 'false');
    setIsPopoverVisible(flag);
    // if (!popoverRef?.current) return;
    // flag ? popoverRef.current?.show() : popoverRef.current?.hide();
  };

  // 返回首页
  const homeAction = () => {
    // 顶部Icon点击埋点上报
    report('top_icon_btn', {
      type: 'click',
      icon_name: '回到首页',
      store_id: storeId
    });
    imgSrc === 'backPage' ? navigate(-1) : navigate('/');
  };

  // 跳转我的优惠券
  const couponAction = useCallback(() => {
    // 顶部Icon点击埋点上报
    report('top_icon_btn', {
      type: 'click',
      icon_name: '我的订单',
      store_id: storeId
    });
    const url = key ? `/platform/home/order?key=${key}` : `/platform/home/order`;
    navigate(url);
  }, [key, navigate, report, storeId]);
  const rightIcon = useMemo(() => {
    if (isRedIconVisible && isPopoverVisible && popoverVisible === 'true') {
      return (
        <div className={styles.showRedIcon} onClick={couponAction}>
          <Popover
            ref={popoverRef}
            mode="dark"
            className={styles.homePopover}
            defaultVisible
            content={
              <div className={styles.popoverInfo}>
                <div>{popoverText}</div>
                <img src={popoverCancel} alt="" onClick={() => handlePopover(false)} />
              </div>
            }
            trigger="click"
            placement="bottom"
          >
            <img src={coupon} alt="" />
          </Popover>
        </div>
      );
    }
    return (
      <div className={isRedIconVisible ? styles.showRedIcon : styles.showIcon} onClick={couponAction}>
        <img src={coupon} alt="" />
      </div>
    );
  }, [couponAction, isPopoverVisible, isRedIconVisible, popoverText, popoverVisible]);

  return (
    <div className={styles.merchantNavBar}>
      <img src={imgSrc === 'backPage' ? backPage : home} alt="" onClick={homeAction} />
      {rightIcon}
    </div>
  );
};
export default NavBar;
