import { useMount } from 'ahooks';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getPositionAsync, getPositionInfo, PositionReduxInterface } from '@/store/global/position';

type PositionReturn = {
  positionInfo: PositionReduxInterface;
  manualGetPosition: () => Promise<PositionReduxInterface>;
};
let loading = false; // 全局只进行一次进行中的定位
function usePosition(manual?: boolean): PositionReturn {
  // manual 是否手动获取 默认为false 表示会初始化自动拉取定位
  const dispatch = useAppDispatch();
  const positionInfo = useAppSelector(getPositionInfo);

  async function manualGetPosition() {
    console.log('locationAction', 'usePosition手动拉定位');
    const resp = await dispatch(getPositionAsync({}));
    return (resp?.payload || {}) as PositionReduxInterface;
  }
  useMount(async () => {
    if (!manual && !positionInfo.success && !loading) {
      console.log('locationAction', 'usePosition拉定位');
      loading = true;
      await dispatch(getPositionAsync({}));
      loading = false;
    }
  });
  return { positionInfo: positionInfo, manualGetPosition };
}

export default usePosition;
