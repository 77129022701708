export const setCookie = function (name: string, value: string, expires?: number | null, path?: string | null): void {
  console.log('传进来的语言', value, 'window.navigator.language:', window.navigator.language);
  let cookieString = `${name}=${encodeURIComponent(value)}`;

  if (expires) {
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + expires * 24 * 60 * 60 * 1000);
    cookieString += `; expires=${expiryDate.toUTCString()}`;
  }

  if (path) {
    cookieString += `; path=${path}`;
  }

  document.cookie = cookieString;
};

export const getCookie = function (name: string): string | undefined {
  const cookies: string[] = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]!.trim();
    const [cookieName, cookieValue] = cookie.split('=');

    if (cookieName === name) {
      return decodeURIComponent(cookieValue!);
    }
  }

  return undefined;
};

/**
 *
 * @param cookieName
 */
export const deleteCookie = function (cookieName: string): void {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
