import { getRemoteConfig, getValue, fetchAndActivate } from 'firebase/remote-config';
import { initializeApp } from 'firebase/app';
import { syncH5Vesion, asyncInitSafeHeight, backListener, fixInneHeight, syncInitDevOpts } from '../appMount/actions';
import { getQueryParam } from '../tools/others';
import { asyncInitAppInfo, asyncInitDeviceInfo } from './actions';
import { monitorReport, updateExcludeStrsReportErrorConfig } from '@/utils/monitor/monitorReport';
import { updateExcludeErrorCode } from '@/utils/constants/errorCode';
import { initDeviceId } from '@/utils/ajax/setDeviceId';
import { customExceptionReport } from '@/utils/monitor/exceptionReport';
import { SESSION_KEY, storage } from '@/utils/tools';
import { firebaseConfig } from '@/sw/config';
import { addListener, isQponApp } from "@/utils/nativeSdk";

let hasInit = false;

// 初始化同步代码
async function syncInit() {
  // hook必须在逻辑最外层调用，需要确保重复调用无副作用
  // 1.obus初始化, 多次调用不会重复初始化 @雷浩
  // 2.处理session数据以及sessionId初始化
  // 3. 监听dataChange这里面可能会有更新的deviceInfo

  // 页面加载完成只执行一次
  if (hasInit) return;
  hasInit = true;
  window.addEventListener('online', () => {
    console.log('online called');
    // location.reload();
  });
  window.addEventListener('offline', () => {
    console.log('offline called');
  });
  // 错误监控初始化
  customExceptionReport.jsErrorInit();
  customExceptionReport.promiseErrorInit();
  // 3. http请求头初始化 ok
  console.count('appSyncInit');
  const url = window.location.href;
  const urlParams = new URLSearchParams(url.split('?')[1]);
  // 获取名为 'channel_id' 的参数的值   channel_id为非区分门店主页场景
  const channel_id = getQueryParam('channel_id') || storage.getSessionStorage(SESSION_KEY.CHANNEL_ID);
  storage.setSessionStorage(SESSION_KEY.CHANNEL_ID, channel_id || '');
  // 获取名为 'source_tag' 的参数的值 跟踪投放渠道场景
  const source_tag = getQueryParam('source_tag') || storage.getSessionStorage(SESSION_KEY.SOURCE_TAG);
  storage.setSessionStorage(SESSION_KEY.SOURCE_TAG, source_tag || '');

  const user_equipmentObj = JSON.parse(storage.getSessionStorage(SESSION_KEY.user_equipment) || '{}');
  // 全搜团队跳转券详情会带用户的设备ID在URL上
  storage.setSessionStorage(
    SESSION_KEY.user_equipment,
    JSON.stringify({
      gaid: urlParams.get('gaid') || user_equipmentObj?.gaid,
      duid: urlParams.get('duid') || user_equipmentObj?.duid,
      ouid: urlParams.get('ouid') || user_equipmentObj?.ouid,
      guid: urlParams.get('guid') || user_equipmentObj?.guid
    })
  );
  // appDataChangeListener(); // 监听设备信息变更
  fixInneHeight();
  backListener(); // 监听返回
  return true;
}

// 初始化异步代码
async function asyncInit() {
  // 设备信息
  asyncInitDeviceId();
  // app信息
  asyncInitAppInfo();
  // 设备信息
  asyncInitDeviceInfo();
  // H5版本信息
  syncH5Vesion();
  // 将状态栏高度 底部安全高度获取设置到html变量
  asyncInitSafeHeight();
  // 初始化开发者选项,防止影响数据
  syncInitDevOpts();
}

// 初始化异步代码
let promiseResult: Promise<string | null> | null = null;
async function asyncInitDeviceId() {
  // 重复调用，返回历史结果
  if (promiseResult) {
    console.count('appAsyncInit 重复调用，返回历史结果');
    return promiseResult;
  }
  // 拉去风控资源从服务端获取deviceId
  console.count('appAsyncInit 初次调用');
  promiseResult = initDeviceId();
  return promiseResult;
}

// 拉取firebase远端配置 主要给TT告警过滤使用
function fetchFireBaseRemoteConfig() {
  try {
    const app = initializeApp(firebaseConfig);
    const remoteConfig = getRemoteConfig(app);
    // remoteConfig.settings.minimumFetchIntervalMillis = 2000; // 限制应用提取时间 默认是12小时 本地调试可以用小一点的值
    // 拉取remoteConfig
    fetchAndActivate(remoteConfig)
      .then(() => {
        const confitValue = getValue(remoteConfig, 'logPlatfromMonitorConfig');
        const configObj = JSON.parse(confitValue.asString());
        updateExcludeErrorCode(configObj.excludeErrorCode);
        updateExcludeStrsReportErrorConfig(configObj.excludeStrsReportErrorConfig);
      })
      .catch((err) => {
        monitorReport.info({ category: '获取firebase remote config失败', msg: { message: err?.message || '' } });
      });
  } catch (err) {
    monitorReport.info({ category: `Error initializing Firebase`, msg: { message: err?.message || '' } });
  }
}
function initAppListener(report: (eventId: string, properties: object, eventGroup: string) => void) {
  // 移除app的埋点监听
  // removeAllListeners();
  // 监听app的埋点上报
  if (isQponApp()) {
    try {
      addListener('data_report', (jsonString) => {
        console.log('CapacitorApp data_report', jsonString);
        let jsonObj = {};
        try {
          jsonObj = typeof jsonString === 'string' ? JSON.parse(jsonString) : jsonString;
        } catch (e) {
          console.error(e);
        }
        const { eventId, eventGroup, properties } = jsonObj || {};
        report(eventId, properties, eventGroup);
      });
    } catch (e) {
      console.log('initAppListener error:', e);
    }
  }
}
export { asyncInitDeviceId, syncInit, fetchFireBaseRemoteConfig, asyncInit, initAppListener };
