function copyText(text: string): boolean {
  // todo 没有return status
  let result;

  try {
    // 判断文档处于聚焦状态，同时navigator.clipboard存在，才能调用navigator.clipboard.writeText，否则会报错
    console.log('window.navigator.clipboard', window.navigator.clipboard);
    if (window.document?.hasFocus?.() && window.navigator.clipboard) {
      console.log('window.navigator.clipboard分支1');
      // clipboard api 复制
      navigator.clipboard
        .writeText(text)
        .then((res) => {
          console.log('clipboard-success', res);
        })
        .catch((error) => {
          console.log('clipboard-error', error);
          console.log('clipboard-error-code', error.code);
          console.log('clipboard-error-message', error.message);
          console.log('clipboard-error-name', error.name);
          copyFn(text);
        });
      result = true;
    } else {
      console.log('window.navigator.copy分支2', document.queryCommandSupported('copy'));
      copyFn(text);
      result = true;
    }
  } catch (e) {
    console.log('copy fail', e);
    result = false;
  }
  return result;
}

const copyFn = (text: string) => {
  const textarea = document.createElement('textarea');
  textarea.setAttribute('readonly', 'readonly'); // 设置只读属性防止手机上弹出软键盘
  document.body.appendChild(textarea);
  // 隐藏此输入框
  textarea.style.position = 'fixed';
  textarea.style.clip = 'rect(0 0 0 0)';
  textarea.style.top = '10px';
  // 赋值
  textarea.value = text;
  // 选中
  textarea.select();

  // 复制
  document.execCommand('copy');
  // 移除输入框
  document.body.removeChild(textarea);
};

export default copyText;
