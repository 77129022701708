import React, { useState } from 'react';
import styles from './index.module.scss';
import NavBar from '@/components/nav-bar';
import backIcon from '@/assets/login/back.svg';

interface DemoInterface {}

const ExtraIcon = () => (
  <>
    <NavBar.Action
      autoColor="fill"
      icon={
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.90029 11.3C4.90029 7.87583 7.67613 5.1 11.1003 5.1C14.5245 5.1 17.3003 7.87583 17.3003 11.3C17.3003 14.7242 14.5245 17.5 11.1003 17.5C7.67613 17.5 4.90029 14.7242 4.90029 11.3ZM11.1003 3.5C6.79247 3.5 3.30029 6.99218 3.30029 11.3C3.30029 15.6078 6.79247 19.1 11.1003 19.1C12.966 19.1 14.6787 18.445 16.021 17.3523L20.1595 21.4908L21.2909 20.3595L17.1524 16.221C18.2452 14.8786 18.9003 13.1658 18.9003 11.3C18.9003 6.99218 15.4081 3.5 11.1003 3.5Z"
            fill="black"
            fillOpacity="0.85"
          />
        </svg>
      }
    />
    <NavBar.Action
      autoColor="fill"
      icon={
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.6544 9.03891C17.6544 6.28362 15.5131 4.06533 12.7986 3.69867V2.5H11.1986V3.70026C8.48988 4.0717 6.35476 6.28742 6.3546 9.03861V9.03892L6.3498 13.9127H5.4347C4.43644 13.9127 3.6272 14.722 3.6272 15.7202V17.3599C3.6272 17.8072 3.98982 18.17 4.43727 18.17H19.5606C20.0081 18.17 20.3707 17.8072 20.3707 17.3599V15.7202C20.3707 14.722 19.5614 13.9127 18.5632 13.9127H17.6496L17.6544 9.0397V9.03891ZM12.0045 5.24543C9.72631 5.24543 7.9546 6.98422 7.9546 9.03892V9.03971L7.94901 14.7135C7.94858 15.155 7.59053 15.5127 7.14901 15.5127H5.4347C5.3201 15.5127 5.2272 15.6056 5.2272 15.7202V16.57H18.7707V15.7202C18.7707 15.6056 18.6778 15.5127 18.5632 15.5127H16.8488C16.6365 15.5127 16.4329 15.4283 16.2828 15.2781C16.1328 15.1279 16.0486 14.9242 16.0488 14.7119L16.0544 9.03891C16.0544 6.98422 14.2827 5.24543 12.0045 5.24543ZM11.995 21.5C10.9429 21.5 10.0899 20.6471 10.0899 19.5949H13.9001C13.9001 20.6471 13.0472 21.5 11.995 21.5Z"
            fill="black"
            fillOpacity="0.85"
          />
        </svg>
      }
    />
    <NavBar.Action autoColor="fill" icon={<img src={backIcon} />} />
  </>
);
const Demo: React.FC<DemoInterface> = () => {
  const [extraSlot] = useState<React.ReactNode | null>(<ExtraIcon />);
  const handleBack = () => history.back();
  return (
    <>
      <div>
        <div className={styles.customClass}>
          <NavBar
            onBack={handleBack}
            title="爆品专区"
            showBackButton={true}
            // backIcon={<img src={backIcon} />}
            transparent={true}
            align={'left'}
          >
            {extraSlot}
          </NavBar>
        </div>
        <div style={{ height: '1000px', backgroundColor: 'red' }}>内容</div>
      </div>
    </>
  );
};

export default Demo;
