const getUserAgent = () => {
  if (typeof window === 'object') {
    return window?.navigator?.userAgent;
  }
};

// 获取设备型号
const getDevicetype = function () {
  const ua = getUserAgent() || ''; // 浏览器ua

  if (/(?:iPhone)/i.test(ua)) {
    return 'iPhone';
  } else if (/(?:Android)/i.test(ua)) {
    return 'Android';
  } else if (/(?:iPad|PlayBook)/i.test(ua)) {
    return 'iPad';
  } else if (/(?:Windows Phone)/i.test(ua)) {
    return 'WindowsPhone';
  } else {
    return 'other';
  }
};

// 获取浏览器型号
const getBrowserType = function () {
  const ua = getUserAgent() || ''; // 浏览器ua

  if (/(?:Firefox)/i.test(ua)) {
    return 'Firefox';
  } else if (/(?:Chrome)/i.test(ua)) {
    return 'Chrome';
  } else if (/(?:Safari)/i.test(ua)) {
    return 'Safari';
  } else if (/(?:Opera)/i.test(ua)) {
    return 'Opera';
  } else if (/(?:Edge)/i.test(ua)) {
    return 'Edge';
  } else if (/(?:Trident)/i.test(ua)) {
    return 'Trident';
  } else if (/(?:HeyTapBrowser)/i.test(ua)) {
    return 'OPPO';
  } else {
    return 'Unknown';
  }
};

// 判断是否是oppo浏览器
const isOppoBrowser = function () {
  // ua 包含 HeyTapBrowser 字符串 就是OPPO浏览器
  const userAgent = navigator.userAgent.toLowerCase();
  const isHeyTapBrowser = userAgent.includes('heytapbrowser');
  return isHeyTapBrowser;
};

//系统信息配置
const getSystemConfig = () => {
  const userAgent = navigator.userAgent;
  // 使用正则表达式匹配 Android
  const androidRegex = /Android/i;
  if (androidRegex.test(userAgent)) {
    return {
      name: 'Android',
      version: userAgent.match(/Android (\d[\d\\.]*)/)?.[1] || '',
      device: userAgent.match(/Android [\d\\.]+;( [\w\\-]*;)? ([A-Z\d\\-]+)/i)?.[2] || '' // Mozilla/5.0 (Linux; Android 8.1.0; PBAM00 Build/OPM1.171019.026; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/76.0.3809.89 Mobile Safari/537.36 T7/11.20 SP-engine/2.16.0 baiduboxapp/11.20.0.14 (Baidu; P1 8.1.0) NABar/2.0
    };
  }

  // 使用正则表达式匹配 iOS
  const iOSRegex = /(iPhone|iPod|iPad)/i;
  if (iOSRegex.test(userAgent)) {
    return {
      name: 'IOS',
      version: userAgent.match(/OS (\d+_\d+(_\d+)?)/)?.[1]?.replace(/\\_/g, '.') || ''
    };
  }

  // 使用正则表达式匹配 macOS
  const macOSRegex = /(Mac OS)/i;
  if (macOSRegex.test(userAgent)) {
    return {
      name: 'macOS',
      version: userAgent.match(/OS X (\d+_\d+(_\d+)?)/)?.[1]?.replace(/\\_/g, '.') || ''
    };
  }

  return {
    name: '',
    version: ''
  };
};

export { getDevicetype, getBrowserType, isOppoBrowser, getSystemConfig };
