import { getCookie } from '@/utils/cookie';

const envMap = {
  prod: 'prod',
  pre: 'prod',
  test3: 'pre',
  test2: 'pre',
  test: 'pre',
  local: 'pre'
};
const reginMap = {
  prod: 'sg',
  pre: 'sg',
  test3: 'cn',
  test2: 'cn',
  test: 'cn',
  local: 'cn'
};

const getLanguageStr = () => {
  const language = getCookie('language') || window.navigator?.language || '';
  let languageStr = '中文';
  if (/ID/i.test(language)) languageStr = '印尼语';
  if (/US/i.test(language)) languageStr = '英语';
  if (/GB/i.test(language)) languageStr = '英语';
  return languageStr;
};

export { envMap, reginMap, getLanguageStr };
