import { LOCAL_KEY, storage } from '../tools/storage';
// import login from '@/components/login/login';

// 设置登录态信息
export const setTokenInfo = function (tokenInfo: { accessToken: string; refreshToken: string; userId: string }) {
  storage.setLocalStorage(LOCAL_KEY.TOKEN_INFO, tokenInfo, true);
};

// 获取登录态信息
export const getTokenInfo = function () {
  return storage.getLocalStorage(LOCAL_KEY.TOKEN_INFO, true);
};

// 清除登录态并跳转至登录页
export const goLogin = async function (sceneType: string = '异常状态：登录态过期') {
  clearTokenInfo();
  const login = (await import('@/components/login/login')).default;
  await login({ sceneType });
};

// 清除登录态
export const clearTokenInfo = function () {
  storage.removeLocalStorage(LOCAL_KEY.TOKEN_INFO);
};
