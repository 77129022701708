import { CommonResponseData, LanguageMap } from '@/types/common';
import request from '@/utils/ajax';
// import request from '@/utils/ajax';
import { PromotionInfo } from '@/api/order/settlementApi';

interface CouponReq {
  storeId: string;
}

interface Coupon {
  id: string;
  templateId?: number;
  couponName?: LanguageMap;
  couponType?: string;
  couponTypeName?: string;
  couponAmount?: number;
  couponDescription?: string;
  useConditionDescription?: string;
  useScopeDescription?: string;
  useRuleDescription?: string;
  status?: string;
  isReceived?: boolean;
  effectiveTimeType?: string;
  effectiveDays?: number;
  effectiveStartTime?: string;
  effectiveEndTime?: string;
  receivedTime?: string;
  useOrderAmountThreshold?: number;
  storeList?: string;
  scopeOrderFoodTypes?: number[];
  templateEffectiveBeginTime: string;
  templateEffectiveEndTime: string;
  logoUrl: string;
}

export interface CouponBatch {
  batchId?: number;
  batchName?: string;
  coupon?: Coupon;
  receiveCode?: string;
  startTime?: string;
  endTime?: string;
  receiveTimesDailyLimit: number;
  receiveTimesLimit: number;
}
export interface ProductItem {
  productId: string;
  purchaseCode: number;
  reachePurchaseLimit: boolean;
  dailyStockValid: boolean;
  discountRatio: number;
  salePrice: number;
  originalPrice: number;
  orderUseLimitNum: number;
  soldProcess: number;
  redeemTimeType: string;
  redeemTime: LanguageMap;
  productName: LanguageMap;
  logoUrl: string;
  skuId: string;
  receiveTimesLimit: number;
  receiveTimesDailyLimit: number;
  productType: string;
  remainingStock: number;
  promotionInfo?: PromotionInfo; // 促销信息
  isFlashSale: boolean;
  detailId: string | null;
  productType: 'EXCHANGE_COUPON' | 'CASH_COUPON'; //商品类型(兑换券: EXCHANGE_COUPON、代金券: CASH_COUPON)
  isSoldOut: boolean; //是否售罄
}

export interface HomepageCoupon {
  posId: string;
  couponBatchList?: CouponBatch[];
  activityId: number;
  productList?: ProductItem[];
}

export type NoticeContent = LanguageMap;

export interface Content {
  contentId?: string;
  contentType?: string;
  iconUrl?: string;
  noticeContent?: NoticeContent;
}

export interface HomepageCouponNotice {
  posId?: string;
  contentSource?: string;
  content?: Content;
}

export interface HomeCoupons {
  homepageCouponList?: HomepageCoupon;
  homepageCouponNotice?: HomepageCouponNotice;
}

export type CouponRes = CommonResponseData<HomeCoupons>;
/**
 * 查询商家首页优惠券展示资源位
 */

export const queryHomepageCopon = async (data: CouponReq): Promise<CouponRes> => {
  return await request<CouponReq, CouponRes>('/resource_pos/merchant_homepage/coupon', data);
};

// /**
//  * 查询商家首页优惠券展示资源位
//  *
//  */

// export const queryHomepageCopon = async (data: CouponReq): Promise<CouponRes> => {
//   return await request<CouponReq, CouponRes>('/resource_pos/merchant_homepage/coupon', data);
// };
