import axios from 'axios';
import { bspwwasSdk } from '@qpon-design/bspwwas';
import { includeBspCheckList } from '../config/config';
import { monitorReport } from '@/utils/monitor/monitorReport';

// 添加请求拦截器
axios.interceptors.request.use(
  async (config) => {
    const headers = config.headers;
    if (includeBspCheckList.includes(config.url!)) {
      // 需要风控签名的接口 todo 需要 风控sdk暴露出来给我使用。
      monitorReport.info({
        category: '参与风控签名的入参',
        msg: { method: config.method, body: config.data, url: `${config.baseURL}${config.url}`, headers: config.headers }
      });
      const signHeaders = (await bspwwasSdk.randomBspWas(config.method!, config.data, `${config.baseURL}${config.url}`, config.headers)) || {};
      if (signHeaders['x-euler-const'] || signHeaders['x-euler-headers']) {
        headers['X-euler-const'] = signHeaders['x-euler-const'];
        headers['X-euler-headers'] = signHeaders['x-euler-headers'];
      }
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
