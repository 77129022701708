import React, { CSSProperties, ReactElement } from 'react';
import cls from 'classnames';

export interface NativeProps<S extends string = never> {
  className?: string;
  style?: CSSProperties & Partial<Record<S, string | number>>;
}

export function withNativeProps<P extends NativeProps>(props: P, element: ReactElement) {
  const p = { ...element.props };

  if (props.className) p.className = cls(element.props.className, props.className);
  if (props.style) p.style = { ...p.style, ...props.style };

  return React.cloneElement(element, p);
}
