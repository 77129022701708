import React, { useEffect, useRef, useState } from 'react';
import { useMount } from 'ahooks';
import classnames from 'classnames';
import styles from './index.module.scss';
import { getSafeArea } from '@/utils/nativeSdk';

export interface tabData {
  activeIcon: string;
  inActiveIcon: string;
  key: string;
  title: string; // tab标题
  checkActive?: (activeTab: string, key: string) => boolean; // 传入检查是否激活的方法，优先级最高
}
export type TabsData = tabData[];
export type BottomTabsProps = {
  tabsData: tabData[];
  activeKey?: string; // 当前激活的tab key
  defaultActiveKey?: string; // 初始化选中 item 的 key，如果没有设置 activeKey
  onChange: (key: string) => void; // 切换面板的回调
  safeArea: boolean; //是否开启安全区适配	默认false
};
const BottomTabs: React.FC<BottomTabsProps> = (props) => {
  const safeAreaDiv = useRef<HTMLDivElement>(null);
  const { tabsData, activeKey, defaultActiveKey, onChange, safeArea = false } = props;
  const [activeTab, setActiveTab] = useState<string>(activeKey || defaultActiveKey || '');

  function clickTab(key: string) {
    setActiveTab(key);
    onChange(key);
  }
  useMount(async () => {
    // 设置底部安全高度
    const safeAreaInfo = await getSafeArea();
    if (safeAreaInfo.code === 0 && safeAreaInfo?.data?.bottom && safeAreaDiv?.current) {
      safeAreaDiv.current.style.height = `${safeAreaInfo?.data?.bottom}px`;
    }
  });
  useEffect(() => {
    if (activeKey) setActiveTab(activeKey!);
  }, [activeKey]);

  if (!tabsData?.length) return null;
  return (
    <div className={styles.container}>
      <div className={styles.bottomTabs}>
        {tabsData.map((tab) => {
          const { inActiveIcon, activeIcon, key, title, checkActive } = tab;
          const active = checkActive ? checkActive(activeTab, key) : activeTab === key;
          return (
            <div
              className={styles.tabBar}
              key={key}
              onClick={() => {
                clickTab(key);
              }}
            >
              <div className={styles.icon}>
                <img src={active ? activeIcon : inActiveIcon} alt="icon" />
              </div>
              <div className={classnames(styles.title, active ? styles.active : styles.inActive)}>{title}</div>
            </div>
          );
        })}
      </div>
      {!!safeArea && <div className={styles.safeArea} ref={safeAreaDiv}></div>}
    </div>
  );
};

export default BottomTabs;
