import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import getLocation, { GetPositionParams, PositionResult } from '@/utils/tools/getPosition';
import { RootState } from '@/store';
import { updateXDigitalFoodCoorsHeader } from '@/utils/ajax/config/configAction';

export type PositionReduxInterface = PositionResult & { loading: boolean; address: string };
const initialState: PositionReduxInterface = {
  success: false,
  errorMessage: '',
  code: 0,
  latitude: '',
  longitude: '',
  loading: false,
  address: '' // 解析过的地址
};

type PositionAsyncThunkParams = GetPositionParams & {
  update?: boolean;
};
export const getPositionAsync = createAsyncThunk('position/getPositionAsync', async (params: PositionAsyncThunkParams = {}, { dispatch }) => {
  const { update = true } = params;
  delete params.update;
  const time1 = new Date().getTime();
  console.log('locationAction', 'getPositionAsync拉定位');
  const positionInfo = await getLocation(params);
  const time2 = new Date().getTime();
  const timeCost = time2 - time1;
  console.log('locationAction getPositionAsync H5转换后result', positionInfo, '定位耗时', timeCost);
  if (update) {
    // 每次拉定位清空解析地址
    dispatch(updateAddress(null));
    dispatch(updatePosition(positionInfo));
  }

  return positionInfo;
});

export const positionState = createSlice({
  name: 'position',
  initialState,
  reducers: {
    updatePosition: (state, action) => {
      const positionData = action.payload || {};
      const { latitude = '', longitude = '', success = false, errorMessage, code } = positionData;
      // 优化 如果定位数据没有更新，我们不做update
      const originData = {
        latitude: state.latitude,
        longitude: state.longitude,
        success: state.success,
        code: state.code
      };
      const newData = {
        latitude,
        longitude,
        success,
        code
      };
      if (JSON.stringify(originData) === JSON.stringify(newData)) return;
      state.latitude = latitude;
      state.longitude = longitude;
      state.success = success;
      state.errorMessage = errorMessage;
      state.code = code;
      // 这里是为了处理请求头 统一处理
      if (latitude && longitude) updateXDigitalFoodCoorsHeader(latitude, longitude);
    },
    updateAddress: (state, action) => {
      state.address = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getPositionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPositionAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getPositionAsync.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const { updatePosition, updateAddress } = positionState.actions;
export const getPositionInfo = (state: RootState): PositionReduxInterface => state.position;

export default positionState.reducer;
