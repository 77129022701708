import request from '@/utils/ajax';
import { getBrowserType } from '@/utils/tools';

const browserType = getBrowserType();

interface ResponseError {
  code: string;
  message: string;
}
interface succesData {
  processToken: string;
  codeLength: number;
  isNeed: boolean;
  accessToken: string;
  refreshToken: string;
  userType: string;
  userId: string;
  isFirstLoginAfterMerging: boolean;
}

export interface ResponseData {
  success: boolean;
  data: succesData;
  error: ResponseError;
}
interface RequestData {}

const commonData = {
  browserId: browserType
};

/**
 * 查询demo示例
 * doc: yapi文档外链贴这
 */
export const checkSlider = (data: RequestData) => {
  return request<RequestData, ResponseData>(`/account/checkSlider`, {
    ...commonData,
    ...data
  });
};

export const sendCode = (data: RequestData) => {
  return request<RequestData, ResponseData>(`/account/sendCode`, {
    ...commonData,
    ...data
  });
};

export const login = (data: RequestData) => {
  return request<RequestData, ResponseData>(`/account/login`, {
    ...commonData,
    ...data
  });
};

export const logout = () => {
  return request<RequestData, ResponseData>(`/account/logout`, {
    ...commonData
  });
};
