import { memo, useMemo } from 'react';
import { Toast } from 'antd-mobile';
import styles from './index.module.scss';
import { HomepageStoreInfo, StoreChannel } from '@/api/merchant/queryHomepageStoreInfo';
import { t } from '@/utils/lang';
import delivery from '@/assets/merchant/delivery.svg';
import pickup from '@/assets/merchant/pickup.svg';
import dine from '@/assets/merchant/dine.svg';
import message from '@/assets/merchant/message.png';
import { addExtraQuery } from '@/utils/tools/addExtraQuery';

const orderFoodTypesIconMap = {
  1: delivery,
  2: pickup,
  3: dine
};

interface CoponMspProps {
  notice: string;
}
const CoponMsp = (props: CoponMspProps) => {
  const { notice } = props;
  // useEffect(() => {
  //   if (notice) report.coupon_notice_bar_view();
  // }, [notice]);
  return !notice ? (
    <div className={styles.blank}></div>
  ) : (
    <div className={styles.couponsMsg}>
      <img src={message} alt="" className={styles.icon} />
      <span className={styles.message}>{notice}</span>
    </div>
  );
};

const ActiveItem = (props: { service: StoreChannel; isLast: boolean; onClickAction: () => void }) => {
  const { service, isLast, onClickAction } = props;
  const { orderFoodType } = service;
  const orderFoodTypeNameMap = {
    1: t('merchant.delivery'),
    2: t('merchant.pickUp'),
    3: t('merchant.dineIn')
  };
  return (
    <>
      <div className={styles.item} key={orderFoodType} onClick={onClickAction}>
        <img src={orderFoodTypesIconMap[orderFoodType as '1' | '2' | '3']} alt="" className={styles.img} />
        <span>{orderFoodTypeNameMap[orderFoodType as '1' | '2' | '3']}</span>
      </div>
      {!isLast && <div className={styles.verticalLine}></div>}
    </>
  );
};

interface Props {
  storeInfo: HomepageStoreInfo;
  notice: string;
  language: string;
  scanAction?: (visible: boolean) => void;
}

const ActiveService = (props: Props) => {
  const orderFoodTypeNameMap = {
    1: t('merchant.delivery'),
    2: t('merchant.pickUp'),
    3: t('merchant.dineIn')
  };
  const { storeInfo = {}, notice, scanAction } = props;
  const { storeChannelList } = storeInfo as HomepageStoreInfo;

  const validOrderFoodTypeChannels = useMemo(() => {
    if (!storeChannelList?.length) return [];
    const srotList = [3, 1, 2]; // 展示顺序 堂食 外卖 自提
    const sortStoreChannelList: StoreChannel[] = [];
    srotList.forEach((type) => {
      const channelService = storeChannelList?.filter((item) => +item.orderFoodType === type);
      if (channelService.length) sortStoreChannelList.push(channelService[0] as StoreChannel);
    });
    // report.channel_type_module_view(sortStoreChannelList?.map((item) => item.orderFoodType));
    return sortStoreChannelList || [];
  }, [storeChannelList]);

  const clickAction = (service: StoreChannel) => {
    // report.channel_type_module_btn_click(service.orderFoodType);
    if (+service.orderFoodType === 3) return scanAction?.(true); // 外卖 扫码
    // 外卖自提 共用一个码
    const qrCodeId = validOrderFoodTypeChannels?.filter((channel) => [1, 2].includes(+channel.orderFoodType) && channel.qrCodeId)[0]?.qrCodeId;
    if (!qrCodeId) {
      const message = service.orderFoodType === '1' ? t('merchant.deliveryNotAvaliable') : t('merchant.pickUpNotAvaliable');
      // const reportMessage = service.orderFoodType === '1' ? '商家未配置外卖菜单' : '商家未配置自提菜单';
      // report.channel_type_module_btn_toast_view({ toast_text: reportMessage, channel_module: service.orderFoodType });
      return Toast.show({ content: message });
    }
    // MARK 在APP中拼接额外的参数，跳转扫码下单项目
    const origin = import.meta.env.VITE_FRONT_ENV_DOMAIN || 'https://qpon-h5-id.qponmobile.com';
    const originHref = `${origin}/menuQR?qrCodeId=${qrCodeId}&orderFoodType=${service.orderFoodType}`;
    const finalUrl = addExtraQuery(originHref);
    // 采用APP内置的跳转方法
    //toOpenWebView(finalUrl, { showToolbar: false, showURL: false, showNavigationButtons: false }, 'href');
    window.location.href = finalUrl;
  };
  if (!validOrderFoodTypeChannels?.length) return null;
  if (validOrderFoodTypeChannels.length === 1) {
    const onlyService = validOrderFoodTypeChannels[0];
    // 只有一个的展示
    const orderFoodType = validOrderFoodTypeChannels[0]!.orderFoodType as '1' | '2' | '3';
    const icon = orderFoodTypesIconMap[orderFoodType];
    const orderFoodTypeStr = orderFoodTypeNameMap[orderFoodType];
    return (
      <div className={styles.onlyDine}>
        <div className={styles.card} onClick={() => clickAction(onlyService as StoreChannel)}>
          <div className={styles.dineZone}>
            <span className={styles.dineText}>{orderFoodTypeStr}</span>
            <span className={styles.clickArea}>{t('merchant.clickOrder')}</span>
          </div>
          <div className={styles.rightImg}>
            <img src={icon} alt="dine" className={styles.icon} />
          </div>
        </div>
        <CoponMsp notice={notice}></CoponMsp>
      </div>
    );
  }
  return (
    <>
      <div className={styles.serviceCard}>
        <div className={styles.serviceTyp}>
          {validOrderFoodTypeChannels.map((item, index) => {
            return (
              <ActiveItem
                service={item}
                isLast={index === validOrderFoodTypeChannels.length - 1}
                key={item.orderFoodType}
                onClickAction={() => clickAction(item)}
              ></ActiveItem>
            );
          })}
        </div>
        <CoponMsp notice={notice}></CoponMsp>
      </div>
    </>
  );
};

export default memo(ActiveService);
