// import { AppInfo } from '@capacitor-qpon/app-info';
import { Toast } from 'antd-mobile';
import { isOpenFromPWA } from '../pwa';
import { updateXAppHeader, updateXDigitalFoodHeader, updateXModelHeader, updateXSysHeader } from '../ajax/config/configAction';
import { XAPP, XDigitalFood, XSys } from '../ajax/interface';
import {
  getAppInfo,
  getDeviceInfoAsync,
  getSafeArea,
  getStatusBarHeight,
  notifyAppReady,
  exitActivity,
  backButtonAddListener,
  isQponApp
} from '../nativeSdk';
import { getH5Version, initH5Version } from '../tools/getAppH5Info';
import { getBrowserInfo } from '../tools/browserJsBridge';
import { t } from '@/utils/lang';
import { LOCAL_KEY, SESSION_KEY, storage } from '@/utils/tools';

// 监听deviceInfo变化
// const appDataChangeListener = () => {
//   AppInfo?.addListener('DataChangedModel', (data: string) => {
//     console.log('DataChangedModel', data);
//     try {
//       const parseData = JSON.parse(data);
//       updateXDigitalFoodHeader(parseData as AppData);
//     } catch (e) {
//       // todo
//     }
//   });
// };
// 获取应用信息并设置头
async function asyncInitAppInfo() {
  const appInfo = await getAppInfo();
  console.log('appInfo', appInfo);
  let info: XAPP;
  if (appInfo.code === 0) {
    const { id, name, versionName, versionCode } = appInfo.data!;
    info = {
      appPackage: id,
      appVersion: versionName,
      appVersionCode: versionCode,
      hostPackage: name,
      hostType: 'APP'
    };
    storage.setLocalStorage(LOCAL_KEY.CLIENT_INFO, appInfo.data, true);
  } else {
    const hostType = isOpenFromPWA() ? 'PWA' : 'WEB';
    info = {
      hostType: hostType
    };
  }
  updateXAppHeader(info);
}

//  异步拉设备信息设置header
async function asyncInitDeviceInfo() {
  const deviceInfo = await getDeviceInfoAsync();
  const oppoBrowserInfo = getBrowserInfo(); //oppo浏览器信息
  const user_equipmentObj = JSON.parse(storage.getSessionStorage(SESSION_KEY.user_equipment) || '{}');
  // 客户端
  if (deviceInfo.code === 0) {
    const {
      brand,
      model,
      screenHeight,
      screenWidth,
      androidVersion,
      iosVersion,
      osType = '0',
      dfp,
      apid,
      duid,
      guid,
      ouid,
      customerId,
      adId
    } = deviceInfo.data!;
    const info: XSys = {
      model,
      ht: screenHeight,
      wd: screenWidth,
      androidVersionCode: androidVersion,
      iosVersionCode: iosVersion || '',
      osType,
      brand
    };
    const dfpObj = { dfp, adId: adId || customerId, apid, duid, guid, ouid };
    storage.setLocalStorage(LOCAL_KEY.CLIENT_DEVICE_INFO, dfpObj, true);
    updateXSysHeader(info);
    updateXModelHeader(model);
    updateXDigitalFoodHeader(dfpObj);
  } else if (oppoBrowserInfo) {
    // oppo浏览器
    const { duid, ouid, gaid } = oppoBrowserInfo || {};
    const dfpObj = { adId: gaid || user_equipmentObj?.gaid, duid: duid || user_equipmentObj?.duid, ouid: ouid || user_equipmentObj?.ouid };
    updateXDigitalFoodHeader(dfpObj);
  }
}

// 异步拉取H5信息
function syncH5Vesion() {
  initH5Version();
  const h5Info = getH5Version();
  console.log('h5Info', h5Info);
  const { h5_version_code, h5_version_name } = h5Info || {};
  if (h5_version_code || h5_version_name) {
    const info: XDigitalFood = {
      h5Version: h5_version_name?.toString() || '',
      h5VersionCode: h5_version_code?.toString() || ''
    };
    updateXDigitalFoodHeader(info);
  }
}

function backListener() {
  // 监听返回
  let isExit = false; // 是否可退出应用
  backButtonAddListener((e) => {
    if (e.canGoBack && !['/', '/platform/home'].includes(window.location.pathname)) {
      if (window.location.pathname === '/platform/pay') {
        /* empty */
      } else {
        history.go(-1);
      }
    } else {
      if (isExit) {
        isExit = false;
        exitActivity();
      } else {
        isExit = true;
        Toast.show(t('system.exitApp'));
      }
    }
  });
  // AppInfo?.addListener('backButton', (e) => {
  //   if (e.canGoBack && !['/', '/platform/home'].includes(window.location.pathname)) {
  //     if (window.location.pathname === '/platform/pay') {
  //       /* empty */
  //     } else {
  //       history.go(-1);
  //     }
  //   } else {
  //     if (isExit) {
  //       isExit = false;
  //       exitActivity();
  //     } else {
  //       isExit = true;
  //       Toast.show(t('system.exitApp'));
  //     }
  //   }
  // });
}

function appReady() {
  // 通知app初始化完成  app会关闭闪屏
  // window.addEventListener('load', () => {
  notifyAppReady();
  // });
}

function asyncInitSafeHeight() {
  const root = document.documentElement;
  getSafeArea().then((safeAreaInfo) => {
    const height = safeAreaInfo?.data?.bottom || 0;
    root.style.setProperty('--safe-area-bottom-height', `${height}px`);
  });
  getStatusBarHeight().then((statusBarArea) => {
    const height = statusBarArea?.data?.height || 0;
    root.style.setProperty('--status-bar-height', `${height}px`);
  });
}

function setVh() {
  // Calculate 1vh in pixels
  const vh = window.innerHeight * 0.01;
  // Set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
// 解决100vh的问题
function fixInneHeight() {
  if (!isQponApp() && !CSS.supports('height', '100dvh')) {
    window.removeEventListener('resize', setVh);
    // Set the initial value
    setVh();
    // Recalculate on window resize
    window.addEventListener('resize', setVh);
  }
}
// 初始化开发者选项
function syncInitDevOpts() {
  try {
    sessionStorage.removeItem('simulateCoords');
  } catch (e) {
    console.log('syncInitDevOpts error:', e);
  }
}
export { asyncInitAppInfo, asyncInitDeviceInfo, syncH5Vesion, backListener, appReady, asyncInitSafeHeight, fixInneHeight, syncInitDevOpts };
