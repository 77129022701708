import { useCountDown } from 'ahooks';
import styles from './index.module.scss';

interface Props {
  diff: number;
  timeOutEvent?: () => void; //倒计时结束回调
}

const Countdown = (prop: Props) => {
  const { diff, timeOutEvent } = prop;
  const [, formattedRes] = useCountDown({
    leftTime: diff,
    onEnd: () => {
      // 倒计时结束，回调
      timeOutEvent && timeOutEvent();
    }
  });
  const formattedTime = (time: number) => {
    if (time < 10) return '0' + time;
    return time;
  };
  return (
    <div className={styles.container}>
      <span className={styles.timeBox}>{formattedTime(formattedRes?.days * 24 + formattedRes?.hours)}</span>
      <span className={styles.timeBox}>{formattedTime(formattedRes?.minutes)}</span>
      <span className={styles.timeBox}>{formattedTime(formattedRes?.seconds)}</span>
    </div>
  );
};

export default Countdown;
