import { ResponseError } from '@/types/common';
import request from '@/utils/ajax';
// import request from '@/utils/ajax';

interface QueryHomepageStoreInfoReq {
  storeId?: string;
  cpType?: string;
  sourceType?: 'CUSTOMER';
  qrCodeId?: string;
  lng?: string | number | undefined;
  lat?: string | number | undefined;
}

interface DayPartTimeList {
  channelCode: string;
  dayPartCode: string;
  startTime: string;
  endTime: string;
  isBusinessHours: boolean;
}

interface StoreImageList {
  id?: string;
  storeId?: string;
  imageUrl?: string;
  imageType?: string;
  sort?: null;
}

export interface StoreSocialMedia {
  id: string;
  storeId: string;
  mediaName: 'WHATS_APP' | 'FACEBOOK' | 'INSTAGRAM' | 'YOUTUBE' | 'TIKTOK' | 'WECHAT' | 'EMAIL'; // WHATS_APP、FACEBOOK、INSTAGRAM、TIKTOK、WECHAT、EMAIL
  mediaInfo: string;
}

export interface StoreChannel {
  orderFoodType: string;
  qrCodeId: string;
  qrCodeUrl: string;
}

export interface mallObject {
  mallName: string;
  mallId: number;
  mallAddress: string;
  floorList: { floorId: number | string; floorName: string }[] | null;
  lng?: string;
  lat?: string;
  locationRemark?: { 'en-US'?: string; 'id-ID'?: string; 'zh-CN'?: string };
}

export interface HomepageStoreInfo {
  storeId: string;
  storeName: string;
  halalRestaurant: string;
  storeMobile?: string;
  storeAddress?: string;
  mapLinkAddress?: string;
  lat?: string;
  lng?: string;
  isBusinessHours?: boolean;
  dayPartTimeList?: DayPartTimeList[];
  storeImageList?: StoreImageList[];
  storeSocialMediaList?: StoreSocialMedia[];
  storeChannelList: StoreChannel[];
  merchantBackgroundImage?: string;
  storeDistance?: number | string;
  mallInfo?: mallObject;
}

export interface QueryHomepageStoreInfoRes {
  success: boolean;
  error: ResponseError;
  data: HomepageStoreInfo;
}

export const queryHomepageStoreInfo = async (data: QueryHomepageStoreInfoReq): Promise<QueryHomepageStoreInfoRes> => {
  return await request<QueryHomepageStoreInfoReq, QueryHomepageStoreInfoRes>('/homepage/query_homepage_store_info', data);
};

// /**
//  * 查询商家首页门店信息
//  *
//  */
// export const queryHomepageStoreInfo = async (data: QueryHomepageStoreInfoReq): Promise<QueryHomepageStoreInfoRes> => {
//   return await request<QueryHomepageStoreInfoReq, QueryHomepageStoreInfoRes>('/homepage/query_homepage_store_info', data);
// };
