// import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/index.ts';
import App from './App.tsx';
import useSessionId from './hooks/useSession';
import { initPWA } from './utils/pwa/index.ts';
import websee from './utils/websee.ts';
import loadPolyfills from '@/utils/polyfills.ts';
// websee接入
websee.install();
loadPolyfills(); // 一些pollyfill
initPWA();
function Main() {
  useSessionId(); // 初始化sessionId
  return (
    <Provider store={store}>
      <App />
      {/* 配置测试环境请求baseUrl的悬浮窗 */}
    </Provider>
  );
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <Main />
  // </React.StrictMode>
);
