import { useMemo, useRef, useState } from 'react';
import { useMemoizedFn, useMount } from 'ahooks';
import { Skeleton, Image } from 'antd-mobile';
import ScanPopUp, { ScanPopUpHandler } from '../couponDetail/components/ScanPopUp';
import CardInfo from './componets/CardInfo';
import RecommendFoods from './componets/RecommendFoods';
import ActiveService from './componets/ActiveService';
import CouponList from './componets/CouponList';
import NavBar from './componets/NavBar';
import styles from './index.module.scss';
import Expired from './componets/Expired';
import { CouponRes, HomepageCoupon, queryHomepageCopon } from '@/api/merchant/queryCopon';
import { HomepageStoreInfo, QueryHomepageStoreInfoRes, queryHomepageStoreInfo } from '@/api/merchant/queryHomepageStoreInfo';
import { SESSION_KEY, storage } from '@/utils/tools';
import useQueryParams from '@/hooks/useQueryParams';
import { getLanguage } from '@/utils/lang';
import { LanguageMap } from '@/types/common';
import defaultBg from '@/assets/merchant/defaultBg.webp';
import useReport from '@/hooks/useReport';
import { asyncInitDeviceId } from '@/utils/appMount';
import { compressImg } from '@/utils/tools/compressImg';
import MerchantNavBar from '@/components/nav-bar';
import usePosition from '@/hooks/usePosition';

interface QueryParams {
  storeId: string;
  cpType: string;
  channel_id: string;
  pageLoadTime: number;
  qrCodeId: string;
}

export default function Index() {
  const { storeId, cpType = 'rydeen', channel_id, qrCodeId } = useQueryParams<QueryParams>(); // cpType默认值暂时设置
  const { positionInfo } = usePosition(true);
  const { latitude, longitude } = positionInfo;
  const language = getLanguage();
  const { report } = useReport();
  const ScanPopUpRef = useRef<ScanPopUpHandler>(null); // 扫码窗口

  const [merchantStoreInfoRes, setStoreInfo] = useState<QueryHomepageStoreInfoRes>();
  const [couponInfo, setCouponInfo] = useState<CouponRes>({});

  // 请求完成coupon接口后上报性能
  function queryCouponSuccess(resp: CouponRes) {
    if (!resp?.success) return;
  }

  // 券列表信息
  const queryCoupons = useMemoizedFn(async (id: string) => {
    const res = await queryHomepageCopon({ storeId: id });
    setCouponInfo(res);
    queryCouponSuccess(res);
    asyncInitDeviceId();
  });

  const updateCoupons = useMemoizedFn(async (id: string) => {
    const res = await queryHomepageCopon({ storeId: id });
    setCouponInfo(res);
  });

  // 门店信息
  const queryStoreInfo = useMemoizedFn(async () => {
    const res = await queryHomepageStoreInfo({ qrCodeId, storeId, cpType, sourceType: 'CUSTOMER', lng: longitude, lat: latitude });
    if (res?.data?.storeDistance) {
      res.data.storeDistance = parseFloat(res.data.storeDistance + '').toFixed(1);
    }
    setStoreInfo(res);
    const queryStoreId = res?.data?.storeId;

    // url没有storeId 此时通过qrCodeId查询的storeId需要拿来查询
    if (!storeId && queryStoreId) {
      queryCoupons(queryStoreId);
      report('page', { type: 'view', storeId: queryStoreId });
    }
  });

  useMount(async () => {
    // 设置 storeId 和 channel_id
    storage.setSessionStorage(SESSION_KEY.STORE_ID, storeId);
    queryStoreInfo();
    // 线下铺码可能是只有qrCode没有storeId，此时接口不调用，需要等stroreInfo接口完成后调用
    if (storeId) {
      queryCoupons(storeId);
      report('page', { type: 'view', storeId: storeId });
    }
    window.scrollTo({ top: 0 });
  });

  const [availableOrderFoodType, imageUrl, merchantStoreInfo] = useMemo(() => {
    const { data } = merchantStoreInfoRes || {};
    const { storeChannelList, storeImageList, merchantBackgroundImage } = data || {};
    const merchantStoreInfo = data || {};
    const dineInOrderFoodType = storeChannelList?.find((storeChannel) => +storeChannel.orderFoodType === 3);
    const deliveryOrderFoodType = storeChannelList?.find((storeChannel) => +storeChannel.orderFoodType === 1);
    const pickUpOrderFoodType = storeChannelList?.find((storeChannel) => +storeChannel.orderFoodType === 2);
    const availableOrderFoodType =
      dineInOrderFoodType?.orderFoodType ?? deliveryOrderFoodType?.orderFoodType ?? pickUpOrderFoodType?.orderFoodType ?? undefined;
    const storeBackgroundImage = storeImageList?.find((item) => item?.imageType === 'BACKGROUND')?.imageUrl || '';
    const imageUrl = storeBackgroundImage || merchantBackgroundImage || '';
    return [availableOrderFoodType, imageUrl, merchantStoreInfo];
  }, [merchantStoreInfoRes]);

  const [couponBatchList, productList, notice, homepageCouponInfo] = useMemo(() => {
    const noticeMap = couponInfo.data?.homepageCouponNotice?.content?.noticeContent ?? ({} as LanguageMap);
    const homepageCouponObj = couponInfo.data?.homepageCouponList;
    const couponBatchList = homepageCouponObj?.couponBatchList ?? [];
    const homepageCouponInfo = couponInfo.data?.homepageCouponList ?? {};
    const productList = homepageCouponObj?.productList ?? [];
    const notice = noticeMap[language];
    return [couponBatchList, productList, notice, homepageCouponInfo];
  }, [couponInfo, language]);

  // 展示扫码点餐/自提/外卖入口
  const activeServiceVisible = useMemo(() => {
    return ['2', '3', '4', '5'].includes(channel_id);
  }, [channel_id]);

  const resultStoreId = useMemo(() => {
    return storeId || merchantStoreInfoRes?.data?.storeId;
  }, [storeId, merchantStoreInfoRes]);

  const scanAction = useMemoizedFn((visible) => {
    return ScanPopUpRef.current?.setVisible(visible);
  });

  if (merchantStoreInfoRes && !merchantStoreInfoRes.success && merchantStoreInfoRes.error && merchantStoreInfoRes.error.code === '3800021') {
    return <Expired></Expired>;
  }
  // storeInfo获取之前使用骨架屏
  if (!merchantStoreInfoRes)
    return (
      <div className={styles.skeleton}>
        <Skeleton.Title animated />
        <Skeleton.Paragraph lineCount={5} animated />
      </div>
    );

  return (
    <div className={styles.page} id="merchantPage">
      <MerchantNavBar safeHeight={0} transparent={true} />
      <NavBar storeId={resultStoreId!}></NavBar>
      <div className={styles.bgContainer}>
        <Image src={imageUrl ? compressImg(imageUrl, { width: 720 }) : defaultBg} className={styles.merchantBg} fit={'cover'}></Image>
      </div>
      <div className={styles.cardInfo}>
        <CardInfo storeInfo={merchantStoreInfo as HomepageStoreInfo} language={language} channel_id={channel_id}></CardInfo>
      </div>
      {activeServiceVisible && (
        <ActiveService
          storeInfo={merchantStoreInfo as HomepageStoreInfo}
          notice={notice!}
          language={language}
          scanAction={scanAction}
        ></ActiveService>
      )}
      <CouponList
        storeId={resultStoreId}
        productList={productList}
        couponBatchList={couponBatchList}
        updateCoupons={updateCoupons}
        homepageCouponInfo={homepageCouponInfo as HomepageCoupon}
        channel_id={channel_id}
      ></CouponList>
      {availableOrderFoodType && resultStoreId && (
        <RecommendFoods storeId={resultStoreId} cpType={cpType} language={language} availableOrderFoodType={availableOrderFoodType!}></RecommendFoods>
      )}
      <ScanPopUp ref={ScanPopUpRef}></ScanPopUp>
    </div>
  );
}
