/* eslint-disable */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, Input, Radio, Space, Toast } from 'antd-mobile';
import { Style, Animation } from '@capacitor/status-bar';
import { CameraResultType, CameraSource } from '@capacitor/camera';
import { InAppBrowser, DefaultWebViewOptions, DefaultSystemBrowserOptions } from '@capacitor/inappbrowser';
import KeyboardCom from './KeyboardCom';
import {
  checkPermission,
  requestPermission,
  notifyAppReady,
  requestCheckUpdate,
  saveImage,
  closeSplashPage,
  setAppLanguage,
  getAppInfo,
  getDeviceInfoAsync,
  getDeviceInfo,
  interceptBackKey,
  updateBadge,
  checkPermissionsPosition,
  requestPermissionsPosition,
  getCurrentPosition,
  watchPositionAddListener,
  getCurrentPositionPermissions,
  getPlatform,
  scanBarcode,
  statusBarSetStyle,
  setBackgroundColor,
  showStatusBar,
  hideStatusBar,
  getInfoStatusBar,
  setOverlaysWebView,
  getnetworkStatus,
  networkAddListener,
  networkRemoveAllListener,
  openInWebView,
  openInSystemBrowser,
  openInExternalBrowser,
  closeBrowser,
  addListenerBrowser,
  removeAllListenersBrowser,
  checkDeeplink,
  openDeeplink,
  getSafeArea,
  getStatusBarHeight,
  checkPermissionsCamera,
  requestPermissionsCamera,
  getPhoto
} from '@/utils/nativeSdk';

const urlReg = /^(?:http|https|ftp|file):\/\/?(?:www\.)?[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]|www\.[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const FRONT_ENV = import.meta.env.VITE_FRONT_ENV_DOMAIN;
export const nativeSdkExampleOptions = [
  {
    label: '监听键盘弹出-收起',
    fn: () => {
      Dialog.confirm({ content: <KeyboardCom></KeyboardCom> });
    }
  },
  {
    label: '获取当前运行环境',
    fn: () => getPlatform()
  },
  {
    label: '权限管理-检查权限',
    fn: async () => {
      const res = await checkPermission({
        permissions: ['android.permission.CAMERA', 'android.permission.ACCESS_NETWORK_STATE', 'android.Manifest.permission.INTERNET']
      });
      console.log('permission res:', res);
      return res;
    }
  },
  {
    label: '权限管理-请求权限',
    fn: async () => requestPermission({ permissions: ['android.permission.CAMERA'] })
  },
  {
    label: 'H5自升级-js初始化完成',
    fn: async () => await notifyAppReady()
  },
  {
    label: 'H5自升级-h5启动自升级任务',
    fn: async () => await requestCheckUpdate()
  },
  {
    label: '保存图片到本地相册',
    fn: async () => {
      let radio: CameraSource = CameraSource.Camera;
      const result = await Dialog.confirm({
        content: (
          <>
            <Radio.Group onChange={(v) => (radio = v as CameraSource)}>
              <Space direction="vertical">
                <Radio value={CameraSource.Camera}>拍照</Radio>
                <Radio value={CameraSource.Photos}>相册</Radio>
              </Space>
            </Radio.Group>
          </>
        )
      });
      const checkPermission = async (type: CameraSource, permission?: any) => {
        const { code, data } = permission || (await checkPermissionsCamera());
        if (code !== 0 || data?.[type.toLowerCase()] === 'denied') {
          if (permission) {
            Toast.show({ content: '未获取到相机或相册权限' });
            return false;
          } else {
            return checkPermission(type, await requestPermissionsCamera());
          }
        }
        return data;
      };
      const permissionResult = await checkPermission(radio);
      console.log(permissionResult);

      if (result && permissionResult) {
        const { code, data } = await getPhoto({ source: radio, resultType: CameraResultType.DataUrl });
        if (code === 0 && data?.dataUrl) {
          return await saveImage({ imageData: data.dataUrl });
        } else {
          Toast.show({ content: '照片获取失败' });
        }
      }
    }
  },
  {
    label: '关闭splash页面',
    fn: async () => await closeSplashPage()
  },
  {
    label: '获取应用信息',
    fn: async () => await getAppInfo()
  },
  {
    label: '客户端本地语言设置',
    fn: async () => {
      let inputValue = '';
      const result = await Dialog.confirm({
        content: (
          <>
            <Input type="text" placeholder="请输入语言" onChange={(v) => (inputValue = v)} />
          </>
        )
      });
      if (result && inputValue) {
        return await setAppLanguage({ language: inputValue });
      }
    }
  },
  {
    label: '获取设备信息(异步)',
    fn: async () => await getDeviceInfoAsync()
  },
  {
    label: '获取设备信息(同步)',
    fn: async () => await getDeviceInfo()
  },
  {
    label: '物理返回拦截',
    fn: async () => {
      let radio = '';
      const result = await Dialog.confirm({
        content: (
          <>
            <Radio.Group onChange={(v) => (radio = v as string)}>
              <Space direction="vertical">
                <Radio value="1">拦截</Radio>
                <Radio value="2">不拦截</Radio>
              </Space>
            </Radio.Group>
          </>
        )
      });
      if (result) {
        return await interceptBackKey(radio === '1');
      }
    }
  },
  {
    label: '更新角标数字',
    fn: async () => {
      const inputValue = { title: '', message: '', number: 0 };
      const result = await Dialog.confirm({
        content: (
          <>
            <Input placeholder="请输入通知标题" onChange={(v) => (inputValue.title = v)} />
            <Input placeholder="请输入通知内容" onChange={(v) => (inputValue.message = v)} />
            <Input placeholder="请输入红点显示个数" onChange={(v) => (inputValue.number = +v)} />
          </>
        )
      });
      if (result) {
        return await updateBadge(inputValue);
      }
    }
  },
  {
    label: '检查位置权限',
    fn: async () => await checkPermissionsPosition()
  },
  {
    label: '请求位置权限',
    fn: async () => await requestPermissionsPosition()
  },
  {
    label: '获取当前位置（不含请求权限）',
    fn: async () => {
      const startTime = Date.now();
      const result = await getCurrentPosition();
      return {
        ...result,
        time: Date.now() - startTime + 'ms'
      };
    }
  },
  {
    label: '获取当前位置-watch（不含请求权限）',
    fn: async () => {
      const startTime = Date.now();
      const result = await watchPositionAddListener({
        enableHighAccuracy: true,
        timeout: 20000,
        maximumAge: 1000 * 60 * 30
      });
      return {
        ...result,
        time: Date.now() - startTime + 'ms'
      };
    }
  },
  {
    label: '获取当前位置(含请求权限)',
    fn: async () => {
      const result = await getCurrentPositionPermissions('location');
      return result;
    }
  },
  {
    label: '扫描条形码',
    fn: async () =>
      await scanBarcode({
        hint: 0
      })
  },
  {
    label: '设置当前状态栏的样式',
    fn: async () => await statusBarSetStyle({ style: Style.Dark })
  },
  {
    label: '设置状态栏的背景颜色',
    fn: async () => await setBackgroundColor({ color: '#e04343' })
  },
  {
    label: '显示状态栏',
    fn: async () => await showStatusBar({ animation: Animation.Fade })
  },
  {
    label: '隐藏状态栏',
    fn: async () => await hideStatusBar({ animation: Animation.Fade })
  },
  {
    label: '状态栏信息',
    fn: async () => await getInfoStatusBar()
  },
  {
    label: '状态栏是否应覆盖 web 视图',
    fn: async () => {
      let radio = '';
      const result = await Dialog.confirm({
        content: (
          <>
            <Radio.Group onChange={(v) => (radio = v as string)}>
              <Space direction="vertical">
                <Radio value="1">覆盖</Radio>
                <Radio value="2">不覆盖</Radio>
              </Space>
            </Radio.Group>
          </>
        )
      });
      if (result) {
        return await setOverlaysWebView({ overlay: radio === '1' });
      }
    }
  },
  {
    label: '获取网络状态',
    fn: async () => await getnetworkStatus()
  },
  {
    label: '监听网络状态',
    fn: async () =>
      await networkAddListener('networkStatusChange', (res) => {
        alert('当前网络状态：' + JSON.stringify(res));
      })
  },
  {
    label: '移除网络监听',
    fn: async () => await networkRemoveAllListener()
  },
  {
    label: '打开webview',
    fn: async () => {
      // eslint-disable-next-line max-lines
      let inputValue = 'www.baidu.com';
      const result = await Dialog.confirm({
        content: (
          <>
            <Input type="text" placeholder="请输入地址" value={inputValue} onChange={(v) => (inputValue = v)} />
          </>
        )
      });
      if (result) {
        if (urlReg.test(inputValue)) {
          return await openInWebView({
            url: inputValue,
            options: {
              showURL: true,
              showToolbar: true,
              clearCache: true,
              clearSessionCache: true,
              mediaPlaybackRequiresUserAction: true,
              closeButtonText: '关闭',
              toolbarPosition: 0,
              showNavigationButtons: true,
              leftToRight: true,
              android: { allowZoom: true, hardwareBack: true, pauseMedia: true },
              iOS: {
                allowOverScroll: true,
                enableViewportScale: true,
                allowInLineMediaPlayback: true,
                surpressIncrementalRendering: true,
                viewStyle: 0,
                animationEffect: 0
              }
            }
          });
        } else {
          Toast.show({ content: '输入的不是一个地址' });
        }
      }
    }
  },
  {
    label: '打开系统浏览器',
    fn: async () => {
      let inputValue = 'www.baidu.com';
      const result = await Dialog.confirm({
        content: (
          <>
            <Input type="text" placeholder="请输入地址" value={inputValue} onChange={(v) => (inputValue = v)} />
          </>
        )
      });
      if (result) {
        if (urlReg.test(inputValue)) {
          return await openInSystemBrowser({
            url: inputValue,
            options: {
              android: {
                showTitle: true,
                hideToolbarOnScroll: true,
                viewStyle: 0,
                startAnimation: 0,
                exitAnimation: 0
              },
              iOS: {
                closeButtonText: 0,
                viewStyle: 0,
                animationEffect: 0,
                enableBarsCollapsing: true,
                enableReadersMode: true
              }
            }
          });
        } else {
          Toast.show({ content: '输入的不是一个地址' });
        }
      }
    }
  },
  {
    label: '打开外部浏览器',
    fn: async () => {
      let inputValue = 'www.baidu.com';
      const result = await Dialog.confirm({
        content: (
          <>
            <Input type="text" placeholder="请输入地址" value={inputValue} onChange={(v) => (inputValue = v)} />
          </>
        )
      });
      if (result) {
        if (urlReg.test(inputValue)) {
          return await openInExternalBrowser({ url: inputValue });
        } else {
          Toast.show({ content: '输入的不是一个地址' });
        }
      }
    }
  },
  {
    label: '关闭浏览器',
    fn: async () => await closeBrowser()
  },
  {
    label: '添加浏览器监听器-browserClosed',
    fn: async () =>
      await addListenerBrowser('browserClosed', () => {
        alert('browserClosed-浏览器监听');
      })
  },
  {
    label: '添加浏览器监听器-browserPageLoaded',
    fn: async () =>
      await addListenerBrowser('browserPageLoaded', () => {
        alert('browserPageLoaded-浏览器监听');
      })
  },
  {
    label: '移除浏览器监听器',
    fn: async () => await removeAllListenersBrowser()
  },
  {
    label: '检查是否可以使用给定的 URL 打开应用程序',
    fn: async () => {
      let inputValue = 'www.baidu.com';
      const result = await Dialog.confirm({
        content: (
          <>
            <Input type="text" placeholder="请输入地址" value={inputValue} onChange={(v) => (inputValue = v)} />
          </>
        )
      });
      if (result) {
        if (urlReg.test(inputValue)) {
          return await checkDeeplink({ url: inputValue });
        } else {
          Toast.show({ content: '输入的不是一个地址' });
        }
      }
    }
  },
  {
    label: '打开deeplink连接',
    fn: async () => {
      let inputValue = 'www.baidu.com';
      const result = await Dialog.confirm({
        content: (
          <>
            <Input type="text" placeholder="请输入地址" value={inputValue} onChange={(v) => (inputValue = v)} />
          </>
        )
      });
      if (result) {
        if (urlReg.test(inputValue)) {
          return await openDeeplink({ url: inputValue });
        } else {
          Toast.show({ content: '输入的不是一个地址' });
        }
      }
    }
  },
  {
    label: '获取安全区域信息',
    fn: async () => await getSafeArea()
  },
  {
    label: '获取状态栏高度',
    fn: async () => await getStatusBarHeight()
  },
  {
    label: '在WebView中打开指定页面',
    fn: async () => {
      console.warn('在WebView中打开指定页面');
      await InAppBrowser.openInWebView({
        url: FRONT_ENV,
        options: DefaultWebViewOptions
      });
    }
  },
  {
    label: '在系统浏览器中打开指定页面',
    fn: async () =>
      await InAppBrowser.openInSystemBrowser({
        url: FRONT_ENV,
        options: DefaultSystemBrowserOptions
      })
  },
  {
    label: '在外部浏览器中打开指定页面',
    fn: async () =>
      await InAppBrowser.openInExternalBrowser({
        url: FRONT_ENV
      })
  }
];
