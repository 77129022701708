/* eslint-disable react-refresh/only-export-components */
import React from 'react';
import { createBrowserRouter, redirect, RouteObject } from 'react-router-dom';
import type { Router as RemixRouter } from '@remix-run/router';
import Exception from '@/components/Exception';
// 首页组件同步加载
import RootLayout from '@/pages/rootLayout';
import Apphome from '@/pages/appHome';
import Merchant from '@/pages/merchant';
import performanceConfig from '@/utils/constants/performance';
// import NoNetwork from '@/pages/noNetwork';
// import SuspenseLazy from '@/components/SuspenseLazy';

// 异步加载路由组件
// const Home = React.lazy(() => import('@/pages/home'));
const Redux = React.lazy(() => import('@/pages/reduxDemo'));
const MyCouponList = React.lazy(() => import('@/pages/myCouponList'));
const CouponDetail = React.lazy(() => import('@/pages/couponDetail'));
const GroupCouponOrderDetail = React.lazy(() => import('@/pages/groupCouponOrderDetail'));
const GroupCouponDetail = React.lazy(() => import('@/pages/groupCouponDetail'));
const LoginProtocol = React.lazy(() => import('@/pages/loginProtocol'));
const Cashier = React.lazy(() => import('@/pages/cashier'));
const ConfirmOrder = React.lazy(() => import('@/pages/confirmOrder'));
const TripartiteCoupons = React.lazy(() => import('@/pages/tripartiteCoupons'));
//购买成功页
const PurchaseSuccessPage = React.lazy(() => import('@/pages/purchaseSuccessPage'));
const Demo = React.lazy(() => import('@/pages/demo'));
// 中奖记录页面
const WinningRecords = React.lazy(() => import('@/pages/winningRecords'));
// 商家首页
// const Merchant = React.lazy(() => import('@/pages/merchant'));
// const Redux = SuspenseLazy(() => import('@/pages/reduxDemo'));
const BrandsHomepage = React.lazy(() => import('@/pages/BrandsHomepage'));
// 搜索菜品页面
const SearchDishes = React.lazy(() => import('@/pages/searchDishes'));
// 品牌专区
const Brands = React.lazy(() => import('@/pages/brands'));
// 支付页
const Pay = React.lazy(() => import('@/pages/pay'));
// 爆品专区
const FlashDeal = React.lazy(() => import('@/pages/flashDeal'));
// 插件示例
const Example = React.lazy(() => import('@/pages/example'));
const DownloadQpon = React.lazy(() => import('@/pages/downloadQpon'));
// 无网络页面
// const NoNetwork = React.lazy(() => import('@/pages/noNetwork'));
const Mall = React.lazy(() => import('@/pages/mall'));
// 开发者选项页面
const developerOptions = React.lazy(() => import('@/pages/developerOptions'));

// 路由配置
const router = createBrowserRouter(
  [
    {
      element: <RootLayout />,
      children: [
        {
          path: '/',
          Component: Apphome
          // 重定向
          // loader: () => {
          //   return redirect(`/`);
          // }
        },
        {
          path: '/demo',
          Component: Demo
        },
        {
          path: '/platform',
          // 重定向
          loader: () => {
            return redirect(`/`);
          }
        },
        {
          path: '/platform/home/:activeKey?', // 首页路由的改造
          Component: Apphome
        },
        {
          path: '/platform/cashier',
          Component: Cashier
        },
        {
          path: '/platform/pay',
          Component: Pay
        },
        {
          path: '/platform/redux',
          Component: Redux
        },
        {
          path: '/platform/myCouponList',
          Component: MyCouponList
        },
        {
          path: '/platform/couponDetail',
          Component: CouponDetail
        },
        {
          path: '/platform/loginProtocol',
          Component: LoginProtocol
        },
        {
          path: '/platform/winningRecords',
          Component: WinningRecords
        },
        {
          path: '/platform/merchant',
          Component: Merchant
        },
        {
          path: '/platform/groupCouponDetail',
          Component: GroupCouponDetail
        },
        {
          path: '/platform/groupCouponOrderDetail',
          Component: GroupCouponOrderDetail
        },
        {
          path: '/platform/confirmOrder',
          Component: ConfirmOrder
        },
        {
          path: '/platform/brandsHomepage',
          Component: BrandsHomepage
        },
        {
          path: '/platform/searchDishes',
          Component: SearchDishes
        },
        {
          path: '/platform/tripartiteCoupons',
          Component: TripartiteCoupons
        },

        {
          path: '/platform/brands',
          Component: Brands
        },
        {
          path: '/platform/flashDeal',
          Component: FlashDeal
        },
        {
          path: '/platform/example',
          Component: Example
        },
        {
          path: '/platform/mall',
          Component: Mall
        },
        {
          path: '/platform/purchaseSuccessPage',
          Component: PurchaseSuccessPage
        },
        {
          path: '/platform/developerOptions',
          Component: developerOptions
        },
        {
          path: '/platform/downloadQpon',
          Component: DownloadQpon
        },
        // {
        //   path: '/platform/noNetwork',
        //   Component: NoNetwork
        // },
        {
          path: '*',
          element: <Exception type="404" title="404" description="Not Found" />
        }
      ]
    }
  ] as RouteObject[],
  {
    basename: '/'
  }
) as unknown as RemixRouter;

router.subscribe((state) => {
  // 标记性能埋点
  if (performanceConfig.isNeedReportPage) {
    performanceConfig.isNeedReportPage = false;
  }
  console.warn('subscribe:', state);
});
export default router;
