/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppPermission } from '@capacitor-qpon/permission';
import { CapacitorUpdater, AppSplash } from '@capacitor-qpon/h5-updater';
import { AppGallery } from '@capacitor-qpon/gallery';
import { AppInfo, KeyboardInfo } from '@capacitor-qpon/app-info';
import { AppBadge } from '@capacitor-qpon/badge';
import { Device } from '@capacitor-qpon/app-device';
import { Geolocation, GeolocationPermissionType } from '@capacitor/geolocation';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import { Network } from '@capacitor/network';
import { AppLauncher } from '@capacitor/app-launcher';
import { Camera } from '@capacitor/camera';
import { InAppBrowser } from '@capacitor/inappbrowser';
import { Keyboard } from '@capacitor/keyboard';
import { ErrorCode, middleware } from './trycatch';
import { Listener } from './Listener';
/**
 * 当前运行环境 android, ios, or web
 */
export const getPlatform = middleware(Capacitor.getPlatform);
/**
 * 判断是否为ios
 */
export const isIos = () => {
  const platform = middleware(Capacitor.getPlatform)();
  const res = platform.data === 'ios';
  console.log('ios res:', res, platform.data);
  return res;
};
/**
 * 是否在Qpon客户端内
 * Boolean if the platform is native or not. `android` and `ios`
 * would return `true`, otherwise `false`.
 */
export const isQponApp = () => {
  const isNativePlatform = middleware(Capacitor.isNativePlatform)();
  //   console.log('isNativePlatform', isNativePlatform);
  return isNativePlatform.data;
};

export const isQponIos = () => {
  const platform = middleware(Capacitor.getPlatform)();
  console.warn('isQponIos', platform);
  return platform.data === 'ios';
};

/**
 * 查询当前的容器环境、操作系统
 */
export const getAppSystem = () => {
  if (!isQponApp()) return 'other';
  return isIos() ? 'Ios' : 'Android';
};

/**
 * 返回当前环境是iso还是安卓
 */
export const platformType = () => {
  return isIos() ? 'Ios' : 'Android';
};
/**
 * 权限管理-检查权限
 */
export const checkPermission = middleware(AppPermission.checkPermissionForList);
/**
 * 权限管理-请求权限
 */
export const requestPermission = middleware(AppPermission.requestPermissionForList);
/**
 * H5自升级-js初始化完成
 */
export const notifyAppReady = middleware(CapacitorUpdater.notifyAppReady);
/**
 * H5自升级-h5启动自升级任务
 */
export const requestCheckUpdate = middleware(CapacitorUpdater.requestCheckUpdate);
/**
 * 事件监听
 */
export const addListener = middleware(CapacitorUpdater.addListener);
/**
 * 保存图片到本地相册
 */
export const saveImage = middleware(AppGallery.saveImage);
/**
 * 关闭splash页面
 */
export const closeSplashPage = middleware(AppSplash.closePage);
/**
 * 获取应用信息
 */
export const getAppInfo = middleware(AppInfo.getAppInfo);
/**
 * 客户端本地语言设置
 */
export const setAppLanguage = middleware(AppInfo.setAppLanguage);
/**
 * 物理返回键监听
 */
export const backButtonAddListener = middleware(AppInfo.addListener.bind(null, 'backButton'));
/**
 * 退出应用
 */
export const exitActivity = middleware(AppInfo.exitActivity);
/**
 * 获取设备信息(异步)
 */
export const getDeviceInfoAsync = middleware(Device.getDeviceInfoAsync);
/**
 * 获取设备信息(同步)
 */
export const getDeviceInfo = middleware(Device.getDeviceInfo);
/**
 * 物理返回拦截
 */
export const interceptBackKey = middleware(Device.interceptBackKey);
/**
 * 更新角标数字
 */
export const updateBadge = middleware(AppBadge.updateBadge);
/**
 * 检查位置权限
 */
export const checkPermissionsPosition = middleware(Geolocation.checkPermissions);
/**
 * 请求位置权限
 */
export const requestPermissionsPosition = middleware(Geolocation.requestPermissions);
/**
 * 获取当前位置
 */
export const getCurrentPosition = middleware(Geolocation.getCurrentPosition);
/**
 * 通过监听获取当前位置
 */
export const watchPositionAddListener = middleware((options: PositionOptions) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    const id = await Geolocation.watchPosition(options, (position, err) => {
      Geolocation.clearWatch({ id });
      if (err) {
        reject(err);
        return;
      }
      resolve(position);
    });
  });
});
/**
 * 获取当前位置(含请求权限)
 */
export const getCurrentPositionPermissions = middleware(
  async (permissionType: GeolocationPermissionType = 'coarseLocation', options?: PositionOptions) => {
    const { code, data } = await checkPermissionsPosition();
    if (code === 0) {
      if (data?.[permissionType] === 'denied') {
        throw new Error(ErrorCode.PERMISSION_DENIED);
      } else {
        const params = {
          enableHighAccuracy: permissionType === 'coarseLocation',
          timeout: 20000,
          // maximumAge: 1000 * 60 * 30,
          ...options
        };
        let result = null;
        if (isIos()) {
          result = await watchPositionAddListener(params);
        } else {
          result = await getCurrentPosition(params);
        }
        console.log('locationAction 定位getCurrentPosition 结果', result, code);
        if (result.code === 0) {
          return result.data;
        } else {
          if (data?.[permissionType] === 'granted') {
            throw new Error(ErrorCode.NOT_USE_WITH_GRANTED);
          } else {
            throw new Error(ErrorCode.NO_PERMISSION);
          }
        }
      }
    } else {
      if (code === +ErrorCode.PERMISSION_DENIED) {
        throw new Error(ErrorCode.PERMISSION_DENIED);
      }
      throw new Error(ErrorCode.NO_PERMISSION);
    }
  }
);
/**
 * 监听位置变化
 */
export const watchPosition = middleware(Geolocation.watchPosition);
/**
 * 清除位置监听
 */
export const clearWatchPosition = middleware(Geolocation.clearWatch);
/**
 * 扫描条形码
 */
export const scanBarcode = middleware(Geolocation.clearWatch);
/**
 * 设置当前状态栏的样式
 */
export const statusBarSetStyle = middleware(StatusBar.setStyle);
/**
 * 设置状态栏的背景颜色
 */
export const setBackgroundColor = middleware(StatusBar.setBackgroundColor);
/**
 * 显示状态栏
 */
export const showStatusBar = middleware(StatusBar.show);
/**
 * 隐藏状态栏
 */
export const hideStatusBar = middleware(StatusBar.hide);
/**
 * 状态栏信息
 */
export const getInfoStatusBar = middleware(StatusBar.getInfo);
/**
 * 状态栏是否应覆盖 web 视图
 */
export const setOverlaysWebView = middleware(StatusBar.setOverlaysWebView);
/**
 * 获取网络状态
 */
export const getnetworkStatus = middleware(Network.getStatus);
/**
 * 监听网络状态
 */
export const networkAddListener = middleware(Network.addListener);
/**
 * 移除网络监听
 */
export const networkRemoveAllListener = middleware(Network.removeAllListeners);
/**
 * 打开wenview
 */
export const openInWebView = middleware(InAppBrowser.openInWebView);
/**
 * 打开系统浏览器
 */
export const openInSystemBrowser = middleware(InAppBrowser.openInSystemBrowser);
/**
 * 打开外部浏览器
 */
export const openInExternalBrowser = middleware(InAppBrowser.openInExternalBrowser);
/**
 * 关闭浏览器
 */
export const closeBrowser = middleware(InAppBrowser.close);
/**
 * 添加浏览器监听器
 */
export const addListenerBrowser = middleware(InAppBrowser.addListener);
/**
 * 移除浏览器监听器
 */
export const removeAllListenersBrowser = middleware(InAppBrowser.removeAllListeners);
/**
 * 检查是否可以使用给定的 URL 打开应用程序
 */
export const checkDeeplink = middleware(AppLauncher.canOpenUrl);
/**
 * 打开deeplink连接
 */
export const openDeeplink = middleware(AppLauncher.openUrl);
/**
 * 获取安全区域信息
 */
export const getSafeArea = middleware(Device.getSafeArea);
/**
 * 获取状态栏高度
 */
export const getStatusBarHeight = middleware(Device.getStatusBarHeight);
/**
 * 检查相机和相册权限
 */
export const checkPermissionsCamera = middleware(Camera.checkPermissions);
/**
 * 请求相机和相册权限
 */
export const requestPermissionsCamera = middleware(Camera.requestPermissions);
/**
 * 获取照片
 */
export const getPhoto = middleware(Camera.getPhoto);

export type ListenerKeyboardInfo = (status: boolean, info?: KeyboardInfo) => void;
/**
 * 键盘监听
 */
export const keyboardListener = {
  _listeners: null as Listener<ListenerKeyboardInfo> | null,
  _init() {
    if (!keyboardListener._listeners) {
      keyboardListener._listeners = new Listener<ListenerKeyboardInfo>((fn) => {
        // 监听键盘弹出
        Keyboard.addListener('keyboardWillShow', (...arg) => fn(true, ...arg));
        // 监听键盘隐藏
        Keyboard.addListener('keyboardWillHide', () => fn(false));
      });
    }
    return keyboardListener._listeners;
  },
  add: middleware((fn: ListenerKeyboardInfo) => {
    keyboardListener._init()?.add(fn);
  }),
  remove: middleware((fn: any) => {
    keyboardListener._init()?.remove(fn);
  })
};

// /**
//  * 监听键盘弹出
//  */
// export const keyboardWillShow = (cb: (arg0: any) => any) => {
//   if (!isQponApp) return;
//   Keyboard.addListener('keyboardWillShow', (info) => {
//     console.warn('keyboard will show with height:', info.keyboardHeight);
//     cb && cb(info.keyboardHeight);
//   });
// };

// /**
//  * 监听键盘隐藏
//  */
// export const keyboardWillHide = (cb: () => any) => {
//   if (!isQponApp) return;
//   Keyboard.addListener('keyboardWillHide', () => {
//     console.warn('keyboard will hide');
//     cb && cb();
//   });
// };

/**
 * 移除所有键盘监听
 */
export const keyboardRemoveAllListeners = () => {
  if (!isQponApp) return;
  Keyboard.removeAllListeners();
};

/**
 * 判断是否为内部页面
 */
export const isInnerPage = (url: string) => {
  return url.includes('/platform/');
};
