interface Connection {
  effectiveType?: string; // 定义 effectiveType 属性
  [key: string]: unknown;
}
// 可能会经常切换网络，不能缓存，需要实时获取
interface NavigatorWithConnection extends Navigator {
  connection?: Connection; // 定义 connection 属性
  mozConnection?: Connection;
  webkitConnection?: Connection;
}
const navigatorWithConnection = navigator as NavigatorWithConnection;

export const getNetworkType = () => {
  //  connection API
  const connection = navigatorWithConnection.connection || navigatorWithConnection.mozConnection || navigatorWithConnection.webkitConnection;
  if (connection) {
    return connection.effectiveType;
  }
  // onLine 属性
  return navigator.onLine ?? 'unknown';
};
