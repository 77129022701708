import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateEffect } from 'ahooks';
import LoadingView from '../common/LoadingView';
import { RootState } from '@/store';

interface Props {
  children: React.ReactNode;
}

// 这个HOC保证用户切换语言后所有页面会刷新
export default function LanguageHOC({ children }: Props) {
  const language = useSelector((state: RootState) => state.language.value);
  const [reload, setReload] = useState(false);

  useUpdateEffect(() => {
    setReload(true);
    setTimeout(() => {
      setReload(false);
    }, 500);
  }, [language]);

  return reload ? <LoadingView /> : children;
}
