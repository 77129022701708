import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import cls from 'classnames';
import { BasicTarget, getTargetElement } from '@/utils/navbar/getTargetElement';
import { NativeProps, withNativeProps } from '@/utils/navbar/withNativeProps';
// import iconBack from '@/assets/navbar/back.svg';
import { getCssVariable } from '@/utils/tools/cssVariable';
import BackIcon from '../GlobalLayout/BackIcon';

const clsPrefix = `uc-nav-bar`;

export type NavBarProps = {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  showBackButton?: boolean;
  backIcon?: React.ReactNode;
  /** 自定义返回按钮内容，如果存在，则优先显示此内容，否则显示默认的返回按钮图标 */
  backSlot?: React.ReactNode;
  transparent?: boolean;
  reverse?: boolean;
  reverseTransparent?: boolean;
  hasBorder?: boolean;
  statusBarHeight?: number;
  safeHeight?: number;
  onBack?: (s?: 'backPage' | 'backHome') => void;
  backType?: 'backPage' | 'backHome';
  children?: React.ReactNode;
  footer?: React.ReactNode;
  scrollElement?: BasicTarget;
  threshold?: number;
  align?: 'left' | 'center';
  onScroll?: (scrollTop: number, rate: number) => void;
} & NativeProps;

export type NavBarActionProps = {
  autoColor?: false | 'fill' | 'stroke';
  fastClick?: boolean;
  stopPropagation?: boolean;
  onClick?: () => void;
  icon?: React.ReactNode;
} & NativeProps;

export const IconBack = ({ onBack, backType }: { onBack?: (s?: 'backPage' | 'backHome') => void; backType?: 'backPage' | 'backHome' }) => {
  // return <img src={iconBack} />;
  return <BackIcon onClick={onBack} backType={backType} />;
};

export const NavBarAction = React.forwardRef<HTMLDivElement, NavBarActionProps>((props, ref) => {
  const { icon, onClick, autoColor } = props;

  return withNativeProps(
    props,
    <div
      ref={ref}
      className={cls(`${clsPrefix}-action`, {
        [`${clsPrefix}-action-fill`]: autoColor === 'fill',
        [`${clsPrefix}-action-stroke`]: autoColor === 'stroke'
      })}
      onClick={onClick}
    >
      {icon}
    </div>
  );
});

const switchTitleColorRate = 0.4;

const NavBar: React.FC<NavBarProps> = (props) => {
  const {
    children,
    footer,
    title,
    subTitle,
    showBackButton,
    backIcon,
    backSlot,
    transparent,
    reverse,
    reverseTransparent,
    hasBorder,
    safeHeight,
    statusBarHeight,
    align = 'left',
    onBack,
    backType,
    scrollElement,
    threshold = 100,
    onScroll
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const rafLock = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasBorder && !transparent) return;
    const el = getTargetElement(scrollElement) || window;
    const updatePosition = () => {
      if (!rafLock.current) {
        const elScrollTop = (el as Element)?.scrollTop || -Infinity;

        requestAnimationFrame(() => {
          if (containerRef.current) {
            const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, elScrollTop, document.body.scrollTop);
            const scrollRate = Math.max(Math.min(parseFloat((scrollTop / threshold).toFixed(2)), 1), 0);
            onScroll?.(scrollTop, scrollRate);
            containerRef.current.style.setProperty('--transparent', String(scrollRate));
            const reverseColor = transparent && scrollRate <= switchTitleColorRate && reverse;
            containerRef.current.classList[reverseColor ? 'add' : 'remove'](`${clsPrefix}-reverse`);
            containerRef.current.classList[reverseColor && reverseTransparent ? 'add' : 'remove'](`${clsPrefix}-reverse-transparent`);
            const showBarBorder = hasBorder && scrollRate > switchTitleColorRate;
            containerRef.current.classList[showBarBorder ? 'add' : 'remove'](`${clsPrefix}-border`);
          }

          rafLock.current = false;
        });

        rafLock.current = true;
      }
    };

    updatePosition();

    el.addEventListener('scroll', updatePosition);
    return () => el.removeEventListener('scroll', updatePosition);
  }, [scrollElement, transparent, reverse, reverseTransparent, hasBorder, threshold, onScroll]);

  const navBarStatusBarHeight = getCssVariable('--status-bar-height');

  const toBack = () => {
    if (onBack) {
      onBack();
      return;
    }
    navigate(-1); //返回上一层
  };

  const renderBackButton = () => {
    if (!showBackButton) {
      return <div className={`${clsPrefix}-container-action-placeholder`} />;
    }
    const defaultBackIcon = <IconBack onBack={onBack} backType={backType} />;
    if (backSlot) {
      return (
        <div className={`${clsPrefix}-container-back-btn`} onClick={toBack}>
          {backSlot}
        </div>
      );
    }
    // Only bind the toBack event if backIcon is provided
    const navBarActionProps = backIcon ? { icon: backIcon, onClick: toBack } : { icon: defaultBackIcon };

    return <NavBarAction {...navBarActionProps} />;
  };

  return (
    <div className={`${clsPrefix}-safe-area`} style={{ height: safeHeight! + (statusBarHeight || navBarStatusBarHeight) }}>
      {withNativeProps(
        props,
        <div
          style={{ paddingTop: statusBarHeight || navBarStatusBarHeight }}
          className={cls(clsPrefix, {
            [`${clsPrefix}-transparent`]: transparent
          })}
          ref={containerRef}
        >
          <div className={`${clsPrefix}-container`} style={{ height: safeHeight! }}>
            <div className={`${clsPrefix}-container-action`}>{renderBackButton()}</div>
            <div className={cls(`${clsPrefix}-container-section`, `${clsPrefix}-container-section-align-${align}`)}>
              <div className={`${clsPrefix}-container-section-title`}>{title}</div>
              {subTitle ? <div className={`${clsPrefix}-container-section-subtitle`}>{subTitle}</div> : null}
            </div>
            <div className={`${clsPrefix}-container-action`}>{children}</div>
          </div>
          {footer}
        </div>
      )}
    </div>
  );
};

NavBar.defaultProps = {
  hasBorder: true,
  statusBarHeight: 0,
  safeHeight: 48
};

export default NavBar;
