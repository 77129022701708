import { useEffect, useState } from 'react';
import { FloatingBubble, Popup, Radio, Space } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import { MessageFill } from 'antd-mobile-icons';
import { curBaseUrl } from '@/utils/ajax/config/baseConfig.ts';
import { findKeyByValue } from '@/utils/tools/object.ts';
import { getNearbyPlaces, getGeocoderDetails, getPlaceInfo, getCurrentPlace } from '@/utils/tools/GoogleMaps';
import usePosition from '@/hooks/usePosition';
import { isDev, isTest, isPre, isProd } from "@/utils/env";

/**
 * 测试环境的请求baseUrl配置弹窗
 * @constructor
 */
function ConfigTestEnv() {
  const navigate = useNavigate();
  const { positionInfo } = usePosition(true);
  const [visible, setVisible] = useState(false);
  const [nearby, setNearby] = useState('');
  const [geocoder, setGeocoder] = useState('');
  const [longName, setLongName] = useState('');
  const [place, setPlace] = useState<{ name?: string; formatted_address?: string }>({});
  const [currentPlace, setCurrentPlace] = useState<{ id?: string; displayName?: { text?: string; languageCode?: string } }>({});
  const [offset, setOffset] = useState({ x: -24, y: -24 });

  const apiHostMap = {};
  // let radioValue = '';
  const [radioValue, setRadioValue] = useState('');

  const onBubbleClick = () => {
    setVisible(true);
  };

  if(isDev || isTest) {
    const host = import.meta.env.VITE_BACKEND_HOST;
    const arr = host.split(',');
    // 遍历数组并赋值给apiHostMap
    arr.forEach((url, index) => {
      const key = `test${index + 1}`;
      apiHostMap[key] = url;
    });
  }

  useEffect(() => {
    setRadioValue(findKeyByValue(apiHostMap, curBaseUrl.value));
  }, []);

  // 预发布、生产环境不渲染
  if (isPre || isProd) {
    return null;
  }

  const onRadioChange = (v) => {
    console.log('onRadioChange v:', v, apiHostMap);
    // 兜底取生产环境
    // eslint-disable-next-line react-hooks/rules-of-hooks
    curBaseUrl.value = apiHostMap?.[v] || 'https://qpon-food-gl.qponmobile.com';
    setRadioValue(v);
  };
  // 单选框选项配置
  const radioArray = [
    { key: 1, value: 'test1', label: 'test1' },
    { key: 2, value: 'test2', label: 'test2' },
    { key: 3, value: 'test3', label: 'test3' },
    { key: 4, value: 'test4', label: 'test4' },
    { key: 5, value: 'test5', label: 'test5' }
  ];

  // 过滤含有locality、political的数据
  // eslint-disable-next-line
  const filterResults = (places: any[]) => {
    // eslint-disable-next-line
    return places.filter((place: any) => {
      return place.types && (!place.types.includes('locality') || !place.types.includes('political'));
    });
  };

  const handleClick = async () => {
    console.log('handleClick', positionInfo);
    const getNearbyDetails = await getNearbyPlaces({
      lat: Number(positionInfo.latitude),
      lng: Number(positionInfo.longitude),
      types: [],
      radius: 1000
    });
    const { results, success } = getNearbyDetails;
    const filterData = filterResults(results);
    setNearby(filterData?.[0]?.name);
    console.log('getNearbyDetails地址', filterData, success);

    const geocoderDetails = await getGeocoderDetails({
      lat: Number(positionInfo.latitude),
      lng: Number(positionInfo.longitude)
    });

    console.log('geocoderDetails地址', geocoderDetails);
    setGeocoder(geocoderDetails?.results?.[0]?.formatted_address);
    setLongName(geocoderDetails?.results?.[0]?.address_components?.[0]?.long_name);

    const geocoderPlaceDetail = await getPlaceInfo({
      lat: Number(positionInfo.latitude),
      lng: Number(positionInfo.longitude)
    });

    console.log('geocoderPlaceDetail地址', geocoderPlaceDetail);
    setPlace(geocoderPlaceDetail?.results?.[0]);

    const currentPlace = await getCurrentPlace({
      lat: Number(positionInfo.latitude),
      lng: Number(positionInfo.longitude)
    });

    setCurrentPlace(currentPlace?.results);

    console.log('geocoderPlaceDetail地址新方式', currentPlace);
  };
  return (
    <>
      <FloatingBubble
        axis="xy"
        style={{
          '--initial-position-bottom': '0',
          '--initial-position-right': '0',
          zIndex: 999,
          position: 'relative'
        }}
        onOffsetChange={(offset) => {
          setOffset(offset);
        }}
        offset={offset}
        onClick={onBubbleClick}
      >
        <MessageFill fontSize={32} />
      </FloatingBubble>
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        onClose={() => {
          setVisible(false);
        }}
        bodyStyle={{ height: '50vh', padding: '20px 20px' }}
      >
        <>
          <div style={{ marginBottom: '10px' }}>nearby信息：{nearby}</div>
          <div style={{ marginBottom: '10px' }}>Geocoder第一条的详细的LongName：{longName} </div>
          <div style={{ marginBottom: '10px' }}>Geocoder信息：{geocoder} </div>
          <div style={{ marginBottom: '10px' }}>GeocoderPlace信息name：{place?.name} </div>
          <div style={{ marginBottom: '10px' }}>GeocoderPlace信息formatted_address：{place?.formatted_address} </div>
          <div style={{ marginBottom: '10px' }}>GeocoderPlace新方式：{currentPlace?.displayName?.text} </div>
          <div style={{ marginBottom: '10px' }}>
            <button onClick={handleClick} style={{ marginRight: '10px' }}>
              测试定位
            </button>
            <button
              onClick={() => {
                setNearby('');
                setGeocoder('');
                setLongName('');
                setPlace({});
                setCurrentPlace({});
              }}
              style={{ marginRight: '10px' }}
            >
              清空
            </button>
            <button onClick={() => navigate?.('/platform/example')}>打开插件示例</button>
          </div>
          <Radio.Group value={radioValue} onChange={onRadioChange}>
            <Space direction="vertical">
              <div style={{ fontSize: '17px' }}>请求接口环境切换：</div>
              {radioArray.map((item) => (
                <Radio key={item.key} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </>
      </Popup>
    </>
  );
}
export default ConfigTestEnv;
