import { Env } from '@/types/ajax';
import { frontEnv, isDev, isTest } from '@/utils/env';

const BASE_PATH = '/digital-food';

type SignKeyType = {
  [K in Env]: {
    appKey: string;
    secret: string;
  };
};

const signKey = {
  test: {
    appKey: 'Bc9fQyuuFW7KTpYsm4ocVc',
    secret: 'kMGxjAoLw8QnTIwVmF/mJA=='
  },
  pre: {
    appKey: 'RZefCEKumLBvnRmmqiih1w',
    secret: 'i0j3eBSzYR7c4/Sxh+5f5g=='
  },
  prod: {
    appKey: 'AuHXwhmjE4EiMSq7WY3FTm',
    secret: 'JPEP4W5FmqM4XBzMhv5lxA=='
  }
} as SignKeyType;

//前端环境
//处理签名 测试本地等环境签名都用测试环境的appKey,预发布生产单独使用各自的
const signConfig = isDev || isTest ? signKey.test : signKey[frontEnv];

const channel = 'CUSTOMER';

/// 需要风控校验的接口

const includeBspCheckList = [
  '/risk/getDeviceInfo',
  '/account/checkSlider',
  '/pay/createPayOrder',
  // 领券加入风控
  '/market/coupon/receive',
  '/local_life/order/place_order',
  '/local_life/flash_sale/order/place_order',
  '/local_life/flash_sale/order/settlement',
  '/local_life/flash_sale/product/detail',
  '/local_life/flash_sale/page_detail'
];

export { BASE_PATH, signConfig, channel, includeBspCheckList };
