import { AndroidWebViewOptions, DefaultWebViewOptions, InAppBrowser, iOSWebViewOptions, ToolbarPosition } from '@capacitor/inappbrowser';
import { isQponApp, openDeeplink } from '../nativeSdk';

//app跳转deeplink H5使用window.open
export const toOpenDeepLink = async (url: string) => {
  const platform = isQponApp();
  if (platform) {
    //app
    await openDeeplink({ url });
  } else {
    //h5
    window.open(url);
  }
};

export interface WEBVIEWOPTIOS {
  showURL?: boolean;
  showToolbar?: boolean;
  clearCache?: boolean;
  clearSessionCache?: boolean;
  mediaPlaybackRequiresUserAction?: boolean;
  closeButtonText?: string;
  toolbarPosition?: ToolbarPosition;
  showNavigationButtons?: boolean;
  leftToRight?: boolean;
  android?: AndroidWebViewOptions;
  iOS?: iOSWebViewOptions;
}
//app打开webview
export const toOpenWebView = async (url: string, options: WEBVIEWOPTIOS, type?: string) => {
  console.log('传入的打开webview的参数:', options, '合并默认的打开webview的参数', { ...DefaultWebViewOptions, ...options });
  const platform = isQponApp();
  if (platform) {
    await InAppBrowser.openInWebView({
      url,
      options: { ...DefaultWebViewOptions, ...options }
    });
  } else {
    //h5
    if (type === 'href') {
      window.location.href = url;
    } else {
      window.open(url);
    }
  }
};
