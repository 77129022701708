import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { LanguageSdk } from '@qpon-design/i18';
import { useMount } from 'ahooks';
import { initLanguageMap } from './utils/tools/others';
import usePageShowReport from './utils/tools/report';
import LanguageHOC from './components/LanguageHOC';
import ErrorBoundary from './components/ErrorBoundary';
import { asyncInit, fetchFireBaseRemoteConfig, syncInit } from './utils/appMount';
import useReport from './hooks/useReport';
import NoNetwork from './components/noNetWork';
import routes from '@/router/routes';
// import '@/styles/global.scss';
// import './App.scss';
import { useHistoryStack } from '@/hooks/useHistoryStack';
import { initAppListener } from '@/utils/appMount/index.ts';

function App() {
  console.count('APP render');
  const { reportInit, report } = useReport();
  LanguageSdk.init(initLanguageMap);
  syncInit(); // 同步初始化
  asyncInit(); // 异步初始化
  reportInit(); //初始化埋点sdk
  usePageShowReport(); // 全局的监听上报
  useHistoryStack(); // 监听路由变化
  initAppListener(report); // app事件监听
  useMount(() => {
    // if (!['/platform/home', '/platform/merchant'].includes(path)) {
    //   asyncInitDeviceId();
    // }
    fetchFireBaseRemoteConfig();
  });
  return (
    <NoNetwork>
      <ErrorBoundary>
        <Suspense>
          <LanguageHOC>
            <RouterProvider router={routes} />
          </LanguageHOC>
        </Suspense>
      </ErrorBoundary>
    </NoNetwork>
  );
}

export default App;
