import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/store';

export interface CounterState {
  storeDistanceInfo: {
    isOpenLocation?: false; //是否授权
    locationDistance?: string; //到店距离
  };
}

const initialState: CounterState = {
  storeDistanceInfo: {}
};

// 更新操作
export const orderState = createSlice({
  name: 'order',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // 更新到店距离
    updateStoreDistanceInfo: (state, action) => {
      state.storeDistanceInfo = action.payload;
    }
  }
});

export const { updateStoreDistanceInfo } = orderState.actions;

export const getStoreDistanceInfo = (state: RootState) => state.order.storeDistanceInfo;

export default orderState.reducer;
