import { monitorReport } from '../monitor/monitorReport';

const ERROR_CODE = {
  '5020000': '5020000', // 系统异常
  '5010000': '5010000', // 参数异常
  '0310202': '0310202', // accessToken非法
  '0310203': '0310203', // refreshToken非法
  '0310204': '0310204', // accessToken已过期
  '0310205': '0310205', // refreshToken已过期
  '0310207': '0310207', // 请输入正确的手机号
  '0310208': '0310208', // 因系统限制登录失败，请稍后重试
  '0310209': '0310209', // 该手机号当天发送验证码次数已达上限，请明日再试
  '0310210': '0310210', // 该设备当天发送验证码次数已达上限，请明日再试
  '0310211': '0310211', // 滑块校验不通过
  '0310212': '0310212', // 验证码错误
  '0310216': '0310216', // 该手机号发送验证码时间间隔小于60s
  '0310218': '0310218', // 因系统限制账号被冻结
  '0310219': '0310219', // 验证码已过期，请重新发送
  '9000002': '9000002', //优惠券已被领光，请下次再来吧
  '9000007': '9000007', // message: 已过优惠券领取时间
  '9000012': '9000012', //该优惠券已达到今日领取上限,请明天再来吧
  '0310402': '0310402', //支付请求过期
  '4110008': '4110008', //订单总价超过qris限额
  '4110011': '4110011', //支付渠道不支持
  '9110057': '9110057', //支付账号不存在
  '9110058': '9110058', //支付账号不异常
  '4110009': '4110009', //后端返回网络异常
  RATE_LIMIT: 429, // 后端返回接口限流
  ERR_NETWORK: 'ERR_NETWORK', // 网络错误
  ECONNABORTED: 'ECONNABORTED', // 请求超时
  ERR_CANCELED: 'ERR_CANCELED', // 请求取消
  '100000030': '100000030' //活动价格发生改变
};

// 默认不上报类型
const defaultExcludeErrorCode: string[] = [
  'ERR_NETWORK',
  'ECONNABORTED',
  'ERR_CANCELED',
  '0310202', // accessToken非法
  '0310203', // accessToken非法
  '0310204', // accessToken已过期
  '0310205', // refreshToken已过期
  '0310207', // 请输入正确的手机号
  '0310208', // 因系统限制登录失败，请稍后重试
  '0310209', // 该手机号当天发送验证码次数已达上限，请明日再试
  '0310210', // 该设备当天发送验证码次数已达上限，请明日再试
  '0310211', // 滑块校验不通过
  '0310212', // 验证码错误
  '0310214', // 获取风控等级失败
  '0310215', // 滑块接口请求失败
  '0310216', // 该手机号发送验证码时间间隔小于60s
  '0310218', // 因系统限制账号被冻结
  '0310219', // 验证码已过期，请重新发送
  '0310402', //支付请求过期
  '3800021', // 门店桌码信息不存在
  '3800057', // 门店信息为空
  '4110008', //订单总价超过qris限额
  '4110009', //后端返回网络异常
  '4110011', //支付渠道不支持
  '4110023', //订单已完成支付，请勿重复创建支付二维码
  '6000003', // 检测到重复操作
  '6510005', // 单个手机号的发送验证码超出单日限制
  '8000005', // 活动不存在
  '8000016', // 活动已结束，感谢您的关注
  '8000071', // 领取失败，暂不符合领取资格
  '8000072', // 优惠券已领完，请下次再来
  '9000002', //优惠券已被领光，请下次再来吧
  '9000007', //已过优惠券领取时间
  '9000009', // 该优惠券已达到活动领取上限,请下次再来吧
  '9000012', //该优惠券已达到今日领取上限,请明天再来吧
  '9000013', // 无权访问优惠券，一般是访问了别人的优惠券详情页导致
  '9000022', // 当前优惠券已被使用，请使用其他优惠券
  '9110057', //支付账号不存在
  '9110058', //支付账号不异常
  '10000005', // 超值套餐已抢光，可购买其他套餐
  '10000006', // 超值套餐今日已抢光，可明日再来
  '10000007', // 今天的限购额已达，明天再来
  '10000008', // 已达可购买上限，可购买其他套餐
  '10000009', // 此超值套餐已下架，购买失败
  '3800039', //当前门店没有配置推荐菜
  '100000030' //活动价格发生改变
];

// 以下类型接口异常不上报
let excludeErrorCode: string[] = [...defaultExcludeErrorCode];
const updateExcludeErrorCode: (codes: string[]) => void = function (codes) {
  try {
    excludeErrorCode = Array.from(new Set([...excludeErrorCode, ...codes]));
  } catch (error: unknown) {
    monitorReport.info({ category: '生成excludeErrorCode失败', msg: { message: error?.message || '' } });
  }
};

export { ERROR_CODE, excludeErrorCode, updateExcludeErrorCode };
