/* eslint-disable @typescript-eslint/no-explicit-any */
import webSee from '@websee-food/core';
import webseeConfig from '@/api/common/webseeConfig';
import { cloudBuildVersionStr } from './tools/constant';
import { LOCAL_KEY, storage } from './tools';
import { excludeErrorCode } from './constants/errorCode';
import { getObusSessionId, getObusDeviceId } from './tools/others';

let isDisabled = true;

const jsonParas = (str: string | null) => {
  try {
    return JSON.parse(str || '');
  } catch (error) {
    return null;
  }
};
let currentVersion = '';
/** 获取应用版本 */
const getVersion = () => {
  if (!currentVersion) {
    const { versionName } = storage.getLocalStorage(LOCAL_KEY.CLIENT_INFO, true) || ({} as any);
    const { h5_version_name } = jsonParas(window.localStorage.getItem('h5_version')) || {};
    currentVersion = `${cloudBuildVersionStr}(${versionName || ''}/${h5_version_name || ''})`;
  }
  return currentVersion;
};
/**
 * 需要监控的接口
 */
const includesPaths = ['/digital-food/'];
/**
 * 不上报错误的错误码
 */
const excludeCodes = excludeErrorCode;
/**
 * 事件类型
 */
export enum EVENTTYPES {
  XHR = 'xhr',
  FETCH = 'fetch',
  CLICK = 'click',
  HISTORY = 'history',
  ERROR = 'error',
  HASHCHANGE = 'hashchange',
  UNHANDLEDREJECTION = 'unhandledrejection',
  RESOURCE = 'resource',
  DOM = 'dom',
  VUE = 'vue',
  REACT = 'react',
  CUSTOM = 'custom',
  PERFORMANCE = 'performance',
  RECORDSCREEN = 'recordScreen',
  WHITESCREEN = 'whiteScreen',
  NATIVESDK = 'nativeSdk'
}
/**
 * 插件层错误
 */
export const webSeeNativeSdkLog = (error: Error) => {
  if (isDisabled) {
    return;
  }
  webSee.log({
    type: EVENTTYPES.NATIVESDK,
    message: `插件调用错误：${error.message}`,
    error
  });
};

// 普通上报
export const webSeeLog = (message: string, error: string | Error) => {
  if (isDisabled) {
    return;
  }
  error = error || message;
  error = typeof error === 'string' ? new Error(error) : error;
  webSee.log({
    // type: EVENTTYPES.CUSTOM,
    message,
    error
  });
};
export default {
  install() {
    const {
      disabled, // 是否禁用
      appId = '10001', // (必传项) 每个项目对应一个 apikey，唯一标识
      appName = 'C端平台页', // 应用名称
      silentWhiteScreen = true, // 开启错误上报去重，重复的代码错误只上报一次
      whiteBoxElements = ['html', 'body', '#app', '#root', '.loading', '.loadingIcon', '.van-toast__text'], // 视为白屏的容器
      skeletonProject = false, // 项目是否有骨架屏
      setIncludesPaths = [] // 指定需要监控的接口
    } = storage.getLocalStorage(LOCAL_KEY.WEBSEE_CONFIG, true) || ({} as any); // 服务下发的配置信息

    if (disabled || import.meta.env.MODE === 'dev') {
      // 是否禁用监控
      isDisabled = true;
      return;
    }
    isDisabled = false;

    const baseUrl = import.meta.env.VITE_NODE_HOST || 'https://qpon-food-gl.qponmobile.com';
    // 获取配置信息
    webseeConfig(baseUrl || '', appId).then((res) => {
      if (res.success && res.data) {
        storage.setLocalStorage(LOCAL_KEY.WEBSEE_CONFIG, res.data, true);
      }
    });

    webSee.init({
      dsn: baseUrl + '/nodePublic/control/reportData', // 上报的地址
      appId, // (必传项) 每个项目对应一个 apikey，唯一标识
      appName, // 应用名称
      appVersion: getVersion, // 版本
      userId: () => window.localStorage.getItem('userId') || '', // 用户id
      repeatCodeError: true, // 开启错误上报去重，重复的代码错误只上报一次
      silentWhiteScreen, // 开启白屏检测
      whiteBoxElements, // 视为白屏的容器
      skeletonProject, // 项目是否有骨架屏
      filterXhrUrlRegExp: new RegExp(
        `^(?!.*(${setIncludesPaths
          .concat(includesPaths)
          .map((s: string) => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
          .join('|')})).+$`
      ),
      customInfoLoad: () => ({
        sessionId: localStorage.getItem(LOCAL_KEY.QPON_SESSION_ID) || getObusSessionId(),
        deviceId: localStorage.getItem(LOCAL_KEY.QPON_DEVICE_ID) || getObusDeviceId()
      }),
      handleHttpStatus(data: any) {
        // 自定义hook, 用来检测接口是否异常需要上报
        const { response } = data;
        const { code, error, success } = typeof response === 'string' ? JSON.parse(response) : response;
        const resCode = code || error?.code || '';
        if (excludeCodes.includes(resCode)) {
          return true;
        }
        return !(!success && !excludeCodes.find((code) => code === resCode));
      },
      // 上传前数据处理
      beforeDataReport(data: any) {
        return data;
      }
    });
  }
};
