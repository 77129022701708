import { useRequest } from 'ahooks';
import { AxiosRequestConfig } from 'axios';
import request from '@/utils/ajax';
import { HttpErrorConfig, UseRequestOptionsConfig } from '@/utils/ajax/interface';

/**
 * url:请求接口的url
 * data: 请求传入参数
 * axiosOpts：其他的请求配置 比如请求方式POST GET等 默认POST
 * useRequestOptions?: useRequest需要的配置参数
 * ahooks 官网地址 https://ahooks.js.org/zh-CN/hooks/use-request/index
 */

const useFetch = <T, K>(url: string, data?: T, axiosOpts?: AxiosRequestConfig, useRequestOptions?: UseRequestOptionsConfig) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useRequest<K, any>((params?: any) => request<T, K>(url, { ...data, ...params }, axiosOpts), {
    manual: true, // true允许外部手动控制请求触发时机  false：引用时自动调用
    onError: (error: HttpErrorConfig) => {
      console.error(`通用HTTP错误: HTTP错误代码 ${error?.code}`); //网络请求的错误会被捕获 这里统一做console 业务需要特殊处理则传入onError覆盖即可
    },
    debounceWait: 300, //防抖
    ...useRequestOptions //其他配置项
  });
};

export default useFetch;
