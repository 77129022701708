// 云平台构建版本
const cloudBuildVersionStr = 'feature_20241211-online-payPage-fix-20529ee-20241212194325-27';

// 是否离线包标识，在生成离线包前手动修改(暂定 -- offline_true)
window.offline_status = 'offline_false';

// 优惠券状态const
const couponStatus = {
  valid: 'valid', //可用
  used: 'used', //已使用
  locked: 'locked', //核销中
  expired: 'expired', //已过期
  not_effective: 'not_effective' //未生效
};

// adjustTokne
const adjustToken = {
  Ios: 'bxxrs0ow1tz4',
  Android: '4tt61o72u58g'
};
const defaultFoodImageUrlRegExp = /\/product\/default\.(jpg|png)/;

export { couponStatus, cloudBuildVersionStr, defaultFoodImageUrlRegExp, adjustToken };
