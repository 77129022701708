// 优惠券常量字段

// 优惠券状态
const couponStatus = {
  VALID: 'valid', //可使用
  USED: 'used', //已使用
  LOCKED: 'locked', //已锁定
  EXPIRED: 'expired', //已核销
  NOT_EFFECTIVE: 'not_effective' //已过期
};
// 优惠券支付状态
const couponPayStatus = {
  AWAITPAY: 'awaitPay', //待支付
  CLOSE: 'close', //已关闭
  PAYSUCESS: 'pay_sucess' //支付成功
};

// 券码状态
const voucherStatus = {
  VALID: 'VALID', //可使用
  USED: 'USED', //已使用
  REFUND: 'REFUND', //已退款
  EXPIRED: 'EXPIRED' //已过期
};

const sdkType = {
  PUBSUB: 'pubsub',
  OBUS: 'obus'
};

export { couponStatus, couponPayStatus, voucherStatus, sdkType };
