import { AxiosData, MonitorInfo, SCENE_TYPE, monitorReport } from './monitorReport';
import { excludeErrorCode } from '@/utils/constants/errorCode';
// 关键字错误分类处理，用于告警屏蔽
const keywords2sceneTypeMap = {
  promiseError: {
    'Loading chunk': SCENE_TYPE.RESOURCE_LOAD_ERROR,
    'Aborted(both async and sync fetching of the wasm failed)': SCENE_TYPE.BSP_ERROR,
    'Aborted(load_wasm_err_RuntimeError: Aborted(failed to asynchronously prepare wasm': SCENE_TYPE.BSP_ERROR,
    'Could not process request. Application offline': SCENE_TYPE.FIREBASE_ERROR,
    'Connection is closing': SCENE_TYPE.FIREBASE_ERROR,
    'The database connection is closing': SCENE_TYPE.FIREBASE_ERROR,
    'Transaction timed out due to inactivity': SCENE_TYPE.FIREBASE_ERROR,
    'Database deleted by request of the user': SCENE_TYPE.FIREBASE_ERROR,
    'Version change transaction was aborted in upgradeneeded event handler': SCENE_TYPE.FIREBASE_ERROR,
    'this.oTrack.store.getSessionProps': SCENE_TYPE.OBUS_ERROR,
    'The transaction was aborted, so the request cannot be fulfilled': SCENE_TYPE.FIREBASE_ERROR
  },
  jsError: {
    "Can't find variable: WebViewJavascriptBridge": SCENE_TYPE.WEBVIEW_ERROR,
    "Failed to execute 'setPointerCapture' on 'Element'": SCENE_TYPE.WEBVIEW_ERROR,
    'Cannot redefine property: myRun': SCENE_TYPE.BSP_ERROR
  },
  apiError: {
    // 门店为空
    'Informasi toko kosong': SCENE_TYPE.API_BACK_ERROR,
    // 订单不存在
    'Order does not exist': SCENE_TYPE.API_BACK_ERROR,
    // 历史版本调用已下线接口
    'code: 404': SCENE_TYPE.API_BACK_ERROR,
    // 风控验证码接口签名失败
    'code: 0310101; message: 签名错误': SCENE_TYPE.BSP_ERROR
  }
};

// 通过category关键词修正SceneType
const handleSceneType = (data: MonitorInfo, type: 'promiseError' | 'apiError' | 'jsError') => {
  const errorMaps = keywords2sceneTypeMap[type] as { [key: string]: string };
  if (!errorMaps) return data;
  for (const item of Object.keys(errorMaps)) {
    if (typeof data.category === 'string' && data.category.includes(item)) {
      data.sceneType = errorMaps[item];
      break;
    }
  }
  return data;
};

// OBus不上报魔盒接口
export const exceptOBusUrls = ['/digital-food-monitor/monitor/magicBoxSend'];

// 魔盒不上报的接口
export const excepMonitorUrls = [];

// 正则表达式，用以解析堆栈split后得到的字符串
const FULL_MATCH =
  /^\s*at (?:(.*?) ?\()?((?:file|https?|blob|chrome-extension|address|native|eval|webpack|<anonymous>|[-a-z]+:|.*bundle|\/).*?)(?::(\d+))?(?::(\d+))?\)?\s*$/i;

// 限制只追溯10个
const STACKTRACE_LIMIT = 10;

// 解析每一行
function parseStackLine(line: string) {
  const lineMatch = line.match(FULL_MATCH);
  if (!lineMatch) return { line };
  const filename = lineMatch[2];
  const functionName = lineMatch[1] || '';
  const lineno = parseInt(lineMatch[3]!, 10) || undefined;
  const colno = parseInt(lineMatch[4]!, 10) || undefined;
  return {
    filename,
    functionName,
    lineno,
    colno,
    line
  };
}

// 解析错误堆栈
export function parseStackFrames(error: Error) {
  const { stack } = error;
  // 无 stack 时直接返回
  if (!stack) return [];
  const frames = [];
  for (const line of stack.split('\n').slice(1)) {
    const frame = parseStackLine(line);
    if (frame) {
      frames.push(frame);
    }
  }
  return frames.slice(0, STACKTRACE_LIMIT);
}

/**
 * 上报数据
 * @param {any} data 异常数据
 * @returns {any}
 */
const handleExceptionData = (data: MonitorInfo & { currentUrl: string }) => {
  monitorReport.error(data);
};

/**
 * 异常上报
 * @param {any} type:string 异常类型
 * @param {any} args  从当前方法下获取异常数据就传{} 外部获取到的数据就传实际捕获到的异常数据
 * @returns {any}
 */
export const customExceptionReport = {
  /**
   * 使用策略模式定义异常类型
   */
  /**
   * 捕获js语法异常和资源加载异常
   * @param {any} args
   * @returns {any}
   */
  jsErrorInit: () => {
    window.addEventListener(
      'error',
      (event) => {
        event.stopPropagation();
        try {
          const target = event.target ?? event.srcElement;

          /**
           * 捕获常见的js语法异常
           * @param {any} target===window  window全局对象
           * @returns {any}
           */
          if (target === window) {
            const { filename = '-', colno = '-', message = '-', lineno = '-', error } = event;
            const frames = parseStackFrames(error);
            const data: MonitorInfo = {
              category: `${message},filename:${filename},lineno: ${lineno},colno: ${colno}`, //  表示发生错误的标识类型key。
              errorType: 1,
              action: 'js执行出错',
              sceneType: SCENE_TYPE.JS_ERROR,
              msg: {
                filename, // 表示发生错误的文件名或 URL。
                message, // 表示事件的错误消息。
                colno, // 表示发生错误的列号。
                lineno, // 表示发生错误的行号。
                element: 'window', // 元素标签
                // error_type:event.type //异常 类型
                stackTrace: { frames }
              }
            };
            handleSceneType(data, 'jsError');
            const currentUrl = window.location.href;
            handleExceptionData({ currentUrl, ...data });
          }
          /**
           * 静态资源加载（链接 图片，脚本）请求异常
           * @param {any} targetinstanceofHTMLElement&&['LINK'
           * @param {any} 'SCRIPT'
           * @param {any} 'IMG'].indexOf(target.nodeName
           * @returns {any}
           */
          if (target instanceof HTMLElement && ['LINK', 'SCRIPT'].includes(target.nodeName)) {
            // 下载资源失败
            const tagName = target?.nodeName || target.tagName;
            let src;
            if (tagName === 'SCRIPT') {
              src = (target as HTMLScriptElement)?.src;
            } else {
              src = (target as HTMLLinkElement)?.href;
            }
            const data: MonitorInfo = {
              category: `tagName: ${tagName}，${src}加载失败`,
              action: '资源加载失败',
              sceneType: SCENE_TYPE.RESOURCE_LOAD_ERROR,
              errorType: 0,
              msg: {
                src, // 静态资源加载（链接 图片，脚本）请求异常src
                tagName: target?.nodeName || target.tagName, // 元素标签
                // error_type:event.type //请求异常 类型
                html: target.outerHTML,
                html_load_ts: window.__html_load_time,
                time_cost: Math.round(Date.now() - window.__html_load_time)
              }
            };
            handleSceneType(data, 'jsError');
            const currentUrl = window.location.href;
            handleExceptionData({ currentUrl, ...data });
          }
        } catch (error) {
          // todo
          console.log('jsError捕获失败', error);
        }
        return false;
      },
      true
    );
  },
  /**
   * 捕获promise异常
   * @param {any} args
   * @returns {any}
   */
  promiseErrorInit: () => {
    window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
      try {
        const message = event.reason.message || event.reason;
        const type = event.reason.name || 'UnKnowun';
        const data: MonitorInfo = {
          category: `${message}`,
          errorType: 1,
          action: '未捕获的promise',
          sceneType: SCENE_TYPE.PROMISE_ERROR,
          msg: {
            type,
            message, // 表示事件的错误消息。
            stackTrace: event?.reason?.stack // 表示错误的堆栈跟踪。
          }
        };
        // 修正SceneType
        handleSceneType(data, 'promiseError');
        const currentUrl = window.location.href;
        handleExceptionData({ currentUrl, ...data });
      } catch (error) {
        // todo
        console.log('unhandledrejection捕获失败', error);
      }
      return false;
    });
  },
  /**
   * 后端返回接口异常 但是接口200 data中success为false
   * @param {any} args
   * @returns {any}
   */
  requestError: (params: AxiosData) => {
    if (params?.url === '/digital-food-monitor/monitor/magicBoxSend') return;
    if (params?.url === '/region/region_list') return; // 这个接口格式不规范。
    try {
      const { message, code, url, traceId, currentUrl } = params;
      const data: MonitorInfo = {
        category: `接口${url}请求异常，code: ${code}; message: ${message}`,
        errorType: 1,
        traceId,
        action: '请求异常',
        sceneType: SCENE_TYPE.API_ERROR,
        msg: params.msg || {}
      };
      // 过滤不需要上报的code,为了保证信息不丢失，这部分我们 ---> errorType重置为0 这样TT不会告警但是日志平台会留存
      if (excludeErrorCode.includes((params?.code || '').toString())) {
        data.errorType = 0;
      }
      const realCurrentUrl = currentUrl ?? window.location.href;
      // 修正sceneType
      handleSceneType(data, 'apiError');
      handleExceptionData({ currentUrl: realCurrentUrl, ...data });
    } catch (err) {
      console.log('上报请求异常出错:', err);
    }
  }
};
