import request from '@/utils/ajax';

export interface QueryParams {}

interface ResponseError {
  code: string;
  message: string;
}

export interface ResponseData {
  success: boolean;
  error?: ResponseError;
  data?: {
    deviceId: string;
  };
}

const queryDeviceId = async (data?: QueryParams): Promise<string | null | undefined> => {
  return request<QueryParams, ResponseData>('/risk/getDeviceInfo', data)
    .then((res) => {
      const { success, data } = res;
      const deviceId = success ? data?.deviceId : null;
      return deviceId;
    })
    .catch((err) => {
      console.warn('queryDeviceId', err);
      return null;
    });
};

export default queryDeviceId;
