type varType = '--status-bar-height' | '--safe-area-bottom-height';
function getCssVariable(type: varType) {
  // 获取根元素
  const root = document.documentElement;
  // 获取 --status-bar-height/--safe-area-bottom-height  属性的值
  const customProperty = getComputedStyle(root).getPropertyValue(type);
  const height = parseInt(customProperty);
  return height;
}
export { getCssVariable };
