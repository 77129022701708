import { memo, useState, useEffect } from 'react';
import { Button, Image, Toast } from 'antd-mobile';
import { t } from '@/utils/lang';
import { getCookie } from '@/utils/cookie.ts';
import { formatToIndonesianRupiah } from '@/utils/tools/others';
import invertedTriangle from '@/assets/merchant/invertedTriangle.svg';
import positiveTriangle from '@/assets/merchant/positiveTriangle.svg';
import couponDefaultlogo from '@/assets/image/couponDefaultlogo.svg';
import { ProductItem, CouponBatch, HomepageCoupon } from '@/api/merchant/queryCopon';
import { receiveCopon } from '@/api/merchant/receiveCopon';
import useLogin from '@/hooks/useLogin.ts';
import useReport from '@/hooks/useReport';
import ProductCard, { ProductCardProps } from '@/pages/merchant/componets/ProductCard';
import ViewMore from '@/pages/merchant/componets/ViewMore';
import { compressImg } from '@/utils/tools/compressImg';
import styles from './index.module.scss';

interface ProductListProps {
  productList: ProductCardProps['item'][];
  language: string;
  channel_id: string;
  formatToIndonesianRupiah: (price: number) => string;
  login: (fn: () => void, loginParams: loginParams) => void;
  t: (key: string, values?: Record<string, number>) => string;
  activityId: number;
  storeId: string;
}
// 商品列表组件
const ProductList: React.FC<ProductListProps> = ({ productList, channel_id, language, formatToIndonesianRupiah, login, t, activityId, storeId }) => {
  const { report } = useReport();
  useEffect(() => {
    if (!productList.length) return;
    const coupon_array = productList.map((item) => {
      return {
        coupon_id: item?.productId,
        status: item?.purchaseCode === 0,
        product_id: item?.productId
      };
    });
    // 优惠券卡片曝光
    const eventId = 'coupon_card';
    const reportData = {
      coupon_array,
      activity_id: activityId || '',
      coupon_type: 'PRODUCT',
      store_id: storeId
    };
    //上报埋点
    report(eventId, reportData);
  }, [productList]);
  return productList.length > 0 ? (
    <div className={styles.productList}>
      <div className={styles.productTitle}>{t('merchant.productListTitle')}</div>
      <p className={styles.productTip}>{t('orderProcess.changePageTip')}</p>
      {productList.map((item, index) => (
        <ProductCard
          key={index}
          channel_id={channel_id}
          item={item}
          language={language}
          formatToIndonesianRupiah={formatToIndonesianRupiah}
          login={login}
          t={t}
          activityId={activityId}
          storeId={storeId}
          detailId={item.detailId ?? ''}
        />
      ))}
    </div>
  ) : (
    <></>
  );
};

interface CouponCartProps {
  item: CouponBatch;
  language: string;
  activityId: number;
  handleClaim: (coupon: CouponBatch) => void;
  t: (key: string) => string;
  storeId: string;
}
const CouponCart: React.FC<CouponCartProps> = ({ item, language, handleClaim, t }) => {
  return (
    <>
      {/* 左侧图片 */}
      <div className={styles.couponLeft}>
        <Image
          className={styles.couponImg}
          src={item.coupon?.logoUrl ? compressImg(item.coupon?.logoUrl, { width: 80 }) : couponDefaultlogo}
          alt=""
          placeholder={<img src={couponDefaultlogo} alt="" />}
          lazy
        />
      </div>
      {/* 中间文字 */}
      <div className={styles.couponText}>
        <div className={styles.couponName}>{item.coupon?.couponName?.[language] || item.coupon?.couponName?.['id-ID']}</div>
        <div className={styles.couponDescription}>{t('merchant.couponDescription')}</div>
      </div>
      {/* 右侧按钮 */}
      <div className={styles.couponRight}>
        <Button className={styles.couponButton} onClick={() => handleClaim(item)}>
          {t('merchant.claim')}
        </Button>
      </div>
    </>
  );
};

interface CouponProps {
  couponBatchList: CouponBatch[];
  couponMapList: CouponBatch[];
  language: string;
  activityId: number;
  handleClaim: (coupon: CouponBatch) => void;
  t: (key: string) => string;
  storeId: string;
}
// 优惠列表组件
const CouponItem: React.FC<CouponProps> = ({ couponBatchList, couponMapList, language, activityId, handleClaim, t, storeId }) => {
  const { report } = useReport();
  useEffect(() => {
    if (!couponMapList.length) return;
    const coupon_array = couponMapList.map((item) => {
      return {
        coupon_id: item?.batchId,
        status: item?.coupon?.status === 'ON' ? true : false,
        coupon_template_id: item?.coupon?.templateId
      };
    });
    // 优惠券卡片曝光
    const eventId = 'coupon_card';
    const reportData = {
      coupon_array,
      activity_id: activityId || '',
      coupon_type: 'SASS',
      store_id: storeId
    };
    //上报埋点
    report(eventId, reportData);
  }, [couponMapList]);
  return couponBatchList.length > 0 ? (
    <div className={styles.couponMapList}>
      <div className={styles.couponTitle}>{t('merchant.couponBatchListTitle')}</div>
      <div className={styles.couponList}>
        {couponMapList &&
          couponMapList.map((item, index) => (
            <div key={index} className={styles.couponItem}>
              <CouponCart item={item} language={language} activityId={activityId} handleClaim={handleClaim} t={t} storeId={storeId}></CouponCart>
            </div>
          ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

interface CouponListProps {
  productList: ProductItem[];
  couponBatchList: CouponBatch[];
  homepageCouponInfo?: HomepageCoupon;
  updateCoupons: (id: string) => void;
  channel_id: string;
  storeId: string | undefined;
}

interface loginParams {
  sceneType: string;
  fromEventId: string;
}
const CouponList: React.FC<CouponListProps> = ({ productList, channel_id, couponBatchList, homepageCouponInfo, storeId, updateCoupons }) => {
  const [isSeeMoreSetMeal, setIsSeeMoreSetMeal] = useState<boolean>(true);
  const [isSeeMoreFavorable, setIsSeeMoreFavorable] = useState<boolean>(true);
  const language = getCookie('language') || window.navigator.language || 'id-ID';
  const { report } = useReport();

  // 判断当前是否登录
  const { isLogin } = useLogin();
  const { activityId } = homepageCouponInfo ?? {};
  const login = async (fn: () => void, loginParams: loginParams) => {
    if (!isLogin) {
      // 未登录的时候，先登录
      const login = (await import('@/components/login/login')).default;
      const activityRes = await login(loginParams);
      if (activityRes === 'account') {
        // 登录后，触发事件
        fn();
      } else {
        // 登录失败
        console.log('login failed');
      }
    } else {
      // 已登录的时候，触发事件
      fn();
    }
  };

  // 领取优惠券
  const claimCoupon = async (couponInfo: CouponBatch) => {
    const resp = await receiveCopon({ couponBatchId: couponInfo.batchId, couponTemplateIds: [couponInfo.coupon!.templateId!], activityId });
    if (resp.success) {
      Toast.show({ content: resp.error?.message ?? t('merchant.receiveSucc') });
    } else {
      Toast.show({ content: resp.error?.message ?? t('merchant.receiveFail') });
    }
    updateCoupons(storeId!);
  };
  // 领取
  const handleClaim = (couponInfo: CouponBatch) => {
    // 优惠券卡片领取按钮点击
    const eventId = 'coupon_card_claim_btn';
    const reportData = {
      type: 'click',
      activity_id: activityId || '',
      coupon_id: couponInfo?.batchId,
      coupon_type: 'SASS',
      status: couponInfo?.coupon?.status === 'ON' ? true : false,
      coupon_template_id: couponInfo?.coupon!.templateId,
      btn_text: '领取',
      store_id: storeId
    };
    //obus上报
    report(eventId, reportData);
    const loginParams = { sceneType: '优惠券卡片领取按钮点击', fromEventId: 'coupon_card_claim_btn' };
    login(() => {
      claimCoupon(couponInfo);
    }, loginParams);
  };

  // 查看更多商品按钮事件
  const handleSeeMoreSetMeal = () => {
    setIsSeeMoreSetMeal(!isSeeMoreSetMeal);
  };
  // 查看更多优惠按钮事件
  const handleSeeMoreFavorable = () => {
    setIsSeeMoreFavorable(!isSeeMoreFavorable);
  };

  const seeMoreSetMealVisible = productList.length > 6;
  const productMapList = isSeeMoreSetMeal ? productList?.slice(0, 6) : productList;
  const seeMoreFavorableVisible = couponBatchList.length > 6;
  const couponMapList = isSeeMoreFavorable ? couponBatchList?.slice(0, 6) : couponBatchList;

  return (
    <div className={styles.homePageList}>
      <ProductList
        productList={productMapList}
        channel_id={channel_id}
        language={language}
        formatToIndonesianRupiah={formatToIndonesianRupiah}
        login={login}
        t={t}
        activityId={activityId!}
        storeId={storeId ?? ''}
      />
      {/* 展开按钮 */}
      {seeMoreSetMealVisible && (
        <ViewMore
          isExpanded={isSeeMoreSetMeal}
          onToggle={handleSeeMoreSetMeal}
          textExpanded={t('merchant.seeMore')}
          textCollapsed={t('merchant.hide')}
          triangleUp={invertedTriangle}
          triangleDown={positiveTriangle}
        />
      )}
      {/* 优惠列表 */}
      <CouponItem
        couponBatchList={couponBatchList}
        couponMapList={couponMapList}
        language={language}
        handleClaim={handleClaim}
        t={t}
        activityId={activityId!}
        storeId={storeId ?? ''}
      />
      {/* 查看更多优惠 */}
      {seeMoreFavorableVisible && (
        <ViewMore
          isExpanded={isSeeMoreFavorable}
          onToggle={handleSeeMoreFavorable}
          textExpanded={t('merchant.seeMore')}
          textCollapsed={t('merchant.hide')}
          triangleUp={invertedTriangle}
          triangleDown={positiveTriangle}
        />
      )}
    </div>
  );
};

export default memo(CouponList);
