import { Dialog, Toast } from 'antd-mobile';
import { memo, useMemo } from 'react';
import cls from 'classnames';
import { useMemoizedFn } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { HomepageStoreInfo, StoreSocialMedia } from '@/api/merchant/queryHomepageStoreInfo';
import { t } from '@/utils/lang';
import copyText from '@/utils/tools/copyText';
import WHATS_APP from '@/assets/merchant/whatsapp.svg';
import INSTAGRAM from '@/assets/merchant/ins.svg';
import FACEBOOK from '@/assets/merchant/facebook.svg';
import YOUTUBE from '@/assets/merchant/youtube.svg';
import TIKTOK from '@/assets/merchant/tiktok.svg';
import WECHAT from '@/assets/merchant/wechat.svg';
import qinzheng from '@/assets/qinzheng.webp';
import rightArrow from '@/assets/common/rightArrow.svg';
import contact from '@/assets/merchant/contact.svg';
import copy from '@/assets/merchant/copy.svg';
import locationBlue from '@/assets/merchant/locationBlue.svg';
import { toOpenDeepLink, toOpenWebView } from '@/utils/tools/jumpLink';

const SocialApps = (props: { storeSocialMediaList: StoreSocialMedia[] }) => {
  const { storeSocialMediaList = [] } = props;
  // useMount(() => { // todo 埋点
  //   const app_name = storeSocialMediaList?.map((item) => item.mediaName)?.join(';');
  //   if (app_name) report.social_media_portal(app_name);
  // });
  const apps = ['WHATS_APP', 'INSTAGRAM', 'FACEBOOK', 'YOUTUBE', 'TIKTOK', 'WECHAT'];
  const appIcon = {
    WHATS_APP: WHATS_APP,
    INSTAGRAM: INSTAGRAM,
    FACEBOOK: FACEBOOK,
    YOUTUBE: YOUTUBE,
    TIKTOK: TIKTOK,
    WECHAT: WECHAT
  };
  // const allValidApps = storeSocialMediaList.map((item) => item.mediaName);
  const validApps: StoreSocialMedia[] = [];
  apps.forEach((appType) => {
    const app = storeSocialMediaList?.find((app) => app.mediaName === appType);
    if (app) {
      validApps.push(app);
    }
  });
  function clickIcon(app: StoreSocialMedia) {
    // report.social_media_portal_btn_click(app.mediaName);
    let href;
    if (app.mediaName === 'WECHAT') {
      Dialog.confirm({
        title: 'WECHAT',
        content: app.mediaInfo,
        confirmText: t('messages.copy'),
        cancelText: t('merchant.cancel'),
        onConfirm: () => {
          copyText(app.mediaInfo);
          Toast.show({ content: t('merchant.copySuccess') });
        }
      });
      return;
    } else if (app.mediaName === 'WHATS_APP') {
      href = `https://wa.me/${app.mediaInfo}`;
    } else {
      href = app.mediaInfo;
    }
    toOpenDeepLink(href);
    // toOpenWebView(href, { showToolbar: false, showURL: false });
  }
  if (!validApps?.length) return null;
  return (
    <div className={styles.apps}>
      {validApps.map((app) => {
        return <img src={appIcon[app.mediaName as keyof typeof appIcon]} alt={app.mediaName} key={app.mediaName} onClick={() => clickIcon(app)} />;
      })}
    </div>
  );
};
interface Props {
  storeInfo: HomepageStoreInfo;
  language: string;
  channel_id: string;
}
const CardInfo = (props: Props) => {
  const navigate = useNavigate();
  const { storeInfo, channel_id, language } = props;
  const {
    storeName,
    halalRestaurant,
    dayPartTimeList,
    storeMobile,
    storeAddress,
    mapLinkAddress,
    storeSocialMediaList = [],
    mallInfo,
    storeDistance
  } = storeInfo;
  const { mallId, mallName, floorList, locationRemark } = mallInfo || {};
  const curLanguageLocationRemark = locationRemark?.[(language as keyof typeof locationRemark) || 'id-ID'];
  const { businessStatus, timesStr } = useMemo(() => {
    // 从中过滤 今天(周几)的营业时间。 渠道展示顺序 堂食 》 外卖 》 自取
    const currentWeekDay = new Date().getDay() === 0 ? 7 : new Date().getDay(); // 0 为周天 后端返回为7
    // 今天的所有渠道营业时间
    const currentDayTimeList =
      dayPartTimeList?.filter((timeItem) => {
        return +timeItem.dayPartCode === currentWeekDay;
      }) ?? [];
    const dineInTimeList = currentDayTimeList?.filter((item) => +item.channelCode === 3);
    const deliveryTimeList = currentDayTimeList?.filter((item) => +item.channelCode === 1);
    const pickupTimeList = currentDayTimeList?.filter((item) => +item.channelCode === 2);
    let resultTimeItems;
    if (dineInTimeList?.length) {
      resultTimeItems = dineInTimeList;
    } else if (deliveryTimeList?.length) {
      resultTimeItems = deliveryTimeList;
    } else {
      resultTimeItems = pickupTimeList;
    }
    const times = resultTimeItems?.map((resultTimeItem) => `${resultTimeItem?.startTime}-${resultTimeItem?.endTime}`) || [];
    const timesStr = times.join(' ');
    const isBusinessHours = resultTimeItems[0]?.isBusinessHours;
    return {
      businessStatus: isBusinessHours ? t('merchant.open') : t('merchant.closed'),
      timesStr
    };
  }, [dayPartTimeList]);
  const floor = floorList?.map((floor) => floor?.floorName).join('-') || '';

  const clickAddress = useMemoizedFn(() => {
    // let addr_type;
    if (mapLinkAddress) {
      // addr_type = 1;
      // report.restaurant_addr_btn_click(addr_type);
      toOpenWebView(mapLinkAddress, { showToolbar: false, showURL: false });
    } else {
      // addr_type = 2;
      // report.restaurant_addr_btn_click(addr_type);
      copyText(storeAddress!);
      Toast.show({ content: t('messages.copySuccess'), duration: 1500 });
    }
  });

  const toMallDetail = useMemoizedFn(() => {
    navigate(`/platform/mall?id=${mallId}`);
  });

  const contactReport = () => {
    // report.contact_restaurant_btn_click();
  };

  // 线下铺码进入 不展示地址
  const channelVisible = channel_id !== '6';

  // 商场信息展示判断逻辑：商场id、门店地址、地图链接 任意一个不为空时展示门店信息
  const storeInfoShow = Boolean(mallId || storeAddress || mapLinkAddress);

  return (
    <div className={styles.cardInfo}>
      {/* 门店名称 */}
      <div className={cls(styles.topWrap, { [styles.pb12!]: !storeInfoShow && !storeSocialMediaList?.length })}>
        <div className={styles.topItem}>
          <span className={styles.storeName}>{storeName}</span>
          {halalRestaurant === '1' && <img src={qinzheng} className={styles.qinzhen} />}
        </div>
        {/* 营业状态及时间 */}
        <div className={styles.businessHoursItem}>
          <span className={styles.businessHours}>
            <span className={styles.leftText}>{businessStatus} </span>
            {timesStr}
          </span>
          {storeMobile && (
            <a href={`tel:${storeMobile}`} type="tel" onClick={contactReport} className={styles.contactLink}>
              <img src={contact} className={styles.rightIcon} />
            </a>
          )}
        </div>
        {storeInfoShow && <div className={styles.divide} />}
      </div>
      {channelVisible && (
        <>
          {/* 门店信息 */}
          {storeInfoShow && (
            <div className={styles.centerWrap}>
              <div className={styles.addressInfoItem}>
                {/* 商场信息 */}
                <div className={styles.addressInfo}>
                  {!!mallName && (
                    <div className={styles.mall} onClick={toMallDetail}>
                      <span className={styles.mallName}>{`${mallName}`}</span>
                      <img src={rightArrow} className={styles.rightArrowIcon} />
                    </div>
                  )}
                  <div className={styles.storeLocation}>
                    {floor && curLanguageLocationRemark
                      ? `${floor} · ${curLanguageLocationRemark}`
                      : floor || curLanguageLocationRemark || storeAddress}
                  </div>
                </div>

                {!mapLinkAddress ? (
                  // 复制
                  <div className={cls(styles.map, styles.copyWrap)}>
                    {!!storeAddress && <img src={copy} className={styles.rightIcon} onClick={clickAddress} />}
                  </div>
                ) : (
                  // 定位
                  <div className={cls(styles.map, { [styles.aICenter!]: !storeDistance })} onClick={clickAddress}>
                    <div className={styles.mapBox}>
                      {!!storeDistance && <div className={styles.bubble}>{storeDistance ? `${storeDistance}Km >` : ' '}</div>}
                      <img src={locationBlue} className={styles.rightIcon1} onClick={clickAddress} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* 社交媒体*/}
          {!!storeSocialMediaList?.length && (
            <div className={styles.footerWrap}>
              <div className={cls(styles.divide, styles.mb12)} />
              <SocialApps storeSocialMediaList={storeSocialMediaList}></SocialApps>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default memo(CardInfo);
