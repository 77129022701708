import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { goLogin, getTokenInfo, setTokenInfo } from '../../login/login';
import request from '../index';
import { clearAccessTokenConfig } from '../config/configAction';

const url = `/account/refreshToken`;
interface ExtraInternalAxiosRequestConfig extends InternalAxiosRequestConfig {
  ignoreResInterceptor?: boolean;
}
const withResolvers = <T>() => {
  let resolve: (value: T | PromiseLike<T>) => void = () => {};
  let reject: (reason?: unknown) => void = () => {};

  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return {
    promise,
    resolve,
    reject
  };
};

// 添加响应拦截器
axios.interceptors.response.use(
  (response) => {
    const { promise, resolve } = withResolvers<AxiosResponse>();
    if ((response?.config as ExtraInternalAxiosRequestConfig)?.ignoreResInterceptor) {
      return response;
    }

    const config = response?.config || {};

    const requestApi = () => {
      request(config.url!, JSON.parse(config.data), { ignoreResInterceptor: true, method: 'POST' }).then((res) => {
        const fullResponse: AxiosResponse = {
          data: res,
          status: 200,
          statusText: 'OK',
          headers: {},
          config: response.config,
          request: null // This is usually the XHR object, but we don't need it here
        };
        return resolve(fullResponse);
      });
    };

    const login = (sceneType = '登录态过期') => {
      void goLogin(`站外异常状态: ${sceneType}`).finally(requestApi);
    };

    // 在接收到响应数据之前的处理
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!response?.data?.success) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      switch (response?.data?.error?.code) {
        case '0310204':
          // 0310204 accessToken已过期
          doRequest(response?.config)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((res: any) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (res?.status === 200) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                return resolve(res);
              }
              requestApi();
            })
            .catch(requestApi);

          return promise;
        case '0310205':
        case '0310206':
          // 0310205 refreshToken已过期
          // 0310206 生成token失败
          clearAccessTokenConfig();
          login();
          return promise;
        case '0310218':
          // 0310218 用户已被冻结
          // eslint-disable-next-line no-case-declarations
          const configUrl = response?.config?.url || '';
          // 已冻结状态账号登陆被拦截时，对应Toast提示
          if (configUrl.includes('checkSlider') || configUrl.includes('sendCode')) {
            return response;
          }
          clearAccessTokenConfig();
          login('用户已被冻结');
          return promise;
        case '0310202':
          clearAccessTokenConfig();
          login('Token非法');
          return promise;
        default:
          break;
      }
    }
    return response;
  },
  (error) => {
    // 对响应错误的处理
    console.error('拦截到错误:****', error);
    return Promise.reject(error);
  }
);

type RefreshTokenPromiseType = Promise<{
  success: boolean;
  data: { accessToken: string; refreshToken: string; userId: string };
  error: { code: string; message: string };
}> | null;
// refreshToken出发的请求
let refreshTokenPromise: RefreshTokenPromiseType = null;
async function doRequest(config: InternalAxiosRequestConfig) {
  try {
    // 没有发起refreshToken
    console.log('查看refreshTokenPromise的状态', refreshTokenPromise);
    if (!refreshTokenPromise) {
      const { refreshToken } = getTokenInfo() || { refreshToken: '' };
      //调刷新token的接口
      refreshTokenPromise = request(url, { refreshToken, identityType: 'C', cpType: 'qpon' }, { ignoreResInterceptor: true, method: 'POST' });
    }
    const newTokenRes: {
      success: boolean;
      data: { accessToken: string; refreshToken: string; userId: string };
      error: { code: string; message: string };
    } = await refreshTokenPromise;

    if (newTokenRes?.success) {
      // 第一次拿到结果
      if (refreshTokenPromise) {
        // 需要重置状态
        refreshTokenPromise = null;
        // 获取新token后设置到请求头并保存在本地
        setTokenInfo({
          accessToken: newTokenRes?.data?.accessToken,
          refreshToken: newTokenRes?.data?.refreshToken,
          userId: newTokenRes?.data?.userId
        });
      }
      //刷新token后重新发起上次请求
      const res: { success: boolean } = await request(config.url!, JSON.parse(config.data), { ignoreResInterceptor: true, method: 'POST' });
      if (res?.success) {
        return { status: 200, data: res, config };
      } else {
        // clearAccessTokenConfig();
        // await goLogin('站外异常状态: 登录态过期');
        return { data: res };
      }
    } else {
      // 需要重置状态
      refreshTokenPromise = null;
      console.log('newTokenRes?.success', newTokenRes);
      clearAccessTokenConfig();
      await goLogin('站外异常状态: 登录态过期');
      return { data: newTokenRes };
    }
  } catch (err) {
    // 需要重置状态
    refreshTokenPromise = null;
    console.log('刷新接口的catch进来', err);
    // 重新获取actoken失败，跳转至登录页
    clearAccessTokenConfig();
    await goLogin('站外异常状态: 登录态过期');
    return err;
  }
}
