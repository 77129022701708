import axios from 'axios';
import { MonitorParams } from '@/utils/monitor/monitorReport';
import { getBaseUrl } from "@/utils/ajax";

interface ResponseError {
  code: string;
  message: string;
}

export interface ResponseData {
  success: boolean;
  error?: ResponseError;
  data?: unknown;
}
const postMonitorReport = async (data: MonitorParams): Promise<ResponseData> => {
  // const res = await ajax<MonitorParams, ResponseData>('/digital-food-monitor/monitor/magicBoxSend', data)
  // 不从ajax走是为了headers处理方便 并且该接口本身不用被ajax中的监控和api性能捕获
  const baseUrl = getBaseUrl();
  const config = {
    baseURL: baseUrl || 'https://qpon-food-gl.qponmobile.com',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  };
  const requestId = Date.now().toString(36) + Math.random().toString(36).substr(2, 5);
  const res = await axios({
    ...config,
    url: '/digital-food-monitor/monitor/magicBoxSend',
    data: {
      ...data,
      requestId
    }
  }).catch((error) => {
    console.error('[POST-MONITOR-H5][ERROR]', error);
    return error;
  });
  return res;
};

export default postMonitorReport;
