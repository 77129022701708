import { useCallback, useState, useLayoutEffect } from 'react';
import { useMemoizedFn } from 'ahooks';
import { LOCAL_KEY, LocalData, storage } from '@/utils/tools';
import { logout } from '@/api/login/queryLogin';
import { clearTokenInfo } from '@/utils/login/login';
import { clearAccessTokenConfig } from '@/utils/ajax/config/configAction';

// 登陆类型，账号 |未登陆
export type LoginType = 'account' | '';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getLoginType = (params?: { tokenInfo?: any; guest?: Record<string, number> }): LoginType => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const info = params?.tokenInfo || storage.getLocalStorage(LOCAL_KEY.TOKEN_INFO);
  return info ? 'account' : '';
};

// 服务端登录相关配置
const loginConfig = {
  loginType: getLoginType()
};

const useLogin = () => {
  // 当前登陆状态类型
  const [loginType, setLoginType] = useState<LoginType>(loginConfig.loginType);
  // 是否已经登录 账号登录，有游客身份都算登录
  const isLogin = !!loginType;

  /** 设置登录态信息 */
  const setTokenInfo = useCallback((tokenInfo: LocalData[LOCAL_KEY.TOKEN_INFO]) => {
    storage.setLocalStorage(LOCAL_KEY.TOKEN_INFO, tokenInfo, true);
    setLoginType('account');
  }, []);

  /** 更新登录状态 */
  const updateLoginType = useMemoizedFn((params?: { tokenInfo?: string; guest?: Record<string, number> }) => {
    const newType = getLoginType(params);
    setLoginType(newType);
    loginConfig.loginType = newType;
  });

  // 监听localStorage变化
  const handleLocalChange = useMemoizedFn((e: StorageEvent) => {
    switch (e.key as LOCAL_KEY) {
      // 监听token变化更新登录态
      case LOCAL_KEY.TOKEN_INFO:
        updateLoginType({ tokenInfo: e.newValue || '' });
        break;
      // 更新游客登录态
      case LOCAL_KEY.GUEST_INFO:
        updateLoginType({ guest: JSON.parse(e.newValue || '{}') });
        break;
      default:
        break;
    }
  });

  // 监听storage变化
  const handleStorageChange = useCallback(
    (e: StorageEvent) => {
      switch (e.storageArea) {
        case localStorage:
          handleLocalChange(e);
          break;
        default:
          break;
      }
    },
    [handleLocalChange]
  );

  //退出登录
  const toLogout = () => {
    // 返回一个Promise
    return new Promise((resolve, reject) => {
      // 假设 logout() 是一个返回 Promise 的异步函数
      logout()
        .then((res) => {
          const { data, success, error } = res;
          if (success) {
            // 清除请求头相关token配置
            clearAccessTokenConfig();
            // 清除localStorage的token信息
            clearTokenInfo();
            // 登出成功后可以通知业务逻辑进行刷新
            resolve(data);
          } else {
            // 如果有错误码，抛出错误让外部捕获
            reject(error);
          }
        })
        .catch((error) => {
          // 捕捉logout()可能抛出的异常
          reject(error);
        });
    });
  };

  // 初始化
  const init = useCallback(() => {
    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('storageUpdate', handleStorageChange as EventListener);
    // 卸载
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('storageUpdate', handleStorageChange as EventListener);
    };
  }, [handleStorageChange]);

  useLayoutEffect(init, [init]);

  return {
    /** 登录配置信息，不会更新render */
    loginConfig,
    isLogin,
    loginType,
    setTokenInfo,
    toLogout
  };
};

export default useLogin;
