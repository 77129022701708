import { memo } from 'react';
import cls from 'classnames';
import styles from './index.module.scss';

interface Props {
  discountPercent: number;
  isActivity?: boolean;
}

const DiscountLabel: React.FC<Props> = ({ discountPercent, isActivity = true }) => {
  return (
    <div className={styles.discountDegree}>
      <div className={cls(styles.discountBox, isActivity ? styles.activityBackColor : styles.loseBackColor)}>
        <div className={cls(styles.discountDegreeText, isActivity ? styles.activityColor : styles.loseColor)}>{discountPercent}%</div>
      </div>
      <div className={cls(styles.discountTriangle, isActivity ? styles.discountTriangleActivity : styles.discountTrianglelose)}></div>
    </div>
  );
};
export default memo(DiscountLabel);
