/* eslint-disable @typescript-eslint/no-explicit-any */
import webSee from '@websee-food/core';
import React, { FC } from 'react';
import styles from './index.module.scss';
import errorIcon from './error-icon.svg';
import { t } from '@/utils/lang';

type Props = {
  defaultImgUrl?: string;
  defaultImgText?: string;
};

const Error: FC<Props> = React.memo((props) => {
  const { defaultImgUrl, defaultImgText } = props;
  return (
    <>
      {defaultImgText && (
        <div className={styles['error-wrap']}>
          <div className={styles['error-content']}>
            <img src={defaultImgUrl} className={styles['error-content-img']} />
            <p className={styles['error-content-text']}>{defaultImgText}</p>
            <button onClick={() => window.location?.reload()}>{t('flashDeal.refresh')}</button>
          </div>
        </div>
      )}
    </>
  );
});

export default class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }
  componentDidCatch(error: any) {
    // 在componentDidCatch中将报错上报给服务器
    webSee.errorBoundary(error);
  }
  render() {
    if (this.state.hasError) {
      return <Error defaultImgText={'加载出了点小错误'} defaultImgUrl={errorIcon} />;
    }
    return this.props.children;
  }
}
