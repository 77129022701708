export enum frontEnvList {
  dev = 'dev',
  test = 'test',
  prod = 'prod',
  pre = 'pre'
}
export const frontEnv: frontEnvList = import.meta.env.VITE_BUILD_ENV as frontEnvList;
export const backEnv: string = import.meta.env.VITE_BACKEND_HOST as string;
export const isDev = frontEnv === frontEnvList.dev;
export const isTest = frontEnv === frontEnvList.test;
export const isProd = frontEnv === frontEnvList.prod;
export const isPre = frontEnv === frontEnvList.pre;
