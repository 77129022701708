import { memo } from 'react';
import cls from 'classnames';
import styles from './index.module.scss';

interface Props {
  imgIcon: string;
  descText: string;
  isActivity?: boolean;
}
const LabelType: React.FC<Props> = ({ imgIcon, descText, isActivity = true }) => {
  return (
    <div className={styles.productLabel}>
      <img src={imgIcon} alt="" />
      <div className={cls(styles.text, isActivity ? styles.activityColor : styles.loseColor)}>{descText}</div>
    </div>
  );
};
export default memo(LabelType);
