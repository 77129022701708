import { MutableRefObject } from 'react';

type TargetValue<T> = T | undefined | null;

export type TargetType = HTMLElement | Element | Window | Document;

export type BasicTarget<T extends TargetType = Element> = (() => TargetValue<T>) | TargetValue<T> | MutableRefObject<TargetValue<T>>;

export function getTargetElement<T extends TargetType>(target: BasicTarget<T>) {
  let targetElement: TargetValue<T>;

  if (typeof target === 'function') {
    targetElement = target();
  } else if (target && 'current' in target) {
    targetElement = (target as MutableRefObject<TargetValue<T>>).current;
  } else {
    targetElement = target;
  }

  return targetElement;
}
