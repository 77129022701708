import { Toast } from 'antd-mobile';
import { useAsyncEffect } from 'ahooks';
import { memo, useState } from 'react';
import styles from './index.module.scss';
import RecommondFoodImage from './RecommondFoodImage';
import { queryRecommondMenuList, RecommendMenu } from '@/api/merchant/queryRecommondMenuList';
import { t } from '@/utils/lang';

interface Props {
  language: string;
  availableOrderFoodType: string;
  // pageRenderStatus: PAGE_RENDER_STATUS;
  storeId: string;
  cpType: string;
  // [key: string]: any;
}
const RecommondFoods = (props: Props) => {
  const [recommondsMenus, setRecommondsMenus] = useState<RecommendMenu[]>([]);
  const { language, availableOrderFoodType, storeId, cpType } = props;
  const queryRecommondMenuInfo = async () => {
    const res = await queryRecommondMenuList({ storeId, cpType, orderFoodType: availableOrderFoodType });
    if (res.success) {
      setRecommondsMenus(res.data ?? []);
    } else {
      Toast.show({ content: res.error?.message ?? t('messages.netWorkFail') });
    }
  };
  useAsyncEffect(async () => {
    await queryRecommondMenuInfo();
  }, []);

  // useEffect(() => { // todo 埋点
  //   if (recommondsMenus?.length) return report.recommended_dish_module_view(recommondsMenus.length);
  // }, [recommondsMenus]);
  // const clickFood = () => {
  //   report.recommended_dish_module_sliding_screen_btn_click(recommondsMenus?.length);
  // };
  if (!recommondsMenus?.length) return null;
  return (
    <div className={styles.recommondFoods}>
      <div className={styles.title}>{t('merchant.recommended')}</div>
      <div className={styles.recommondFoodsList}>
        {recommondsMenus.map((item) => (
          <div className={styles.slider} key={item.productId}>
            <RecommondFoodImage thumbnailUrl={item.thumbnailUrl} imageUrl={item.imageUrl} />
            <div className={styles.wrap}>
              <span className={styles.productName}>{`${item.productNameMap?.[language] || ''}`}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(RecommondFoods);
