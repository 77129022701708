import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { customExceptionReport } from '../monitor/exceptionReport';
import axiosConfig from './config/baseConfig';
import { safeJsonParse } from './tool';
import './interceptor/interceptors';
import './interceptor/bspwasHeadersRequestInterceptor';
import './interceptor/computeHeadersRequestInterceptor';
import { CommonResponseData } from '@/types/common';
import { BASE_PATH } from '@/utils/ajax/config/config.ts';
import { backEnv, isDev, isTest } from '@/utils/env/index.ts';

// todo 将告警和格式转换迁移到返回拦截器
/**
 * url:请求接口的url
 * data: 请求传入参数
 * axiosOpts：其他的请求配置 比如请求方式POST GET等 默认POST
 */
export interface ExtraAxiosRequestConfig extends AxiosRequestConfig {
  ignoreResInterceptor?: boolean;
}

/**
 * 获取预热接口数据
 */
export const preRequest = (url) => {
  const key = encodeURIComponent(BASE_PATH + url);
  const val = localStorage.getItem(key);
  return JSON.parse(val);
};

/**
 * 获取请求后端接口的baseUrl
 */
export function getBaseUrl(): string {
  if (isDev || isTest) {
    const arr = backEnv.split(',');
    return localStorage.getItem('curBaseUrl') || arr[0];
  } else {
    return backEnv || 'https://qpon-food-gl.qponmobile.com';
  }
};

const request = async <T, K>(url: string, data?: T, axiosOpts?: ExtraAxiosRequestConfig): Promise<K> => {
  return await axios<K>({
    ...axiosConfig,
    url,
    data: data,
    method: 'POST',
    ...axiosOpts
  })
    .then((res) => {
      const request_time_end = new Date().getTime();
      //axios成功（包含接口成功及失败 接口返回success：true/false 数据return出去交给业务处理
      //接口成功 return的数据格式为{success: true, data: {}, error: null}
      //接口失败 return的数据格式为{success: false, data: null, error: {code: '对应错误码', message: '对应message'}}

      // 请求错误监控
      const responseData = res.data as CommonResponseData<unknown>; // 这个responseData是给监控用
      const requestParams = safeJsonParse(res.config.data || '');
      const traceId = requestParams.requestId;
      const request_duration = request_time_end - requestParams.timestamp;
      if (!responseData.success) {
        // 业务异常
        // 不成功上报
        customExceptionReport.requestError({
          url,
          code: responseData.error?.code || '',
          message: responseData?.error?.message || '接口返回异常',
          traceId: traceId,
          msg: {
            req: requestParams as object,
            res: responseData?.error || {}
          }
        });
      }
      // 添加接口耗时
      return Array.isArray(res.data) ? res.data : { ...res.data, request_duration };
    })
    .catch((error: AxiosError) => {
      //网络错误或其他异常处理400 500等异常抛出去的格式和业务接口异常不一样 这里只抛{code: '系统异常码', message: '系统异常message'}
      const errorResponse = error.response;
      const code = errorResponse?.status || error.code || 0;
      const message = errorResponse?.statusText || error.message;
      const requestParams = safeJsonParse(error?.config?.data || '');

      // 请求错误监控
      customExceptionReport.requestError({
        url,
        code: code as string,
        message: `code返回${code},message: ${message}`,
        traceId: requestParams.requestId,
        msg: {
          req: requestParams as object,
          extraAxiosRes: errorResponse
        }
      });
      // 还是恢复返回这种格式，业务方根据success判断
      return {
        data: null,
        success: false,
        error: {
          code,
          message
        }
      };
    });
};

export default request;
