import { LanguageSdk } from '@qpon-design/i18';

export type Values = { [key: string]: string | number | boolean | undefined | null };

export function loadLanguage() {
  LanguageSdk.loadLanguage();
}

export function setLanguage(lang: string) {
  LanguageSdk.setLanguage(lang);
}

export function getLanguage() {
  return LanguageSdk.getLanguage();
}

export function t(key: string, values?: Values) {
  return LanguageSdk.t(key, values);
}
