import { createSlice } from '@reduxjs/toolkit';
import { MultiLanguage } from '@/api/lottery/query';
import { getLanguage } from '@/utils/lang';

export interface GlobalLanguageState {
  value: keyof MultiLanguage | '';
}

const initialState: GlobalLanguageState = {
  value: getLanguage() as keyof MultiLanguage | ''
};

export const languageDishesState = createSlice({
  name: 'globalLanguage',
  initialState,
  reducers: {
    changeLanguage: (state, action) => {
      state.value = action.payload;
    }
  }
});
export const { changeLanguage } = languageDishesState.actions;
// export const getLanguage = (state: RootState) => state.language.value;

export default languageDishesState.reducer;
