/* eslint-disable max-nested-callbacks */
import React, { Suspense, useImperativeHandle, useState } from 'react';
import { CenterPopup } from 'antd-mobile';
import styles from './index.module.scss';

const Content = React.lazy(() => import('./Content'));

interface Props {}

export interface ScanPopUpHandler {
  setVisible: (visible: boolean) => void;
}
const ScanPopUp = React.forwardRef<ScanPopUpHandler, Props>((props, ref) => {
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    setVisible
  }));

  return (
    <CenterPopup
      destroyOnClose
      className={styles.popup}
      visible={visible}
      onMaskClick={() => {
        setVisible(false);
      }}
    >
      <Suspense>
        <Content setVisible={setVisible}></Content>
      </Suspense>
    </CenterPopup>
  );
});

export default ScanPopUp;
