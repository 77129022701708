// sessionStorage key注册， 注意看下面函数的SessionData[K] 是否标红，类型检测标红 SESSION_KEY 的值 和 SessionData 的 key 不一致
export enum SESSION_KEY {
  LOGIN_TIME = 'login_time', // 登录验证码过期时间
  SESSION_ID = 'sessionId',
  STORE_ID = 'storeId',
  CHANNEL_ID = 'channel_id',
  SOURCE_TAG = 'source_tag',
  user_equipment = 'userEquipment', //全搜团队带过来的用户参数
  RED_PACKET_VISIBLE = 'redPacketVisible', // 平台首页红包弹窗是否显示
  VIEW_TIME = 'view_time', //页面访问性能view_time
  INIT_RECOMMEND_FLAG = 'init_recommend_flag', // 初始推荐标识
  POSITION_VISIBLE = 'positionVisible', // s首页定位气泡显示
  HOMEPAGE_LIST_REPORT = 'homepage_list_report', // 标识，是否需要请求列表接口获取上报数据
  HAS_LISTENER_DEEPLINK = 'has_listener_deepLink' // 是否已经初始化deeplink监听
}
/**
 * 设置 storageUpdate 事件，以监听 storage 变化
 * @param key
 * @param storageArea
 * @param newValue
 */
function storageUpdateEvent(key: string, storageArea: Storage, newValue: string | null) {
  const event = new Event('storageUpdate');
  Object.assign(event, { key, newValue, storageArea });
  window.dispatchEvent(event);
}

/**
 * 获取本地Storage储存的某值
 *
 * @since v0.0.1
 * @param {string} key
 * @param {boolean} isJson
 * @returns {*} Returns 获取到的值，获取不到返回null
 */
function getStorage(type: 'localStorage' | 'sessionStorage', key: string, isJson?: boolean) {
  if (typeof window === 'undefined') return;
  const storage = type === 'localStorage' ? window.localStorage : window.sessionStorage;
  try {
    const data = storage.getItem(key);
    if (isJson) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return data ? JSON.parse(data) : null;
    }

    return data;
  } catch (error) {
    return null;
  }
}

/**
 * 设置Storage
 *
 * @since v0.0.1
 * @param {string} key 属性名
 * @param {string} value 值
 * @param {boolean} isJson 是否是对象
 * @returns {*} Returns the result of `func`.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function setStorage(type: 'localStorage' | 'sessionStorage', key: string, value: any, isJson?: boolean) {
  if (typeof window === 'undefined') return;
  const storage = type === 'localStorage' ? window.localStorage : window.sessionStorage;
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const setValue = isJson ? JSON.stringify(value) : value;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    storage.setItem(key, setValue);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    storageUpdateEvent(key, storage, setValue);
  } catch (error) {
    console.error('[setItem][error]', error);
  }
}

/**
 * 清空Storage 不支持全部清空
 *
 * @since v0.0.1
 * @param {string} key 属性名
 * @returns {*} Returns the result of `func`.
 */

function removeStorage(type: 'localStorage' | 'sessionStorage', key: string) {
  if (typeof window === 'undefined') return;
  const storage = type === 'localStorage' ? window.localStorage : window.sessionStorage;
  try {
    storage.removeItem(key);
    storageUpdateEvent(key, storage, null);
  } catch (error) {
    console.error('[setItem][error]', error);
  }
}

// localStorage key注册， 注意看下面函数的 LocalData[K] 是否标红，类型检测标红 LOCAL_KEY 的值 和 LocalData 的 key 不一致
export enum LOCAL_KEY {
  DEVICE_ID = 'deviceId', // 设备Id
  QPON_DEVICE_ID = 'qpon_device_id', //排查丢失问题新加qpon_device_id
  QPON_SESSION_ID = 'qpon_session_id',
  TOKEN_INFO = 'tokenInfo',
  GUEST_INFO = 'guestInfo',
  POPOVER_VISIBLE = 'popoverVisible', // 商店首页气泡显示
  SEARCH_DISHES_HISTORY = 'searchDishesHistory', // 搜索菜品历史
  PAGE_CARD_INFO = 'pageCardInfo', // 首页上半部分卡片信息
  WEBSEE_CONFIG = 'webseeConfig', // websee监控配置
  CLIENT_INFO = 'clientInfo', // 客户端设备信息
  FCM_TOKEN = 'fcm-token', // 推送token
  APP_FCM_TOKEN = 'app-fcm-token', // app推送token
  CLIENT_DEVICE_INFO = 'clientDeviceInfo', //duid auid等
  H5_VERSION = 'h5_version' //H5版本
}

// localStorage 数据类型注册, 用LOCAL_KEY的值做为key ，检测 enum 值的唯一性，出现标红 LOCAL_KEY 值重复
export type LocalData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deviceId: string;
  tokenInfo: { accessToken: string; refreshToken: string };
  guestInfo: { [K in `guest_${string}`]: number };
};

// sessionStorage 数据类型注册, 用SESSION_KEY的值做为key，检测 enum 值的唯一性，出现标红 SESSION_KEY 值重复
type SessionData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  login_time: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sessionId: string;
  storeId: string;
  channel_id: string;
  user_equipment: string; //全搜团队带过来的用户参数
  source_tag: string;
};

export const storage = {
  setLocalStorage: <K extends LOCAL_KEY, J extends boolean = false>(key: K, value: J extends true ? LocalData[K] : string, isJson?: J) =>
    setStorage('localStorage', key, value, isJson),
  getLocalStorage: <K extends LOCAL_KEY, J extends boolean = false>(key: K, isJson?: J) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    getStorage('localStorage', key, isJson) as (J extends true ? LocalData[K] : string) | null,
  removeLocalStorage: (key: LOCAL_KEY) => removeStorage('localStorage', key),
  setSessionStorage: <K extends SESSION_KEY, J extends boolean = false>(key: K, value: J extends true ? SessionData[K] : string, isJson?: J) =>
    setStorage('sessionStorage', key, value, isJson),
  getSessionStorage: <K extends SESSION_KEY, J extends boolean = false>(key: K, isJson?: J) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    getStorage('sessionStorage', key, isJson) as (J extends true ? SessionData[K] : string) | null,
  removeSessionStorage: (key: SESSION_KEY) => removeStorage('sessionStorage', key)
};

export const getFcmToken = () => {
  const fcmToken = storage.getLocalStorage(LOCAL_KEY.FCM_TOKEN);
  const appFcmToken = storage.getLocalStorage(LOCAL_KEY.APP_FCM_TOKEN);
  console.warn('fcmToken', fcmToken);
  console.warn('appFcmToken', appFcmToken);
  return fcmToken || appFcmToken;
};
