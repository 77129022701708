import axios from 'axios';
import { getLanguage } from '@/utils/lang';
import { LOCAL_KEY, SESSION_KEY, storage } from '@/utils/tools';
import { getTokenInfo } from '@/utils/login/login';
import { getObusSessionId, getObusDeviceId } from '@/utils/tools/others';
import { sign, getNetworkStatus, getOriginHeader } from '../tool';

// 添加请求拦截器
axios.interceptors.request.use(
  async (config) => {
    const sessionId = storage.getLocalStorage(LOCAL_KEY.QPON_SESSION_ID) || getObusSessionId();
    const deviceId = storage.getLocalStorage(LOCAL_KEY.QPON_DEVICE_ID) || getObusDeviceId();
    const sourceTag = storage.getSessionStorage(SESSION_KEY.SOURCE_TAG);
    const language = getLanguage();
    // requestId
    const requestId = Date.now().toString(36) + Math.random().toString(36).substr(2, 5);
    // 时间戳
    const timestamp = new Date().getTime();
    // 处理token
    const { accessToken } = getTokenInfo() || {};
    const requestData = config.data || {}; // 参数可不传递
    // 重新组装请求参数
    const resultQuestData = { ...requestData, sessionId, requestId, timestamp };
    // 解析出 xsys headers字段
    const existXSysHeader = getOriginHeader(config, 'X-Sys');
    const originLocal = existXSysHeader.locale;
    const existXDigital = getOriginHeader(config, 'X-Digital-Food');
    const digitalObj = {
      ...existXDigital,
      deviceId,
      netstate: getNetworkStatus(),
      // channel: channel,
      requestId,
      sessionId: sessionId,
      sourceTag
    };
    const signTag = sign(resultQuestData);
    config.data = resultQuestData;
    //2、处理数据签名
    const headers = config.headers;
    headers['Sign'] = signTag;
    headers['Timestamp'] = timestamp;
    headers['X-Digital-Food'] = encodeURIComponent(JSON.stringify(digitalObj));
    if (accessToken) {
      headers['Authorize'] = accessToken;
    }
    if (language !== originLocal) {
      existXSysHeader.locale = language;
      // 更新 X-Sys
      const newXSysEncode = encodeURIComponent(JSON.stringify(existXSysHeader));
      headers['X-Sys'] = newXSysEncode;
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
