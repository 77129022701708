import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/store';

export interface CounterState {
  scrollTop: number;
  redPacketVisible: {
    hasShow: boolean;
    visible: boolean;
  };
}

const initialState: CounterState = {
  scrollTop: 0,
  redPacketVisible: {
    hasShow: false,
    visible: false
  } //初始为false
};

// 更新操作
export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    updateRedPacketVisible: (state, action) => {
      state.redPacketVisible = action.payload;
    },
    updateScrollTop: (state, action) => {
      state.scrollTop = action.payload;
    }
  }
});

export const { updateRedPacketVisible, updateScrollTop } = homeSlice.actions;

export const getIsRedPacketVisible = (state: RootState) => state.home.redPacketVisible;

export const getScrollTop = (state: RootState) => state.home.scrollTop;

export default homeSlice.reducer;
