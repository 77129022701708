/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Tabs } from 'antd-mobile';
import { nativeSdkExampleOptions } from './nativeSdkOptions';
import { webseeOptions } from './webseeOptions';

export default () => {
  const [data, setData] = useState<any>({ nativeSdk: [], websee: [] });
  const [tabList] = useState(() =>
    [
      { label: '插件', value: 'nativeSdk', options: nativeSdkExampleOptions },
      { label: 'websee', value: 'websee', options: webseeOptions }
    ].map((item) => {
      return {
        ...item,
        options: item.options.map((row, rowIndex) => ({
          ...row,
          fn:
            Object.prototype.toString.call(row.fn) === '[object AsyncFunction]'
              ? async () => {
                  const set = (result: any) => {
                    setData({ ...data, [item.value]: { [rowIndex]: result } });
                  };
                  set(await Promise.resolve(row.fn()));
                }
              : () => setData({ ...data, [item.value]: { [rowIndex]: row.fn() } })
        }))
      };
    })
  );

  //   ...i,
  //   fn: async () => {
  //     const set = (result: any) => {
  //       setData({ ...data, [index]: result });
  //     };
  //     set(await Promise.resolve(i.fn()));
  //   }
  // }));
  return (
    <div style={{ padding: '20px', width: '100vw', height: '100vh', overflow: 'auto' }}>
      <button onClick={() => history.go(-1)} style={{ margin: '20px' }}>
        返回
      </button>
      <Tabs>
        {tabList.map((item) => {
          return (
            <Tabs.Tab title={item.label} key={item.value}>
              {item.options.map((row, rowIndex) => {
                return (
                  <div key={rowIndex} style={{ marginBottom: '10px' }}>
                    <button onClick={row.fn}>{row.label}</button>
                    <div>{JSON.stringify(data[item.value][rowIndex]) || ''}</div>
                  </div>
                );
              })}
            </Tabs.Tab>
          );
        })}
      </Tabs>
      {/* {list.map((item, index) => {
          return (
            <div key={index} style={{ marginBottom: '10px' }}>
              <button onClick={item.fn}>{item.label}</button>
              <div>{JSON.stringify(data[index]) || ''}</div>
            </div>
          );
        })} */}
    </div>
  );
};
