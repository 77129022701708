import { isPre, isProd } from '../env';
import { LOCAL_KEY } from '../tools';
import { cloudBuildVersionStr } from '../tools/constant';
import { getNetworkType } from '../tools/network';
import { idleFn } from '../tools/others';
import postMonitorReport from '@/api/common/postMonitorReport';

// 特定字符串上报次数 errorType 0 | 1 | 2 //异常类型：正常时为0，前端异常为1，服务端异常为2
interface excludeStrsReportErrorConfigItem {
  time: number;
  max: number;
  strType: string;
  errorType: 0 | 1 | 2;
}

const excludeStrsReportErrorConfig: Array<excludeStrsReportErrorConfigItem> = [
  { time: 0, max: 0, strType: 'CloudHubJSBridge', errorType: 0 },
  { time: 0, max: 0, strType: 'InvalidPointerId', errorType: 0 },
  { time: 0, max: 0, strType: 'AbortController', errorType: 0 },
  { time: 0, max: 0, strType: 'timeout of 20000ms exceeded', errorType: 0 },
  { time: 0, max: 0, strType: 'Write permission denied', errorType: 0 },
  { time: 0, max: 0, strType: 'Network Error', errorType: 0 },
  { time: 0, max: 0, strType: 'Request aborted', errorType: 0 },
  { time: 0, max: 10, strType: 'Failed to fetch dynamically imported module', errorType: 0 },
  { time: 0, max: 0, strType: 'getReadModeRender', errorType: 0 }, // heytap浏览器触发的报错，不影响页面功能的正常使用
  { time: 0, max: 0, strType: 'getReadModeExtract', errorType: 0 }, // heytap浏览器触发的报错，不影响页面功能的正常使用
  { time: 0, max: 0, strType: 'getReadModeConfig', errorType: 0 }, // heytap浏览器触发的报错，不影响页面功能的正常使用
  { time: 0, max: 0, strType: "undefined is not an object (evaluating 'a.L')", errorType: 0 } // iPhone/iPad使用chrome浏览器的谷歌翻译时导致的报错，不影响页面功能的正常使用
];
export const updateExcludeStrsReportErrorConfig: (list: excludeStrsReportErrorConfigItem[]) => void = function (list) {
  try {
    excludeStrsReportErrorConfig.push(...list);
  } catch (e) {
    monitorReport.info({ category: '生成 excludeStrsReportErrorConfig失败', msg: { message: error?.message || '' } });
  }
};

// 是否需要上报
function checkSpecialStrReport(message: string): [boolean, excludeStrsReportErrorConfigItem | null] {
  const configItem = excludeStrsReportErrorConfig.find((config) => message.includes(config.strType));
  // const strInExcludeArr = excludeStrs.some((str) => message.includes(str)); // 是否属于需要特定处理的字符串
  if (!configItem) return [true, null]; // 不在此列需要上报
  // 看配置有限制上报几次没。
  const { time, max } = configItem;
  if (time >= max) {
    return [false, null];
  } else {
    configItem.time++;
    return [true, configItem];
  }
}

export enum SCENE_TYPE {
  JS_ERROR = 'JS_ERROR', // JS执行错误
  RESOURCE_LOAD_ERROR = 'RESOURCE_LOAD_ERROR', // 资源加载错误(不包含菜品图片失败)
  API_ERROR = 'API_ERROR', // API请求错误
  API_BACK_ERROR = 'API_BACK_ERROR', // 服务端错误，可以屏蔽
  PICTURE_LOAD_ERROR = 'PICTURE_LOAD_ERROR', // 严格定义为菜品图片加载错误
  NO_IMAGE_ERROR = 'NO_IMAGE_ERROR', // 严格定义为菜品配置为空
  PROMISE_ERROR = 'PROMISE_ERROR', // Promise错误
  REACT_ERROR = 'REACT_ERROR', // React错误
  BSP_ERROR = 'BSP_ERROR', // 风控初始化相关错误
  OBUS_ERROR = 'OBUS_ERROR', // OBus相关错误
  PRE_API_ERROR = 'PRE_API_ERROR', // 预请求错误
  WEBVIEW_ERROR = 'WEBVIEW_ERROR', // WebView环境错误
  FIREBASE_ERROR = 'FIREBASE_ERROR' //firebase离线错误
}
// 加上一些额外的浏览器环境数据 都加msg上
function addCommonBrwseStatus(msg = {}) {
  const userAgent = navigator?.userAgent || '';
  const browserVendor = navigator?.vendor || '';
  const platform = navigator?.platform || '';
  const networkType = getNetworkType();
  return { ...msg, userAgent, browserVendor, platform, networkType };
}
export interface CommonParams {
  title?: string; // 标题
}
export interface MonitorInfo {
  url?: string;
  action?: string | number;
  page?: string;
  errorType?: number; // 0 | 1 | 2 //异常类型：正常时为0，前端异常为1，服务端异常为2
  category?: string;
  sceneType?: string;
  traceId?: string;
  msg?: {
    requestId?: string;
    config?: object;
    url?: string;
    req?: object;
    res?: object;
    stackTrace?: unknown;
    [key: string]: unknown;
  };
}

export type AxiosData = MonitorInfo & {
  currentUrl?: string;
  url: string;
  code: string;
  message: string;
  traceId: string;
  msg: object;
};

// export type MonitorData = MonitorInfo & CommonParams

export type MonitorParams = CommonParams &
  Pick<MonitorInfo, 'page' | 'action' | 'errorType' | 'category' | 'traceId' | 'sceneType'> & { msg?: string };

const getCommonData = () => {
  return {
    deviceId: localStorage.getItem(LOCAL_KEY.QPON_DEVICE_ID) || '', // 设备ID
    sessionId: localStorage.getItem(LOCAL_KEY.QPON_SESSION_ID) || '' // session_id
  };
};

/**
 * 监控上报
 */
export const monitorReport = {
  // 埋点、正常请求
  info: (data: MonitorInfo & { currentUrl?: string }) => {
    const { action, page, msg = {}, errorType = 0, traceId, currentUrl, category: categoryStr } = data || {};
    try {
      // SSR 渲染的时间戳
      const realCurrentUrl = currentUrl ?? window.location.href;
      const title = `【平台优惠券】\n 当前页面：【${realCurrentUrl}】`;
      // 这个值在构建会被修改
      const cloudPlatformVersion = cloudBuildVersionStr;
      const category = `正常日志上报：云平台version: ${cloudPlatformVersion},${categoryStr}`;
      const newMsg = addCommonBrwseStatus(msg);
      const requestData: MonitorParams = {
        title,
        category,
        action,
        page,
        errorType,
        // ...getCommonData(),
        msg: JSON.stringify(newMsg)
      };

      // cloudObservationRumInstance.recordLog('INFO',JSON.stringify({...requestData, msg }))

      if (traceId) requestData.traceId = traceId;

      // 正式环境上报,其他查看日志
      if (isProd || isPre) {
        idleFn(async () => await postMonitorReport(requestData));
        return;
      }
      console.info('monitorReport.info', requestData);
      return;
    } catch (e) {
      console.log('monitorReport.info 出错', e);
    }
  },
  error: (data: MonitorInfo & { currentUrl?: string }) => {
    const { category = '', action, page, msg = {}, traceId, sceneType, currentUrl } = data || {};
    // 某些特定字符串的特殊处理
    const [needReport, configItem] = checkSpecialStrReport(category);
    if (!needReport) {
      return false;
    } else {
      // 需要上报的数据。如果在以上额外处理的字符串，我们将errorType重置为预设值，这部分会上报到info不会告警到TT
      if (configItem && configItem.errorType === 0) {
        data.errorType = 0; // 修正errorType 为 0 不会上报到TT 但是会打日志留存
      }
    }
    try {
      // 这个值在构建会被修改
      const cloudPlatformVersion = cloudBuildVersionStr;
      const realCurrentUrl = currentUrl ?? window.location.href;
      const title = `【平台优惠券】\n 当前页面：【${realCurrentUrl}】`;
      const newCategory = `${category} 云平台version: ${cloudPlatformVersion}`;
      const newMsg = addCommonBrwseStatus(msg);
      const requestData: MonitorParams = {
        title,
        category: newCategory,
        action,
        page,
        errorType: data.errorType,
        ...getCommonData(),
        msg: JSON.stringify(newMsg)
      };

      if (traceId) requestData.traceId = traceId;
      if (sceneType) requestData.sceneType = sceneType;
      // 非正式环境不上报,本地查看日志
      if (isProd || isPre) {
        idleFn(async () => await postMonitorReport(requestData));
        return;
      }
      console.warn('monitorReport.error', requestData);
      return;
    } catch (e) {
      console.error('monitorReport.error 出错', e);
    }
  }
};
