import { memo, useMemo } from 'react';
import { Image } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import cls from 'classnames';
import noImage2 from '@/assets/merchant/noImage2.svg';
import noImage from '@/assets/merchant/noimage.svg';
import useReport from '@/hooks/useReport';
import { GroupCouponListRes } from '@/hooks/api/brands/brands';
import { compressImg } from '@/utils/tools/compressImg';
import { ProductItem } from '@/api/merchant/queryCopon';
import present from '@/assets/list/present.svg';
import lightning from '@/assets/list/lightning.svg';
import DiscountLabel from '@/pages/home/components/GroupBuyListItem/components/DiscountLabel';
import LabelType from '@/pages/home/components/GroupBuyListItem/components/LabelType';
import { getLanguage } from '@/utils/lang';
import errPresent from '@/assets/list/errPresent.svg';
import errLightning from '@/assets/list/errLightning.svg';
import styles from './index.module.scss';

export interface ProductCardProps<T = ProductItem> {
  item: T;
  language: string;
  channel_id: string;
  formatToIndonesianRupiah: (price: number) => string;
  login?: (fn: () => void, loginParams: loginParams) => void;
  t: (key: string, values?: Record<string, number>) => string;
  activityId: number;
  isToDetail?: boolean; //是否跳转详情
  isOpenLocation?: boolean;
  locationDistance?: string | number;
  reportEveryItem?: boolean; //是否每个卡片都进行曝光
  storeId: string;
  detailId?: string;
}
interface loginParams {
  sceneType: string;
  fromEventId: string;
}

// 商品卡片组件
const ProductCard: React.FC<ProductCardProps<ProductItem | GroupCouponListRes['productList'][0]>> = memo(
  ({
    item,
    channel_id,
    language,
    formatToIndonesianRupiah,
    login,
    t,
    activityId,
    isToDetail,
    isOpenLocation,
    locationDistance,
    storeId,
    detailId
  }) => {
    const navigate = useNavigate();
    const lang = getLanguage();
    const { report } = useReport();

    const isActivity = useMemo(() => {
      const isActivity = !item?.isSoldOut && item?.purchaseCode === 0;
      return isActivity;
    }, [item?.isSoldOut, item?.purchaseCode]);
    // 立即购买
    const handleBuyNow = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (item?.purchaseCode === 0) {
        // 优惠券卡片购买按钮点击
        const eventId = 'coupon_card_claim_btn';
        const reportData = {
          type: 'click',
          activity_id: activityId || '',
          coupon_id: item?.productId,
          status: item?.purchaseCode === 0,
          product_id: item?.productId,
          btn_text: '购买',
          coupon_type: 'PRODUCT',
          is_open_location: isOpenLocation || false,
          location_distance: Number(locationDistance)?.toFixed(4) || 0,
          store_id: storeId
        };
        //obus上报
        report(eventId, reportData);
        const loginParams = { sceneType: '优惠券卡片点击', fromEventId: 'coupon_card_btn' };
        const detailIdStr = detailId ? `&detailId=${detailId}` : '';

        if (isToDetail) {
          navigate(`/platform/groupCouponDetail?skuId=${item.skuId}&productId=${item.productId}&storeId=${storeId}${detailIdStr}`);
          return;
        }
        login?.(() => {
          navigate(`/platform/confirmOrder?skuId=${item.skuId}&productId=${item.productId}&storeId=${storeId}${detailIdStr}`);
        }, loginParams);
      }
    };
    // 点击商品卡片跳转
    const handleChickProductCard = () => {
      // 优惠券卡片点击
      const eventId = 'coupon_card_btn';
      const reportData = {
        type: 'click',
        activity_id: activityId || '',
        coupon_id: item?.productId,
        status: item?.purchaseCode === 0,
        coupon_type: 'PRODUCT',
        product_id: item?.productId,
        is_open_location: isOpenLocation || false,
        location_distance: Number(locationDistance)?.toFixed(4) || 0,
        store_id: storeId
      };
      //上报埋点
      report(eventId, reportData);
      const detailIdStr = detailId ? `&detailId=${detailId}` : '';
      let url = `/platform/groupCouponDetail?skuId=${item.skuId}${detailIdStr}`;
      if (channel_id) {
        url += `&channel_id=${channel_id}`;
      }
      navigate(url);
    };
    const showActivityTagName = useMemo<boolean>(() => {
      if (!item?.promotionInfo) {
        return false;
      }
      const promotionActivityTagName = item?.promotionInfo?.promotionActivityTagName || {};
      return Object.values(promotionActivityTagName).filter((item) => !!item).length > 0;
    }, [item?.promotionInfo?.promotionActivityTagName]);
    const percent = useMemo(() => {
      return item?.promotionInfo?.promotionDiscountRatio || item?.discountRatio || 0;
    }, [item?.discountRatio, item?.promotionInfo?.promotionDiscountRatio]);

    // 是否展示剩余库存,只有库存小于10的时候才展示
    const [showRemainingStock, couponNumText] = useMemo(() => {
      return [
        item?.remainingStock && item?.remainingStock >= 1 && item?.remainingStock <= 10,
        t('coupons.onlyItemsList', { num: item?.remainingStock })
      ];
    }, [item?.remainingStock]);

    return (
      <div className={styles.productListContainer} onClick={handleChickProductCard}>
        <div className={styles.lineOne}>
          <div className={styles.lineOneLeft}>
            <Image
              fit="cover"
              className={styles.lineOneImg}
              src={item?.logoUrl ? compressImg(item?.logoUrl, { width: 128 }) : item?.productType === 'CASH_COUPON' ? noImage2 : noImage}
              alt=""
              lazy
              placeholder={<img src={item?.productType === 'CASH_COUPON' ? noImage2 : noImage} alt="" />}
            />
            {!isActivity && (
              <div className={styles.imgMask}>
                <span>{item?.isSoldOut ? t('home.soldOut') : t('merchant.couponSoldOutTips')}</span>
              </div>
            )}
            {percent && <DiscountLabel isActivity={isActivity} discountPercent={-percent} />}
            {!!showRemainingStock && (
              <div className={styles.amountContainer}>
                <div className={styles.amountText}>{couponNumText}</div>
              </div>
            )}
          </div>
          <div className={styles.lineOneright}>
            <div className={cls(styles.productName, !isActivity ? styles.loseNameColor : '')}>
              {item?.productName?.[language] || item?.productName?.['id-ID']}
            </div>
            <div className={styles.labelContainer}>
              {showActivityTagName && item.promotionInfo?.activityType === 'COMMON' && (
                <LabelType
                  isActivity={isActivity}
                  imgIcon={isActivity ? present : errPresent}
                  descText={item.promotionInfo?.promotionActivityTagName?.[lang] ?? item.promotionInfo?.promotionActivityTagName?.['id-ID'] ?? ''}
                />
              )}
              {item.promotionInfo?.activityType === 'FLASH_SALE' && (
                <LabelType isActivity={isActivity} imgIcon={isActivity ? lightning : errLightning} descText={t('coupons.flashSale')} />
              )}
            </div>
            <div className={styles.lineTwo}>
              <div className={styles.lineTwoLeft}>
                <div className={cls(styles.salePrice, !isActivity ? styles.loseSalePrice : styles.activitySalePrice)}>
                  <span className={styles.pricePrefix}>Rp</span>
                  <span className={styles.price}>{formatToIndonesianRupiah(item?.promotionInfo?.promotionActivitySalePrice ?? item?.salePrice)}</span>
                </div>
                <del className={styles.originalPrice}>
                  Rp<span>{formatToIndonesianRupiah(item?.originalPrice)}</span>
                </del>
              </div>
              <div className={styles.lineTwoRight} onClick={handleBuyNow}>
                <div className={cls(styles.productButton, !isActivity ? styles.loseButton : '')}> {t('merchant.buyNow')}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default memo(ProductCard);
