import { useEffect } from 'react';
import useReport from '@/hooks/useReport';

/**
 * 命中往返缓存进行obus上报的use hook
 */
export function usePageShowReport() {
  const { report } = useReport();
  useEffect(() => {
    /**
     * 命中bfcache，触发storage事件，通过监听storage事件触发handleReportBfcache进行上报
     * 原因：pageshow事件无法触发obus上报，相关代码被浏览器阻止执行
     * @param event
     */
    const handlePageShow = async (event) => {
      if (event.persisted) {
        // 手动触发 storage 事件
        window.dispatchEvent(
          new StorageEvent('storage', {
            key: 'bfcache_view',
            newValue: 'true',
            oldValue: null,
            storageArea: localStorage
          } as StorageEventInit | undefined)
        );
      }
    };
    // bfcache曝光上报
    const handleReportBfcache = (e: StorageEvent) => {
      if (e.key === 'bfcache_view') {
        const eventId = 'bfcache_view';
        const reportData = {
          type: 'view'
        };
        report(eventId, reportData);
      }
    };

    // 挂载时监听
    window.addEventListener('pageshow', handlePageShow);
    window.addEventListener('storage', handleReportBfcache);

    // 卸载时移除监听
    return () => {
      window.removeEventListener('pageshow', handlePageShow);
      window.removeEventListener('storage', handleReportBfcache);
    };
  }, [report]);
}

export default usePageShowReport;
