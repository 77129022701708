import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import NavBar from '@/components/nav-bar';
import downloadQponImg from '@/assets/groupCouponDetail/downloadQpon.webp';
import { t } from '@/utils/lang';
import useReport from '@/hooks/useReport';
import useQueryParams from '@/hooks/useQueryParams';
import style from './index.module.scss';

interface QueryParams {
  is_force_app: boolean;
  is_check_addr: boolean;
}
export default function DownloadQpon() {
  const navigate = useNavigate();
  const { report } = useReport();
  const { is_force_app, is_check_addr } = useQueryParams<QueryParams>();
  // 返回
  const goBack = () => {
    report('top_return_btn', {
      type: 'click',
      is_force_app,
      is_check_addr
    });
    navigate(-1); //返回上一层
  };
  //   用deepLink跳转
  const toApp = () => {
    report('btn', {
      type: 'click',
      is_force_app,
      is_check_addr
    });
    window.location.href = 'https://qpon.go.link/platform/home?adj_t=1iks3t4b_1iudkxue';
  };

  useEffect(() => {
    report('page', {
      type: 'view',
      is_force_app,
      is_check_addr
    });
  }, []);
  return (
    <div className={style.container}>
      <NavBar showBackButton onBack={goBack} transparent></NavBar>
      <img className={style.appImg} src={downloadQponImg} alt="" />
      <div className={style.containerBox}>
        <div className={style.downloadBtn} onClick={toApp}>
          {t('couponDetail.toApp')}
        </div>
        <p className={style.downloadText}>{t('couponDetail.openApp')}</p>
      </div>
    </div>
  );
}
