// 初始配置 在这个文件完成大部分可以获取得到的配置和headers
// import env from '@heytap/lib-env';
import UAParser from 'ua-parser-js';
import { ConfigType } from '../interface';
import { channel } from '../config/config';
import { BASE_PATH, signConfig } from './config';
import { getLanguage } from '@/utils/lang';
import { monitorReport } from '@/utils/monitor/monitorReport';
import { LOCAL_KEY, SESSION_KEY, storage } from '@/utils/tools';
import { getBaseUrl } from '../index.ts';
import { isDev, isTest } from '@/utils/env/index.ts';

/*const { VITE_BUILD_ENV } = import.meta.env;
const isDevOrTest = ['test', 'dev'].includes(VITE_BUILD_ENV);

const host = import.meta.env.VITE_BACKEND_HOST;

let baseUrl;
if (isDevOrTest) {
  const arr = host.split(',');
  baseUrl = isDevOrTest ? localStorage.getItem('curBaseUrl') || arr[0] : arr[0];
} else {
  baseUrl = import.meta.env.VITE_BACKEND_HOST || 'https://qpon-food-gl.qponmobile.com';
}*/

const baseUrl = getBaseUrl();
console.log('baseUrl:', baseUrl, import.meta.env.VITE_BUILD_ENV);
// 使用 Proxy 监听 curBaseUrl 的变化
export const curBaseUrl = new Proxy(
  { value: baseUrl },
  {
    set(target, property, value) {
      if (isDev || isTest) {
        if (property === 'value') {
          console.log(`curBaseUrl 更新: 从 ${target[property]} 到 ${value}`);
          target[property] = value;
          // 更新axios配置的baseUrl
          axiosConfig.baseURL = curBaseUrl.value + BASE_PATH;
          console.log('axiosConfig.baseURL:', axiosConfig.baseURL);
          // 更新localStorage的baseUrl相关配置
          localStorage.setItem('curBaseUrl', curBaseUrl.value);
        }
      }
      return true;
    }
  }
);

const userAgent = new UAParser(navigator.userAgent);
const deviceResult = userAgent.getResult();
const language = getLanguage();
let XSysInfo = {};
try {
  XSysInfo = {
    model: deviceResult?.device?.model || '', //机型
    ht: window.innerHeight, //分辨率-高
    wd: window.innerWidth, //分辨率-宽
    brand: deviceResult?.device?.vendor || '', //生产商
    hardwareType: deviceResult?.device?.type || '', //设备类型
    locale: language,
    osType: deviceResult?.os?.name === 'iOS' ? 1 : 0, //操作系统
    osVersionCode: `${deviceResult?.os?.name}${deviceResult?.os?.version}`
  };
} catch (e) {
  monitorReport.info({ category: 'XSysInfo解析失败', msg: { message: e?.message || '' } });
}

const encodeJson = encodeURIComponent(JSON.stringify(XSysInfo));
const deviceId = storage.getLocalStorage(LOCAL_KEY.DEVICE_ID);
const user_equipmentObj = JSON.parse(storage.getSessionStorage(SESSION_KEY.user_equipment) || '{}'); // 这是全搜带的 设备信息相关

const baseConfig = {
  baseURL: baseUrl + BASE_PATH,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'X-Protocol-Ver': '3.0',
    AppKey: signConfig!.appKey,
    IdentityType: 'C',
    'X-Sys': encodeJson,
    'X-Model': '',
    'X-Digital-Food': encodeURIComponent(
      JSON.stringify({
        deviceId,
        channel: channel,
        ...user_equipmentObj
      })
    ),
    Cptype: 'qpon'
  }
};

const axiosConfig = Object.assign({}, baseConfig) as unknown as ConfigType;

export default axiosConfig;
