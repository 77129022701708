import { HomeListTypeVO } from './type';

export const initialState: HomeListTypeVO = {
  isFirstPage: true,
  lat: '-6.2088', // 默认雅加达地址
  lng: '106.8456',
  listType: 'distance',
  commonQueryparmas: {
    categoryIdList: [],
    pageSize: '10',
    queryId: '',
    needStatistical: false,
    isDefaultData: true
  },
  distanceQueryParams: {
    distance: '10' // 距离默认为10km
  },
  regionQueryParams: {
    regionId: '0' // 默认查询全城
  },
  recommondQueryParams: {
    originQueryId: '' // 只有第一次传
  },
  productsRes: {
    list: [], // 可能要累加 可能要重置
    hasMore: false, // 默认false 防止挂载，拉取数据
    queryId: '',
    activityId: -1,
    loading: false,
    listTypeData: 'distance',
    isLogin: false
  },
  recommondProductsRes: {
    list: [], // 可能要累加 可能要重置
    hasMore: true,
    queryId: '',
    activityId: -1,
    loading: false,
    isLogin: false
  },
  isFirstReceive: false,
  isFirstRetain: false,
  isSelect: false,
  recivedCoupons: {}, // 领取优惠券
  groupHasBuyCoupons: {}, // 售清优惠券
  curCascaderAreaVal: [], // 已选regionId列表
  redPacketVisible: {
    // 红包模块数据
    hasShow: false,
    visible: false
  },
  //推荐菜类型数据
  curCuisineType: [],
  curPosition: {
    title: ''
  }
};
