import { useMemoizedFn, useMount } from 'ahooks';
import dayjs from 'dayjs';
import { reportSdk } from '@qpon-design/burypoint';
import { LOCAL_KEY, SESSION_KEY, storage } from '@/utils/tools';
import { getAndroidReleaseVersion, getAppInfoObj, getBrowserImei, getBrowserVersion, getOpenId } from '@/utils/tools/browserJsBridge';
import { sdkType } from '@/utils/constants/couponDetail';
import originData from '@/report/index.json';
import { isOpenFromPWA, hasSW } from '@/utils/pwa/index';
import { generateUUID, getObusDeviceId, getObusSessionId, getQueryParam } from '@/utils/tools/others';
import { getAppSystem, isIos, isQponApp, platformType } from '@/utils/nativeSdk';
import { frontEnv } from '@/utils/env/index';
import { adjustToken, cloudBuildVersionStr } from '@/utils/tools/constant';
import { getLanguageStr } from './config';

export type Properties = Record<string, unknown>;

const pathMap: Record<string, string> = {
  '/': 'qpon_platform_page',
  '/platform/home': 'qpon_platform_page',
  '/platform/myCouponList': 'my_card_coupon',
  '/platform/couponDetail': 'coupon_detail_page',
  '/platform/groupCouponOrderDetail': 'coupon_detail_page',
  '/platform/merchant': 'food_store_home_page',
  '/platform/confirmOrder': 'place_order_page',
  '/platform/cashier': 'checkout_counter_page',
  '/platform/groupCouponDetail': 'coupon_detail_page',
  '/platform/brands': 'brand_collection_page',
  '/platform/flashDeal': 'hot_product_page',
  '/platform/brandsHomepage': 'brand_page',
  '/platform/searchDishes': 'search_page',
  '/platform/home/order': 'my_coupon_order',
  '/platform/home/account': 'my_page',
  '/platform/pay': 'food_purchase_page',
  '/platform/mall': 'mall_detail_page',
  '/platform/purchaseSuccessPage': 'purchase_success_page',
  '/platform/downloadQpon': 'app_redeem_page'
};
// 这个是为了在不同场景埋点维护一个公共的变量传递
const runtimeVars = {
  prePage: '',
  currentPage: '',
  from_event_id: ''
};

// 从桌面PWA打开
const pwa = isOpenFromPWA();
// 被SW控制
const sw = hasSW();

const getPlatform = () => {
  if (isOpenFromPWA()) return 'pwa';
  if (isQponApp()) return 'app';
  return 'h5';
};

export const getUserId = () => {
  const NO_USER_ID = 'no_user_id'; //10月21号之前登录的用户是无userId的。这部分用户给个特殊值标识
  const tokenInfo = localStorage.getItem(LOCAL_KEY.TOKEN_INFO);
  if (tokenInfo) {
    //有token
    const result = JSON.parse(tokenInfo);
    return result?.userId || NO_USER_ID;
  } else {
    return null;
  }
};

const getToken = () => {
  const tokenInfo = localStorage.getItem(LOCAL_KEY.TOKEN_INFO);
  return tokenInfo;
};

const getLoginStatus = () => {
  const tokenInfo = localStorage.getItem(LOCAL_KEY.TOKEN_INFO);
  return tokenInfo ? 1 : 0;
};

const getQponDeviceId = () => {
  const qponDeviceId = localStorage.getItem(LOCAL_KEY.QPON_DEVICE_ID);
  if (qponDeviceId) {
    return qponDeviceId;
  } else {
    return getObusDeviceId();
  }
};

const getQponSessionId = () => {
  const qponSessionId = localStorage.getItem(LOCAL_KEY.QPON_SESSION_ID);
  if (qponSessionId) {
    return qponSessionId;
  } else {
    return getObusSessionId();
  }
};

const getChannelId = () => {
  const url_channel_id = getQueryParam('channel_id');
  const session_channel_id = sessionStorage.getItem(SESSION_KEY.CHANNEL_ID);

  if (url_channel_id) {
    if (url_channel_id !== session_channel_id) {
      sessionStorage.setItem(SESSION_KEY.CHANNEL_ID, url_channel_id);
    }
    return url_channel_id;
  }

  return session_channel_id ?? '';
};

const getSourceTag = () => {
  //先判断url有source_tag 优先级最高
  const url_source_tag = getQueryParam('source_tag');
  const session_source_tag = sessionStorage.getItem(SESSION_KEY.SOURCE_TAG);
  //url 有source_tag
  if (url_source_tag) {
    if (url_source_tag !== session_source_tag) {
      sessionStorage.setItem(SESSION_KEY.SOURCE_TAG, url_source_tag);
    }
    return url_source_tag;
  }
  //url无source_tag
  const channel_id = getChannelId();
  // 兼容以往已投放出去的链接。需要将channel_id为非1-6的链接。赋值给source_tag
  if (channel_id && ![1, 2, 3, 4, 5, 6].includes(Number(channel_id))) {
    const newSourceTag = channel_id;
    if (newSourceTag !== session_source_tag) {
      sessionStorage.setItem(SESSION_KEY.SOURCE_TAG, newSourceTag);
    }
    return newSourceTag;
  }

  return session_source_tag ?? '';
};

//getPageCommonLogMap里面是每次埋点都要实时上传的可变的字段
const getPageCommonLogMap = () => {
  const user_id = getUserId();
  const session_id = sessionStorage.getItem(SESSION_KEY.SESSION_ID) || '';
  const userEquipmentStr = storage.getSessionStorage(SESSION_KEY.user_equipment);
  let userEquipmentObj = {}; // 全搜团队携带的url上的用户设备信息
  try {
    userEquipmentObj = userEquipmentStr ? JSON.parse(userEquipmentStr) : {};
  } catch (e) {
    console.error('JSON parsing error:', e);
  }
  return {
    app_system: getAppSystem(),
    host_environment: getPlatform(),
    event_page: pathMap[window.location.pathname] || '',
    timestamp_id: new Date().getTime(),
    qpon_id: user_id, //pubsub报qpon_id
    user_id,
    token: getToken(),
    login_status: getLoginStatus(),
    qpon_device_id: getQponDeviceId(),
    qpon_session_id: getQponSessionId(),
    channel_id: getChannelId(),
    source_tag: getSourceTag(),
    language: getLanguageStr(),
    url: location.href,
    device_id: storage.getLocalStorage(LOCAL_KEY.DEVICE_ID) || '',
    session_id,
    store_id: '',
    merchant_id: '',
    local_time: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    from_page: pathMap[runtimeVars.prePage] || '',
    from_event_id: runtimeVars.from_event_id || '',
    imei: getBrowserImei(),
    ...getOpenId(),
    ...getAppInfoObj(), //获取设备版本信息
    ...userEquipmentObj,
    uuid: generateUUID(),
    cloud_build_version: cloudBuildVersionStr,
    platform_type: 'qpon' //平台类型
  };
};

// 定义Hook返回的接口
interface ReportHookReturn {
  reportInit: () => void;
  report: (eventId: string, properties: Properties, eventGroup?: string) => void;
}
//原埋点hooks
function useReport(): ReportHookReturn {
  // 每次渲染处理prepage问题。(因为useReport可能用在非router组件内部)
  useMount(() => {
    const pathname = window.location.pathname;
    if (!runtimeVars.currentPage) {
      runtimeVars.currentPage = pathname;
      return;
    }
    if (pathname !== runtimeVars.currentPage) {
      runtimeVars.prePage = runtimeVars.currentPage;
      runtimeVars.currentPage = pathname;
    }
  });

  //埋点初始化
  const reportInit = () => {
    if (!navigator.onLine) return;
    //埋点sdk接入初始化,obus通道开启
    const nodeHost = import.meta.env.VITE_NODE_HOST || 'https://qpon-food-gl.qponmobile.com';
    reportSdk
      .init(
        {
          uploadSDK: sdkType.OBUS,
          env: frontEnv,
          isNeedAdjustConfig: {
            reportAdjust: isQponApp() || false,
            // adjust的各端token
            adjustToken: adjustToken[platformType()],
            isAndroid: !isIos() //不是ios默认都为安卓
          },
          nodeHost
        },
        getPageCommonLogMap,
        originData
      )
      .then(() => {
        // 添加到公共字段
        reportSdk.setCustomProperties({
          offline_status: window.offline_status,
          pwa: pwa,
          sw: sw,
          androidReleaseVersion: getAndroidReleaseVersion(),
          oppoBrowserVersion: getBrowserVersion()
        });
        //初始化先设置一次获取obus的device_id和session_id
        getQponDeviceId();
        getQponSessionId();
      })
      .catch((error: unknown) => {
        console.log('埋点初始化失败：', error);
      });
  };

  /**
   * 埋点上报
   * @param  eventId 事件名(点击|曝光)
   * @param properties 上报内容
   * @param  eventGroup 页面或者组件的pageId，一般情况下不需要传递，除非是组件的group
   */
  const report = useMemoizedFn((eventId: string, properties: Properties, eventGroup?: string) => {
    const pathname = window.location.pathname;
    //eventData 事件对象demo{ eventGroup: 'qpon_platform_page', eventId: 'page', properties: { test: '贾嵘测试' }}
    const eventData = {
      eventId,
      eventGroup: eventGroup || pathMap[pathname] || '',
      properties: properties || {}
    };
    reportSdk.report(eventData);
    // 每次执行report我们认为是一次埋点，当properties.type  === click说明是一次点击，我们存储这个eventId留给下次的页面曝光使用
    if (properties?.type === 'click') {
      runtimeVars.from_event_id = eventId;
    }
  });

  return { reportInit, report };
}
export default useReport;
