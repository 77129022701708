import React, { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import backHome from '@/assets/common/back-home.svg';
import backPage from '@/assets/common/back-page.svg';
import backHomeBg from '@/assets/common/back-home-bg.svg';
import backPageBg from '@/assets/common/back-page-bg.svg';
import { getStack } from '@/hooks/useHistoryStack';

interface IBackIcon {
  children?: React.ReactNode;
  className?: string;
  mask?: boolean;
  backType?: 'backPage' | 'backHome';
  onClick?: (() => void) | ((str?: IBackIcon['backType']) => void);
}

const CustomIcon: FC<IBackIcon> = ({ children, onClick }) => {
  return <div onClick={onClick as () => void}>{children}</div>;
};

const AutoIcon: FC<IBackIcon> = ({ className, mask, onClick, backType }) => {
  const navigate = useNavigate();

  const iconType = useMemo(() => {
    return backType || (onClick || getStack?.()?.length > 0 ? 'backPage' : 'backHome');
  }, [onClick, backType]);

  const iconData = useMemo(() => {
    return {
      backHome: { src: backHome, size: 24 },
      backPage: { src: backPage, size: 24 },
      backPageBg: { src: backPageBg, size: 30 },
      backHomeBg: { src: backHomeBg, size: 30 }
    }[`${iconType}${(mask && 'Bg') || ''}`] as { src: string; size: number; style?: React.CSSProperties };
  }, [mask, iconType]);

  const iconClick = useCallback(() => {
    if (onClick && typeof onClick === 'function') {
      onClick(iconType);
    } else {
      iconType === 'backPage' ? navigate(-1) : navigate('/');
    }
  }, [iconType]);
  return <img className={className} {...iconData} onClick={iconClick} />;
};

const BackIcon: FC<IBackIcon> = ({ children, ...arg }) => {
  return children ? <CustomIcon {...arg}>{children}</CustomIcon> : <AutoIcon {...arg} />;
};

export default React.memo(BackIcon);
