import React from 'react';
import styles from './Exception.module.scss';

interface IException {
  type?: string;
  title?: string;
  description?: React.ReactNode;
}

const Exception: React.FC<IException> = () => {
  return (
    <section className={styles.exceptionContainer}>
      <div className={styles.exceptionContainer}></div>
    </section>
  );
};

export default Exception;
