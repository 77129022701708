import request from '@/utils/ajax';

export interface LayoutData {
  appId: string;
}

export interface IData {
  disabled?: boolean; // 是否禁用监控
  appName?: string; // 应用名称
  silentWhiteScreen?: boolean; // 是否开启错误上报去重，重复的代码错误只上报一次
  whiteBoxElements?: string[]; // 视为白屏的容器列表
  skeletonProject?: string; // 是否有骨架屏
  setIncludesPaths?: string[]; // 指定需要监控的接口
}

export interface ResponseData {
  success: boolean;
  data?: IData;
  message?: string;
}

export default async (baseUrl: string, appId: string): Promise<ResponseData> => {
  return await request(`${baseUrl}/nodePublic/control/getConfig?appId=${appId}`, {}, { method: 'GET' });
};
