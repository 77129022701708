import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/store';

export interface CounterState {
  scrollLeft: number;
}

const initialState: CounterState = {
  scrollLeft: 0
};

// 更新操作
export const homeSlice = createSlice({
  name: 'cuisineTypeScroll',
  initialState,
  reducers: {
    updatedScrollLeft: (state, action) => {
      state.scrollLeft = action.payload;
    }
  }
});

export const { updatedScrollLeft } = homeSlice.actions;

export const getScrollLeft = (state: RootState) => state.cuisineType.scrollLeft;

export default homeSlice.reducer;
