// 优惠券类型字段

// 优惠券订单枚举
export enum COUPON_ORDER_STATUS {
  INIT = 'INIT', //初始化
  SUBMIT = 'SUBMIT', //提交订单
  COMPLETED = 'COMPLETED', //已完成
  REFUND = 'REFUND', //已退款
  CANCEL = 'CANCEL', //已取消
  RETURN = 'RETURN', //已退单
  EXPIRED = 'EXPIRED' //已过期
}

// 优惠券支付状态枚举
export enum COUPON_PAY_STATUS {
  AWAITPAY = 'WAIT_PAY', //待支付
  CLOSE = 'CLOSE', //已关闭
  PAYSUCCESS = 'PAY_SUCCESS' //支付成功
}

// 核销使用状态
export enum COUPON_VOUCHER_STATUS {
  VALID = 'VALID', //待使用
  USED = 'USED', //已使用
  REFUND = 'REFUND', //已退款
  EXPIRED = 'EXPIRED', //已过期
  NOT_DELIVERY = 'NOT_DELIVERY' //未发货
}

export enum REDEEM_TYPE {
  OFFLINE_CODE = 'OFFLINE_CODE', //线下核销
  SCANE_ORDER_CODE = 'SCANE_ORDER_CODE' //线上扫码核销
}

export enum REDEEM_TIME_TYPE {
  OPENING_TIME = 'OPENING_TIME', //营业时间内核销
  FIXED_TIME = 'FIXED_TIME' //固定时间内核销
}

export enum VOUCHERS_STATUS {
  VALID = 'VALID', //有效
  USED = 'USED', //已核销
  EXPIRED = 'EXPIRED', //已过期
  NOT_EFFECTIVE = 'NOT_EFFECTIVE', //未生效
  INVALID = 'INVALID' //已作废
}

// 商品类型

export enum CATEGORY_TYPE {
  SINGLE = 'SINGLE',
  COMBO = 'COMBO'
}

// 优惠券页面类型
export enum COUPON_PAGE_TYPE {
  ORDER = 'order',
  COUPON = 'coupon'
}


// 团购券类型
export enum COUPON_CODE_CATEGORY {
  PAPER_VOUCHER = 'PAPER_VOUCHER',
  QPON = 'QPON',
  CODE = 'CODE',
  CODE_LINK = "CODE_LINK"
}

