import { useLocation } from 'react-router-dom';

function useQueryParams<T>(url?: string): T {
  const location = useLocation();
  const resUrl = url || location.search;
  const search = resUrl.slice(1); // 去掉开头的问号

  const params: Record<string, string> = {};

  // 手动解析查询字符串
  if (search) {
    const pairs = search.split('&');
    for (const pair of pairs) {
      const [key, value] = pair.split('=');
      if (key && value) {
        // 对每个值进行 encodeURIComponent 编码
        params[key] = encodeURIComponent(decodeURIComponent(value));
      }
    }
  }

  // 使用 URLSearchParams 重新构建查询参数
  const searchParams = new URLSearchParams(params);

  // 创建一个空的对象来存储解析后的参数
  const result: Record<string, unknown> = {};

  // 遍历所有查询参数并填充到 result 对象中
  for (const [key, value] of searchParams.entries()) {
    result[key] = decodeURIComponent(value);
  }

  // 将 result 转换为指定的泛型类型 T
  const typedParams: T = Object.assign({}, result) as T;

  return typedParams;
}

export default useQueryParams;
