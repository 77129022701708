import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from './slices/counterSlice';
import homeReducer from './home/index';
import activityReducer from './home/activity';
import orderReducer from './order/index';
import searchReducer from './home/search';
import languageReducer from './global/language';
import positionReducer from './global/position';
import homeList from './home/list/index';
import pageCardListReducer from './home/pageCardList';
import cuisineTypeScroll from './home/cuisineTypeScroll';

export const store = configureStore({
  reducer: {
    position: positionReducer,
    counter: counterReducer,
    home: homeReducer,
    activity: activityReducer,
    order: orderReducer,
    search: searchReducer,
    language: languageReducer,
    homeList: homeList,
    pageCardList: pageCardListReducer,
    cuisineType: cuisineTypeScroll
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = unknown> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
