/* eslint-disable @typescript-eslint/no-explicit-any */
export const webseeOptions = [
  {
    label: 'websee-js错误',
    fn: () => {
      const a = undefined as any;
      if (a.length) {
        console.log('1');
      }
    }
  },
  {
    label: 'websee-资源加载失败',
    fn: () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://abc.com/index.js';
      document.body.appendChild(script);
    }
  },
  {
    label: 'websee-promise错误',
    fn: () => {
      new Promise((resolve) => {
        const person = {} as any;
        person.name.age();
        resolve(person);
      });
    }
  },
  {
    label: 'websee-fetch请求报错',
    fn: () => {
      fetch('https://jsonplaceholder.typicode.com/posts/a');
    }
  }
];
