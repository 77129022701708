import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMemoizedFn } from 'ahooks';
import LoadingView from '@/components/common/LoadingView';
import activeHome from '@/assets/appHome/activeHome.svg';
import inActiveHome from '@/assets/appHome/inActiveHome.svg';
import activeOrder from '@/assets/appHome/activeOrder.svg';
import inActiveOrder from '@/assets/appHome/inActiveOrder.svg';
import activeAccount from '@/assets/appHome/activeAccount.svg';
import inActiveAccount from '@/assets/appHome/inActiveAccount.svg';
import BottomTabs, { TabsData } from '@/components/common/BottomTabs';
import { closeSplashPage, getPlatform, notifyAppReady } from '@/utils/nativeSdk';
import { t } from '@/utils/lang';
import usePosition from '@/hooks/usePosition';
import { initAppNotifications } from '@/utils/push-notifications';
import useListenerDeepLink from '@/hooks/useListenerDeepLink.ts';
import useReport from '@/hooks/useReport';
import styles from './index.module.scss';

const Home = React.lazy(() => import('@/pages/home'));
const Order = React.lazy(() => import('@/pages/order'));
const Acccount = React.lazy(() => import('@/pages/account'));

const AppHome = () => {
  const { report } = useReport();
  const tabs: TabsData = [
    {
      key: '/',
      title: t('homeTab'),
      activeIcon: activeHome,
      inActiveIcon: inActiveHome,
      checkActive: (activeTab, key) => {
        return activeTab === '/platform/home' || activeTab === key;
      }
    },
    // {
    //   key: '/platform/home',
    //   title: 'home',
    //   activeIcon: activeHome,
    //   inActiveIcon: inActiveHome
    // },
    {
      key: '/platform/home/order',
      title: t('orderTab'),
      activeIcon: activeOrder,
      inActiveIcon: inActiveOrder
    },
    {
      key: '/platform/home/account',
      title: t('accountTab'),
      activeIcon: activeAccount,
      inActiveIcon: inActiveAccount
    }
  ];
  const { positionInfo, manualGetPosition } = usePosition(true);
  const location = useLocation();
  const pathname = location.pathname;
  const [activeTab, setActiveTab] = useState<string>(pathname);
  const [loadedTabs, setLoadedTabs] = useState<string[]>([pathname]);
  const navigate = useNavigate();
  const { success } = positionInfo;
  const { handelListenerDeepLink } = useListenerDeepLink();
  useMemo(() => {
    const platformInfo = getPlatform();
    // 非客户端 进来就拉定位
    if (platformInfo?.code === 0 && platformInfo?.data === 'web') {
      // h5
      if (!success) {
        manualGetPosition();
      }
    } else {
      // app
      // 监听闪屏事件
      window.addEventListener('SplashCloseRequest', async function (jsonString) {
        console.log('splashCloseListener jsonString:', jsonString);
        // 关闭闪屏
        closeSplashPage();
        // 定位太慢，优先APP消息通知，H5的消息通知在PWA中处理
        await initAppNotifications();
        console.warn('initAppNotifications 处理完成');
        // 上报启动埋点
        report('app_on', { type: 'view' }, 'qpon_app');
        // 获取位置
        if (!success) {
          manualGetPosition();
        }
      });
      // 监听客户端deepLink回调
      handelListenerDeepLink();
      // 通知app当前h5页面已经渲染完成
      notifyAppReady();
    }
  }, []);

  const loadTabContent = useMemoizedFn((activeKey: string) => {
    if (!loadedTabs.includes(activeKey)) {
      setLoadedTabs([...loadedTabs, activeKey]);
    }
  });

  useEffect(() => {
    setActiveTab(pathname);
    loadTabContent(pathname);
  }, [pathname, loadTabContent]);

  // 动态引入 ConfigTestEnv 组件
  const ConfigTestEnv = React.lazy(() => {
    // 预发布、生产环境不引入此组件
    if (!['pre', 'prod'].includes(import.meta.env.VITE_BUILD_ENV)) {
      return import('../../components/common/configTestEnv/index.tsx');
    }
    return Promise.resolve({ default: () => null });
  });

  return (
    <div className={styles.app}>
      {ConfigTestEnv && (
        <Suspense>
          <ConfigTestEnv />
        </Suspense>
      )}
      <div className={styles.body}>
        <Suspense fallback={<LoadingView />}>
          {/* MARK:根路由需要展示Tab1 */}
          {(loadedTabs.includes('/platform/home') || loadedTabs.includes('/')) && (
            <div
              id="homePage"
              className={`${styles.component} ${styles.homePage}`}
              style={{ display: activeTab === '/platform/home' || activeTab === '/' ? 'block' : 'none' }}
            >
              <Home />
            </div>
          )}
          {loadedTabs.includes('/platform/home/order') && (
            <div className={styles.component} style={{ display: activeTab === '/platform/home/order' ? 'block' : 'none' }}>
              {activeTab === '/platform/home/order' && <Order />}
            </div>
          )}
          {loadedTabs.includes('/platform/home/account') && (
            <div className={styles.component} style={{ display: activeTab === '/platform/home/account' ? 'block' : 'none' }}>
              <Acccount />
            </div>
          )}
        </Suspense>
      </div>
      <div className={styles.visibility}></div>
      <div className={styles.bottom} id="homeTabBottom">
        <BottomTabs
          tabsData={tabs}
          safeArea
          defaultActiveKey="/"
          activeKey={activeTab}
          onChange={(path) => {
            setActiveTab(path);
            loadTabContent(path);
            navigate(path, { replace: true });
          }}
        />
      </div>
    </div>
  );
};
export default AppHome;
