import { getDevOptWhiteList } from '@/api/devOpt';
import { getUserId } from '@/hooks/useReport';
import { getDeviceInfo, isQponIos } from '@/utils/nativeSdk';
/**
 * 检测当前设备或用户是否在白名单
 */
export const checkWhiteList = async () => {
  const res = await getDevOptWhiteList({ app_id: 'com.qpon.platform' });
  const isIos = isQponIos();
  if (res?.code === 0) {
    const { userIds, adIds, guids, uuids } = res?.data || { deviceIds: [], userIds: [] };
    const deviceInfo = await getDeviceInfo();
    console.log('deviceInfo:', deviceInfo);
    const info = deviceInfo?.data;
    const { adId, guid, deviceId } = info || {};
    let uuid = '';
    // ios设备的uuid
    if (isIos) {
      uuid = deviceId;
    }

    const curUserId = getUserId();
    if (userIds.includes(curUserId as string) || adIds.includes(adId) || guids.includes(guid) || uuids.includes(uuid)) {
      return true;
    }
  }
  return false;
};
