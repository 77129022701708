import { FCM } from '@capacitor-community/fcm';
import { PushNotifications } from '@capacitor/push-notifications';
import { isQponApp } from '@/utils/nativeSdk';
import { reportFCMToken } from '@/api/fcm';

// 1.检查权限，获取权限
const checkPermissions = async () => {
  // 检查权限
  // 'prompt' | 'prompt-with-rationale' | 'granted' | 'denied'
  //  prompt：表示首次请求用户权限，用户可以选择授予或拒绝。
  // prompt-with-rationale：表示用户之前拒绝过权限请求，但在再次请求时可以看到一个解释或说明，目的是尝试说服用户授予权限。
  // granted：表示用户已经授予了所需的权限。
  // denied：表示用户拒绝了权限请求，且如果用户选中了“不再询问”，则该权限将一直被拒绝。
  console.warn('开始检查权限checkPermissions');
  let permStatus = await PushNotifications.checkPermissions();
  console.warn('app-fcm checkPermissions1', permStatus);
  // 无论结果尝试一次申请权限
  permStatus = await PushNotifications.requestPermissions();
  console.warn('checkPermissions2', permStatus);
};
// 2.注册通知
const registerNotifications = async () => {
  console.warn('app-fcm registerNotifications');
  PushNotifications.addListener('registration', async (notification) => {
    // 注册成功
    console.warn('app-fcm registration success', notification);
    initFCM();
  });
  // 接受到消息
  PushNotifications.addListener('pushNotificationReceived', (notification) => {
    console.warn('接收到app-fcm消息', notification);
  });

  // 点击通知栏
  PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
    console.warn('点击了消息通知', notification.actionId, notification.inputValue);
    console.warn('点击了消息通知', notification);
  });
  // 注册
  PushNotifications.register();
};

// 3.初始化FCM
const initFCM = async () => {
  console.warn('app initFCM');
  // 注册后获取FCM的token
  const token = await FCM.getToken().then(
    (result) => {
      return result?.token || '';
    },
    () => {
      return '';
    }
  );
  if (token) {
    reportFCMToken(token);
    localStorage.setItem('app-fcm-token', token);
    console.warn('app-fcm-token', token);
  }
};

export const initAppNotifications = async () => {
  if (!isQponApp()) {
    return;
  }
  console.warn('app-Notifications initAppNotifications');
  await checkPermissions();
  registerNotifications();
};
